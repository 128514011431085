import React from "react";
import FloykaProfileCardDark from "./components/FloykaProfileCardDark";
import FooterSectionDark from "./components/FooterSectionDark";
import ProjectsSectionDark from "./components/ProjectSectionDark";
import "./ShowFloykaThemeDark.scss";

function ShowFloykaThemeDark({
  portfolio,
  inDashboard,
  emailFrom,
  setEmailFrom,
  handleNotificationInfo,
  notificationInfo,
  setNotificationInfo,
}) {
  console.log(portfolio);
  return (
    <div
      className="main-dark"
      style={{
        paddingTop: inDashboard ? 0 : 150,
        width: "100%",
      }}
    >
      <FloykaProfileCardDark portfolio={portfolio} />
      <ProjectsSectionDark userId={portfolio.userId} />
      <FooterSectionDark
        portfolio={portfolio}
        handleNotificationInfo={handleNotificationInfo}
        setNotificationInfo={setNotificationInfo}
        notificationInfo={notificationInfo}
      />
    </div>
  );
}

export default ShowFloykaThemeDark;
