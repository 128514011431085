import React from "react";
import { PREMIUM_TEXT } from "../../../../constants/payment";
import PlanButton from "../components/PlanButton";

function Step4({ plans, setPlan, step, setStep, plan }) {
  return (
    <div className="appyl-inputs-div">
      <h3
        style={{
          color: "black",
          fontWeight: "bold",
          marginBottom: 70,
          marginTop: 120,
        }}
      >
        Choose Your Payment Plan
      </h3>


      <div className="plans-container">
        {plans.map((item, index) => (
          <div className="plan-button-wrapper" key={index}>
            <PlanButton
              onClick={setPlan}
              title={item.title}
              description={item.description}
              price={item.price}
              active={item.active}
              index={index}
              suggested={item.suggested}
            />
          </div>
        ))}
      </div>
      <div style={{ color: "green", fontSize: 20, paddingTop: 20, paddingBottom: 20 }}>
        <i class='bx bxs-gift'></i>
        <span> </span>

        <span>
          Freemium selected plans will be launched as premium valid for 3 months
          for the first 100 users!
        </span>
      </div>
      <div className="button-row">
        <button
          className="apply-buttons active"
          onClick={() => setStep(step - 1)}
        >
          Back
        </button>
        <button
          className="apply-buttons"
          onClick={() =>
            plan == PREMIUM_TEXT ? setStep(step + 1) : setStep(step + 2)
          }
        >
          Continue
        </button>
      </div>
    </div>
  );
}

export default Step4;
