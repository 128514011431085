import React from 'react'
import { Trash, PlusCircle } from 'react-bootstrap-icons';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import * as Queries from '../../../../utils/queries';
import { Avatar, Input, Textarea, Button } from './general';

const UpdateProposal = ({ initialPropsal, toggle }) => {
    const queryClient = useQueryClient();
    const [proposal, setProposal] = React.useState(initialPropsal);
    const [errors, setErrors] = React.useState({});

    const { mutate } = useMutation({
        mutationKey: ['update-proposal', proposal._id],
        mutationFn: () => Queries.updateProposal(proposal._id, proposal),
        onSuccess: async (res) => {
            await queryClient.invalidateQueries('proposal');
            toggle();
        }
    });

    const updateProposal = (key, value) => {
        setProposal((prev) => ({ ...prev, [key]: value }));
    }

    const updateService = (index, key, value) => {
        setProposal((prev) => {
            const services = [...prev.services];
            services[index][key] = value;
            return { ...prev, services };
        });
    }

    const addService = () => {
        setProposal((prev) => ({
            ...prev,
            services: [...prev.services, { name: '', price: 0, currency: 'USD' }]
        }));
    }

    const removeService = (index) => {
        setProposal((prev) => {
            const services = [...prev.services];
            services.splice(index, 1);
            return { ...prev, services };
        });
    }

    const validate = () => {
        const newErrors = {};
        if (!proposal.title) newErrors.title = 'Title is required';
        if (!proposal.description) newErrors.description = 'Description is required';
        if (!proposal.duration.value) newErrors.durationValue = 'Duration value is required';
        if (!proposal.duration.unit) newErrors.durationUnit = 'Duration unit is required';
        if (proposal.services.length === 0) newErrors.services = 'At least one service is required';
        proposal.services.forEach((service, index) => {
            if (!service.name) newErrors[`serviceName${index}`] = 'Service name is required';
            if (!service.price) newErrors[`servicePrice${index}`] = 'Service price is required';
        });
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }

    const handleSubmit = () => {
        if (validate()) {
            mutate();
        }
    }

    return (
        <>
            <form className="tw-w-full tw-px-8">
                <div className="tw-mb-4">
                    <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="title">
                        Title
                    </label>
                    <Input
                        placeholder="Title"
                        value={proposal.title}
                        onChange={(e) => updateProposal('title', e.target.value)}
                    />
                    {errors.title && <p className="tw-text-red-500 tw-text-xs">{errors.title}</p>}
                </div>
                <div className="tw-mb-4">
                    <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="description">
                        Executive Summary
                    </label>
                    <Textarea
                        placeholder="Your proposal message will appear here."
                        value={proposal.description}
                        rows={6}
                        onChange={(e) => updateProposal('description', e.target.value)}
                    />
                    {errors.description && <p className="tw-text-red-500 tw-text-xs">{errors.description}</p>}
                </div>
                <div className="tw-mb-4">
                    <label className="tw-block tw-text-gray-700 tw-text-sm tw-font-bold tw-mb-2" htmlFor="Duration">
                        Duration
                    </label>
                    <div className="tw-flex tw-gap-2">
                        <Input
                            type="number"
                            placeholder="Duration"
                            value={proposal.duration.value}
                            onChange={(e) => updateProposal('duration', { ...proposal.duration, value: e.target.value })}
                            min="0"
                        />
                        <select
                            value={proposal.duration.unit}
                            onChange={(e) => updateProposal('duration', { ...proposal.duration, unit: e.target.value })}
                            className="tw-px-3 tw-py-2 tw-border tw-bg-white tw-border-gray-300 tw-border-solid tw-rounded-md tw-shadow-sm focus:tw-outline-none focus:tw-ring-blue-500 focus:tw-border-blue-500"
                        >
                            <option value="hours">Hours</option>
                            <option value="days">Days</option>
                            <option value="weeks">Weeks</option>
                            <option value="months">Months</option>
                            <option value="years">Years</option>
                        </select>
                    </div>
                    {errors.durationValue && <p className="tw-text-red-500 tw-text-xs">{errors.durationValue}</p>}
                </div>
                <div className="tw-mb-4">
                    <h3 className="tw-text-lg tw-font-semibold tw-mb-2">Services</h3>
                    {proposal.services.map((service, index) => (
                        <>
                            <div key={index} className="tw-flex tw-gap-2 tw-mb-2">
                                <Input
                                    placeholder="Service name"
                                    value={service.name}
                                    onChange={(e) => updateService(index, 'name', e.target.value)}
                                />
                                <Input
                                    type="number"
                                    placeholder="Price"
                                    value={service.price}
                                    onChange={(e) => updateService(index, 'price', e.target.value)}
                                />
                                <select
                                    value={service.currency}
                                    onChange={(e) => updateService(index, 'currency', e.target.value)}
                                    className="tw-px-3 tw-py-2 tw-border tw-bg-white tw-border-gray-300 tw-border-solid tw-rounded-md tw-shadow-sm focus:tw-outline-none focus:tw-ring-blue-500 focus:tw-border-blue-500"
                                >
                                    <option value="USD">USD</option>
                                    <option value="EUR">EUR</option>
                                    <option value="TRY">TRY</option>
                                </select>
                                <Button variant="outline" onClick={() => removeService(index)}>
                                    <Trash className="tw-h-4 tw-w-4" />
                                </Button>
                            </div>
                            {errors[`serviceName${index}`] && <p className="tw-text-red-500 tw-text-xs">{errors[`serviceName${index}`]}</p>}
                            {errors[`servicePrice${index}`] && <p className="tw-text-red-500 tw-text-xs">{errors[`servicePrice${index}`]}</p>}

                        </>

                    ))}

                    <Button variant="outline" className="tw-mt-2" onClick={addService}>
                        <PlusCircle className="tw-h-4 tw-w-4 tw-mr-2" />
                        Add Service
                    </Button>
                </div>
                <div className="tw-flex tw-items-center tw-mb-8 tw-gap-2">
                    <Avatar src={proposal.freelancer?.profileImg || 'https://via.placeholder.com/60'} />
                    <div className="tw-gap-2 tw-flex tw-justify-center tw-flex-col">
                        <p className="tw-font-semibold tw-mb-0 ">{proposal.freelancer?.fullName || 'Full Name'}</p>
                        <p className="tw-text-sm tw-text-gray-500 tw-mb-0">{proposal.freelancer?.role || 'Role'}</p>
                    </div>
                </div>
                <div className="tw-text-sm tw-text-gray-500">
                    <p>Thank you for considering our proposal. We look forward to working with you.</p>
                </div>
            </form >
            <div className='w-full d-flex justify-content-end align-items-end proposal-buttons tw-gap-3'>
                <button type='button' className='custom-button tw-bg-white tw-border tw-border-gray-300 tw-text-gray-700' onClick={handleSubmit}>
                    Update
                </button>
            </div>
        </>
    )
}

export default UpdateProposal