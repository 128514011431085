import axios from "axios";
import { toast } from "react-toastify";
import { RESET_PASSWORD } from "../../../../constants/links";

export const useChangePassword = () => {
    const changePassword = (oldPassword, newPassword, userId, onClose) => {
        axios
            .post(RESET_PASSWORD, { oldPassword, newPassword, userId })
            .then(() => {
                toast.success("Password successfully updated!");
                onClose();
            })
            .catch(() => {
                toast.error("Failed to update password.");
            });
    };

    return { changePassword };
};
