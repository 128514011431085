import React, { useState } from 'react'
import '../styles/sideMenuDropDown.css'
import MenuIcon from './MenuIcon'
function SideMenuMini({ role }) {
    const [menuItems, setMenuItems] = useState([
        { iconClass: "material-symbols-outlined", iconName: "home", link: "/dashboard" },
        { iconClass: "material-symbols-outlined", iconName: "communities", link: "/dashboard/collective" },
        { iconClass: "material-symbols-outlined", iconName: "notifications", link: "/dashboard/notifications" },
        // { iconClass: "material-symbols-outlined", iconName: "home", link: "/" },
        { iconClass: "material-symbols-outlined", iconName: "groups", link: "/dashboard/projects" },
        { iconClass: "material-symbols-outlined", iconName: "task", link: "/dashboard/tasks" },
        { iconClass: "material-symbols-outlined", iconName: "payments", link: "/dashboard/sales-pipeline" },
        { iconClass: "material-symbols-outlined", iconName: "note_add", link: "/dashboard/proposal" },
        // { iconClass: "material-symbols-outlined", iconName: "chat", link: "/" },
        // { iconClass: "material-symbols-outlined", iconName: "calendar_month", link: "/" },
        // { iconClass: "material-symbols-outlined", iconName: "work", link: "/" },
    ])
    return (
        <div class="min-vertical-menu" >
            <div >
                <div class="simplebar-wrapper" style={{ margin: '0px' }}>
                    <div class="simplebar-height-auto-observer-wrapper">
                        <div class="simplebar-height-auto-observer">
                        </div>
                    </div>
                    <div class="simplebar-mask">
                        <div >
                            <div class="simplebar-content-wrapper" tabindex="0" role="region" aria-label="scrollable content" style={{ height: '100%', overflow: 'hidden' }}>
                                <div class="simplebar-content" style={{ padding: '0px' }}>
                                    <div>
                                        <div id="sidebar-menu">
                                            <ul id="side-menu" class="metismenu list-unstyled">
                                                {menuItems.map((item, index) => {
                                                    if (item.iconName == 'work' && role == 'company') {
                                                        return (<></>)
                                                    }
                                                    else {
                                                        return (
                                                            <MenuIcon iconClass={item.iconClass} iconName={item.iconName} link={item.link} />
                                                        )
                                                    }
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >

                </div >

            </div >
        </div >

    )
}

export default SideMenuMini