import { useMutation } from "@tanstack/react-query";
import axios from "axios";

export default function useMyMutations() {

    const mutation = useMutation({
        mutationFn: (options) => {
            // console.log(newData)
            // console.log(headers)

            return axios({
                url: options.url,
                method: options.method,  // GET isteği olduğunu buradan belirtiyoruz
                headers: options.headers,
                data: options.data
            })
            // return axios.put(UPDATE_TASK_BOARD, newData, { headers: { taskBoardId: currentBoard._id } }); // POST isteği burada yapılır
            // console.log(newData, url, headers)
        },
        onError: (error) => {
            // İstek hata aldığında çalışır
            console.log('Error:', error);
        },

    });

    return { mutation }
}