import React from "react";

const priorityColors = {
  high: "#f44336", // Red
  medium: "#ffc107", // Yellow
  low: "#4caf50", // Green
};

const styles = {
  task: {
    backgroundColor: "white",
    borderRadius: "8px",
    padding: "16px",
    marginBottom: "16px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    transition: "box-shadow 0.3s ease, transform 0.3s ease",
    borderLeft: "8px solid",
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  title: {
    fontWeight: "600",
    fontSize: "18px",
    color: "#333",
  },
  description: {
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: "#666",
    fontSize: "14px",
  },
  taskActions: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "8px",
  },
  userInfo: {
    display: "flex",
    alignItems: "center",
  },
  userImage: {
    width: "30px",
    height: "30px",
    marginRight: "10px",
    borderRadius: "50%",
    objectFit: "cover",
  },
  userName: {
    fontSize: "14px",
    color: "#444",
  },
  button: {
    padding: "6px 12px",
    borderRadius: "4px",
    border: "none",
    cursor: "pointer",
    fontSize: "12px",
    transition: "background-color 0.3s ease",
  },
  viewButton: {
    backgroundColor: "gray",
    color: "white",
    marginRight: "8px",
  },
  deleteButton: {
    backgroundColor: "gray",
    color: "white",
  },
  dates: {
    display: "flex",
    gap: "8px",
    fontSize: "12px",
    color: "#888",
  },
};

export default function Task({
  currentBoard,
  task,
  provided,
  onEdit,
  onDelete,
}) {
  const taskStyle = {
    ...styles.task,
    ...provided.draggableProps.style,
    borderLeftColor: priorityColors[task.priority?.toLowerCase()] || "#999", // Priority color or default
  };

  console.log("Border Left Color:", priorityColors[task.priority]);

  const appointedUser =
    currentBoard.users &&
    currentBoard.users.find((user) => user.userId === task.appointedUserId);

  // Tarihi "DD/MM/YYYY" formatına dönüştürür
  const formatShortDate = (dateString) => {
    if (!dateString) return null;
    const date = new Date(dateString);
    return `${date.getDate().toString().padStart(2, "0")}.${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}.${date.getFullYear()}`;
  };

  // Bitiş tarihine göre renk belirleyen fonksiyon
  const getDateStyle = (endDateString) => {
    if (!endDateString) return {};
    const currentDate = new Date();
    const targetDate = new Date(endDateString);

    // Saatleri sıfırlar, yalnızca gün hesaplaması yapılır
    currentDate.setHours(0, 0, 0, 0);
    targetDate.setHours(0, 0, 0, 0);

    // Gün farkını hesaplar
    const timeDiff = targetDate - currentDate;
    const dayDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24)); // Milisaniyeden güne çevirir

    if (dayDiff > 5) {
      return { color: "green" }; // Normal stil (gri)
    } else if (dayDiff > 2) {
      return { color: "orange" }; // 1-3 gün arası
    } else {
      return { color: "red" }; // Tarihi geçmiş
    }
  };

  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={taskStyle}
    >
      <div style={styles.title}>{task.title}</div>
      <div style={styles.description}>{task.description}</div>
      <div style={styles.dates}>
        {task.startDate && (
          <span style={{ color: "black" }}>
            {formatShortDate(task.startDate)}
          </span>
        )}
        {" - "}
        {task.endDate && (
          <span style={getDateStyle(task.endDate)}>
            {formatShortDate(task.endDate)}
          </span>
        )}
      </div>
      <div style={styles.taskActions}>
        <div style={styles.userInfo}>
          {appointedUser && (
            <>
              <img
                src={appointedUser.profileImg}
                alt={appointedUser.fullName}
                style={styles.userImage}
              />
              <span style={styles.userName}>{appointedUser.fullName}</span>
            </>
          )}
        </div>
        <div>
          <button
            style={{ ...styles.button, ...styles.viewButton }}
            onClick={onEdit}
          >
            View
          </button>
          <button
            style={{ ...styles.button, ...styles.deleteButton }}
            onClick={() => onDelete(task._id)}
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
}
