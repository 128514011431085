import React, { useEffect, useState } from "react";
import { CancelIcon } from "../../salesPipeline/Icons";

function TaskEventModal({ show, onClose, title, content }) {
  const [isVisible, setIsVisible] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    if (show) {
      setIsVisible(true);
      setTimeout(() => {
        setIsAnimating(true);
      }, 10); // Animasyon başlaması için kısa bir gecikme
    } else {
      setIsAnimating(false);
      const timeout = setTimeout(() => setIsVisible(false), 300); // Kapatma animasyonu için
      return () => clearTimeout(timeout);
    }
  }, [show]);

  return (
    <>
      {isVisible && (
        <div
          className={`modal fade ${isAnimating ? "show" : ""}`}
          style={{ display: "block", opacity: isAnimating ? 1 : 0 }}
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{title}</h5>
                <button
                  style={{ border: 0, backgroundColor: "transparent" }}
                  type="button"
                  className="close"
                  onClick={onClose}
                >
                  <CancelIcon />
                </button>
              </div>
              <div className="modal-body">{content}</div>
            </div>
          </div>
        </div>
      )}
      {show && (
        <div
          className={`modal-backdrop fade ${isAnimating ? "show" : ""}`}
        ></div>
      )}
    </>
  );
}

export default TaskEventModal;
