import React, { useState } from 'react'

// Stil tanımlamaları
const styles = {
    form: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        marginBottom: '15px',
    },
    input: {
        flex: 1,
        padding: '8px 12px',
        fontSize: '14px',
        border: '1px solid #ddd',
        borderRadius: '4px',
        outline: 'none',
        transition: 'border-color 0.3s',
    },
    inputFocus: {
        borderColor: '#007bff',
        boxShadow: '0 0 0 2px rgba(0,123,255,.25)',
    },
    button: {
        padding: '8px 15px',
        fontSize: '14px',
        backgroundColor: '#007bff',
        color: 'white',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
    },
    buttonHover: {
        backgroundColor: '#0056b3',
    }
};

export default function AddColumnForm({ onSubmit }) {
    const [title, setTitle] = useState('')
    const [isFocused, setIsFocused] = useState(false)

    const handleSubmit = (e) => {
        e.preventDefault()
        if (title.trim()) {  // Boş string kontrolü
            onSubmit(title.trim())
            setTitle('')
        }
    }

    return (
        <form onSubmit={handleSubmit} style={styles.form}>
            <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                placeholder="Status Name"
                required
                style={{
                    ...styles.input,
                    ...(isFocused ? styles.inputFocus : {})
                }}
            />
            <button 
                type="submit"
                style={styles.button}
                onMouseOver={(e) => e.target.style.backgroundColor = styles.buttonHover.backgroundColor}
                onMouseOut={(e) => e.target.style.backgroundColor = styles.button.backgroundColor}
            >
                Add
            </button>
        </form>
    )
}