import React, { useRef, useEffect, useState } from 'react';

function OtpInput({ numberOfDigits, otp,setOtp }) {
    const otpBoxReference = useRef([]);

    function handleChange(value, index) {
        let newArr = [...otp];
        newArr[index] = value;
        setOtp(newArr);

        if (value && index < numberOfDigits - 1) {
            otpBoxReference.current[index + 1].focus()
        }
    }

    function handleBackspaceAndEnter(e, index) {
        if (e.key === "Backspace" && !e.target.value && index > 0) {
            otpBoxReference.current[index - 1].focus()
        }
        if (e.key === "Enter" && e.target.value && index < numberOfDigits - 1) {
            otpBoxReference.current[index + 1].focus()
        }
    }

    function handlePaste(e) {
        e.preventDefault();
        const paste = e.clipboardData.getData("Text");
        if (paste.length === numberOfDigits) {
            setOtp(paste.split(""));
        }
    }

    return (
        <div className='otp-box'>
            {otp.map((digit, index) => (
                <input
                    key={index}
                    value={digit}
                    maxLength={1}
                    onChange={(e) => handleChange(e.target.value, index)}
                    onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
                    onPaste={handlePaste}
                    className='otp-input'
                    ref={(reference) => (otpBoxReference.current[index] = reference)}
                />
            ))}

        </div>
    );
}

export default OtpInput;