import React from "react";
import { ToastContainer } from "react-toastify";
import FloykaProfileCard from "./components/FloykaProfileCard";
import FooterSection from "./components/FooterSection";
import ProjectsSection from "./components/ProjectSection";
import "./ShowFloykaTheme";

function ShowFloykaTheme({
  portfolio,
  inDashboard,
  emailFrom,
  setEmailFrom,
  handleNotificationInfo,
  notificationInfo,
  setNotificationInfo,
}) {
  return (
    <div
      className="main"
      style={{
        paddingTop: inDashboard ? 0 : 150,
        width: "100%",
      }}
    >
      <ToastContainer />
      <FloykaProfileCard portfolio={portfolio} />
      {/* <Works works={portfolio.works} userId={portfolio.userId} /> */}
      <ProjectsSection userId={portfolio.userId} />
      <FooterSection
        portfolio={portfolio}
        handleNotificationInfo={handleNotificationInfo}
        setNotificationInfo={setNotificationInfo}
        notificationInfo={notificationInfo}
      />
    </div>
  );
}

export default ShowFloykaTheme;
