import React from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { PROJECT } from "../../../../constants/links";
import { getUserIdFromCookie } from "../../../../helpers/cookieHelper";

const DeleteMember = ({ member, projectId, onDelete, close }) => {
  const deleteMember = () => {
    console.log(member, projectId);
    axios
      .delete(`${PROJECT}${projectId}/member/${member.userId}`, {
        headers: {
          userid: getUserIdFromCookie(),
        },
      })
      .then((res) => {
        toast.success(res.data.message, {
          theme: "colored",
          position: "bottom-right",
        });
        onDelete(member.userId);
        close();
      })
      .catch((err) => {
        console.error("Error removing member:", err);
        if (err.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          toast.error(
            `Error: ${err.response.data.message || err.response.statusText}`,
            { theme: "colored", position: "bottom-right" }
          );
        } else if (err.request) {
          // The request was made but no response was received
          toast.error(
            "Network error. Please check your connection and try again.",
            { theme: "colored", position: "bottom-right" }
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          toast.error("An unexpected error occurred. Please try again.", {
            theme: "colored",
            position: "bottom-right",
          });
        }
      });
  };

  return (
    <div className="py-5" style={{ textAlign: "center" }}>
      <h4>Are you sure you want to remove {member.fullName} from the team?</h4>
      <div style={{ marginTop: 30 }}>
        <button
          className={"common-purple-button px-5 py-2"}
          style={{ marginRight: 20 }}
          onClick={close}
        >
          No
        </button>
        <button
          className={"common-purple-button px-5 py-2"}
          onClick={deleteMember}
        >
          Yes
        </button>
      </div>
    </div>
  );
};

export default DeleteMember;
