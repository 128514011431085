import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import PageLoadingAnimation from "../../../components/Loading/PageLoadingAnimation";
import UpperModal from "../../../components/UpperModal";
import { GET_USER_PROJECTS } from "../../../constants/links";
import { getUserIdFromCookie } from "../../../helpers/cookieHelper";
import CreateProject from "./CreateProject";
import JoinWithID from "./components/JoinWithID";

const TOP_TOOL_ITEMS = [
  {
    title: "Create Project",
    path: "#createProject",
  },
  {
    title: "Join with ID",
    path: "#joinWithID",
  },
];

function Projects1({ userProfile }) {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);

  const [projectMembers, setProjectsMembers] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(GET_USER_PROJECTS, {
        headers: {
          userid: getUserIdFromCookie(),
        },
      })
      .then((res) => {
        const sortedProjects = res.data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setProjects(sortedProjects);
        // for (let item of sortedProjects) {
        //     for (let user of item.users) {
        //         setProjectsMembers(projectMembers => [...projectMembers, user.profileImg])
        //     }
        // }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(dateString).toLocaleDateString("tr-TR", options);
  };

  if (loading) {
    return <PageLoadingAnimation />;
  } else {
    return (
      <div className="project-main">
        <div className="projects-header d-flex justify-content-between align-items-center mb-4 p-4">
          <h2 className="projects-title">Projects</h2>
          <div className="top-buttons">
            {TOP_TOOL_ITEMS.map((item, index) => (
              <button
                key={index}
                className="custom-button"
                data-toggle="modal"
                data-target={item.path}
              >
                {item.title}
              </button>
            ))}
          </div>
        </div>

        <div className="projects-main-div p-4">
          <ToastContainer />
          <UpperModal
            modalId={"createProject"}
            contentHTML={<CreateProject userProfile={userProfile} />}
            type={"lg"}
          />
          <UpperModal
            modalId={"joinWithID"}
            contentHTML={<JoinWithID userProfile={userProfile} />}
            width={500}
            type={"sm"}
          />

          <div
            className="table-responsive"
            style={{ minWidth: "1000px", overflowX: "auto" }}
          >
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Project Name</th>
                  <th scope="col">Budget</th>
                  <th scope="col">Start Date</th>
                  <th scope="col">Due Date</th>
                  <th scope="col">Members</th>
                </tr>
              </thead>
              <tbody>
                {projects.length > 0 ? (
                  projects.map((project, index) => (
                    <tr key={index}>
                      <td
                        onClick={() =>
                          navigate("/dashboard/project-detail", {
                            state: {
                              projectId:
                                project._id != undefined
                                  ? project._id
                                  : project.projectId,
                            },
                          })
                        }
                        style={{ cursor: "pointer" }}
                      >
                        {project.projectTitle || "N/A"}
                      </td>
                      <td>{project.budget || "N/A"}</td>
                      <td>
                        {project.startDate
                          ? formatDate(project.startDate)
                          : "N/A"}
                      </td>
                      <td>
                        {project.endDate ? formatDate(project.endDate) : "N/A"}
                      </td>

                      <td>
                        {project.users
                          ? project.users.map((user) => (
                              <img
                                src={user.profileImg}
                                alt=""
                                class="rounded-circle avatar-sm"
                                width={70}
                              />
                            ))
                          : "N/A"}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" className="text-center">
                      No projects available.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default Projects1;
