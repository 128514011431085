import React, { useState } from "react";
import { toast } from "react-toastify";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useChangeEmail } from "../hooks/useChangeEmail";

const ChangeEmailModal = ({ isOpen, toggle, userId }) => {
    const { sendVerificationCode, verifyCode, changeEmail } = useChangeEmail();

    const [newEmail, setNewEmail] = useState("");
    const [verificationCode, setVerificationCode] = useState(null);
    const [enteredCode, setEnteredCode] = useState("");
    const [isCodeSent, setIsCodeSent] = useState(false);
    const [isVerified, setIsVerified] = useState(false);

    const handleSendCode = () => {
        if (!newEmail) {
            toast.error("Please enter a valid email.");
            return;
        }
        sendVerificationCode(newEmail, setVerificationCode, setIsCodeSent);
    };

    const handleVerifyCode = () => {
        verifyCode(enteredCode, verificationCode, setIsVerified);
    };

    const handleSave = () => {
        if (!isVerified) {
            toast.error("Please verify your email before saving.");
            return;
        }
        changeEmail(newEmail, userId, toggle);
        setNewEmail("");
        setEnteredCode("");
        setIsCodeSent(false);
        setIsVerified(false);
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>Change Email</ModalHeader>
            <ModalBody>
                {!isCodeSent && (
                    <input
                        type="email"
                        className="form-control mb-2"
                        placeholder="Enter new email"
                        value={newEmail}
                        onChange={(e) => setNewEmail(e.target.value)}
                    />
                )}
                {isCodeSent && !isVerified && (
                    <input
                        type="text"
                        className="form-control mb-2"
                        placeholder="Enter verification code"
                        value={enteredCode}
                        onChange={(e) => setEnteredCode(e.target.value)}
                    />
                )}
                {isVerified && (
                    <div className="text-success">Email successfully verified!</div>
                )}
            </ModalBody>
            <ModalFooter>
                {!isCodeSent ? (
                    <Button color="primary" onClick={handleSendCode}>
                        Send Verification Code
                    </Button>
                ) : !isVerified ? (
                    <Button color="primary" onClick={handleVerifyCode}>
                        Verify Code
                    </Button>
                ) : (
                    <Button color="primary" onClick={handleSave}>
                        Save
                    </Button>
                )}
                <Button color="secondary" onClick={toggle}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ChangeEmailModal;
