import useAsync from "./useAsync";

const DEFAULT_OPTIONS = {
    headers: { "Content-Type": "application/json" }
}

export default function useFetch(url, options = {}, dependencies = []) {
    return useAsync(() => {
        return fetch(url, { ...DEFAULT_OPTIONS, ...options }).then(res => {
            return res.json().then(json => {
                if (res.ok) {
                    return json; // Veriyi döndür
                } else {
                    return Promise.reject(json); // Hata durumunda reddet
                }
            });
        })
    }, dependencies)
}