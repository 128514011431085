import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CANCEL_TICKET_INVITE, SET_COMPANY_ACCEPTED } from '../../../../constants/links'
import axios from 'axios'
import UpperModal from '../../../../components/UpperModal'
import CancelTicketInvite from './CancelTicketInvite'

function CompanyApplyCard({ freelancer, ticket, setTicket, setLoading }) {
    const navigate = useNavigate()
    const [acceptedCompany, setAcceptedCompany] = useState(freelancer.acceptedCompany)


    const cancelTicketInvite = () => {
        setLoading(true)
        axios.post(CANCEL_TICKET_INVITE, {
            userId: freelancer.userId,
            ticketId: ticket._id
        })
            .then((res) => {
                setTicket(res.data)
                setLoading(false)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const applyTicket = (acceptedValue) => {
        setAcceptedCompany(true)
        axios.post(SET_COMPANY_ACCEPTED, {
            ticketId: ticket._id,
            freelancerUserId: freelancer.userId,
            acceptedValue: acceptedValue
        })
            .then((res) => {
                console.log(res.data)
                if (!acceptedCompany) {
                    navigate('/dashboard')
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }
    return (
        <div className='freelancer-card p-3'>
            <UpperModal modalId={'cancelTicketInvite' + freelancer.userId} type={'md'} contentHTML={<CancelTicketInvite cancelTicketInvite={cancelTicketInvite} />} />

            <div style={{ position: 'absolute', right: 0, top: 0, margin: 10, cursor: 'pointer' }} data-toggle="modal" data-target={"#cancelTicketInvite" + freelancer.userId}>
                <i style={{ color: 'red', fontSize: 20 }} class='bx bxs-trash'></i>
            </div>
            <div className='d-flex align-items-center'>
                <img className='freelancer-img' src={freelancer.profileImg} />
                <div className='company-info'>
                    <div>{freelancer.fullName}</div>
                </div>
            </div>
            <div className='d-flex justify-content-between pt-4 px-2'>
                <button className='action-buttons' onClick={() => navigate(`/dashboard/${freelancer.linkNick}`)}>View Portfolio</button>
                {
                    acceptedCompany ? (
                        <div className='d-flex'>
                            Accepted
                        </div>
                    ) : (
                        <>
                            {
                                freelancer.acceptedFreelancer ? (
                                    <div className='d-flex'>
                                        <button className='mx-2 action-buttons' onClick={() => applyTicket(true)}>Apply</button>
                                        <button className='action-buttons' onClick={() => applyTicket(false)}>Reject</button>
                                    </div>
                                ) : (
                                    <div>Pending</div>
                                )
                            }
                        </>
                    )
                }


            </div>
        </div>
    )
}

export default CompanyApplyCard