import React, { useState } from "react";

// Stil tanımlamaları
const styles = {
  formGroup: {
    marginBottom: "15px",
  },
  label: {
    display: "block",
    marginBottom: "3px",
    fontWeight: "bold",
    color: "#333",
    fontSize: "14px",
  },
  input: {
    width: "100%",
    padding: "8px",
    border: "1px solid #ddd",
    borderRadius: "4px",
    fontSize: "13px",
    transition: "border-color 0.3s",
    height: "36px",
  },
  inputFocus: {
    outline: "none",
    borderColor: "#007bff",
    boxShadow: "0 0 0 2px rgba(0,123,255,.25)",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "20px",
  },
  button: {
    padding: "8px 15px",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    fontSize: "14px",
    transition: "background-color 0.3s",
    backgroundColor: "#007bff",
    color: "white",
  },
  buttonHover: {
    filter: "brightness(90%)",
  },
};

export default function AddNewCardForm({ salesPipeline, onSubmit }) {
  const [card, setCard] = useState({
    title: "",
    description: "",
    price: "",
    state: "", // Yeni state alanı
  });

  const [focusedInput, setFocusedInput] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(card);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCard((prevCard) => ({ ...prevCard, [name]: value }));
  };

  return (
    <form onSubmit={handleSubmit}>
      <div style={styles.formGroup}>
        <label htmlFor="editCardTitle" style={styles.label}>
          Card Title
        </label>
        <input
          type="text"
          id="editCardTitle"
          name="title"
          maxLength={100}
          value={card.title}
          onChange={handleInputChange}
          required
          style={{
            ...styles.input,
            ...(focusedInput === "title" ? styles.inputFocus : {}),
          }}
          onFocus={() => setFocusedInput("title")}
          onBlur={() => setFocusedInput(null)}
        />
      </div>

      <div style={styles.formGroup}>
        <label htmlFor="editCardDescription" style={styles.label}>
          Card Description
        </label>
        <textarea
          id="editCardDescription"
          name="description"
          maxLength={350}
          value={card.description}
          onChange={handleInputChange}
          style={{
            ...styles.input,
            ...(focusedInput === "description" ? styles.inputFocus : {}),
            minHeight: "80px",
            height: "auto",
          }}
          onFocus={() => setFocusedInput("description")}
          onBlur={() => setFocusedInput(null)}
        />
      </div>

      <div style={styles.formGroup}>
        <label htmlFor="editCardPrice" style={styles.label}>
          Price
        </label>
        <input
          type="number"
          id="editCardPrice"
          name="price"
          min={0}
          required
          value={card.price}
          onChange={(e) => {
            const value = e.target.value;
            if (value.length <= 6) {
              handleInputChange(e);
            }
          }}
          style={{
            ...styles.input,
            ...(focusedInput === "price" ? styles.inputFocus : {}),
          }}
          onFocus={() => setFocusedInput("price")}
          onBlur={() => setFocusedInput(null)}
        />
      </div>

      {/* Yeni Select Input */}
      <div style={styles.formGroup}>
        <label htmlFor="editCardState" style={styles.label}>
          State
        </label>
        <select
          id="editCardState"
          name="state"
          value={card.state}
          onChange={handleInputChange}
          required
          style={{
            ...styles.input,
            ...(focusedInput === "state" ? styles.inputFocus : {}),
          }}
          onFocus={() => setFocusedInput("state")}
          onBlur={() => setFocusedInput(null)}
        >
          <option value="" disabled>
            Select a state
          </option>
          {salesPipeline.states.map((state, index) => (
            <option key={index} value={state}>
              {state}
            </option>
          ))}
        </select>
      </div>

      <div style={styles.buttonContainer}>
        <button
          className="add-button"
          type="submit"
          // style={styles.button}
          onMouseOver={(e) =>
            (e.target.style.filter = styles.buttonHover.filter)
          }
          onMouseOut={(e) => (e.target.style.filter = "none")}
        >
          Create Card
        </button>
      </div>
    </form>
  );
}
