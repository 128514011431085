import axios from "axios";
import React, { useState, useEffect } from "react";

function NotificationDetail({
  activeNotification,
  setNotificationAccepted,
  notifications, // tüm bildirimler
}) {
  const [notificationStates, setNotificationStates] = useState({});

  useEffect(() => {
    if (notifications && notifications.length > 0) {
      const initialStates = notifications.reduce((acc, notification) => {
        acc[notification._id] = {
          mailMessage: "",
          accepted: false,
          statusMessage: null,
        };
        return acc;
      }, {});
      setNotificationStates(initialStates);
    }
  }, [notifications]);

  const openModal = (decision, notificationId) => {
    setNotificationStates((prevStates) => ({
      ...prevStates,
      [notificationId]: {
        ...prevStates[notificationId],
        decision,
        isModalOpen: true,
      },
    }));
  };

  const handleMailMessageChange = (e, notificationId) => {
    const value = e.target.value;
    setNotificationStates((prevStates) => ({
      ...prevStates,
      [notificationId]: { ...prevStates[notificationId], mailMessage: value },
    }));
  };

  const handleConfirm = (notificationId) => {
    const acceptedValue =
      notificationStates[notificationId].decision === "accept";

    axios
      .post("/api/notification/response", {
        notificationId,
        accepted: acceptedValue,
      })
      .then(() => {
        setNotificationAccepted(notificationId, acceptedValue);
        setNotificationStates((prevStates) => ({
          ...prevStates,
          [notificationId]: {
            ...prevStates[notificationId],
            isModalOpen: false,
            statusMessage: acceptedValue
              ? "You accepted the offer."
              : "You rejected the offer.",
            accepted: acceptedValue,
          },
        }));
      })
      .catch((error) => {
        console.error("Error:", error);
        setNotificationStates((prevStates) => ({
          ...prevStates,
          [notificationId]: {
            ...prevStates[notificationId],
            isModalOpen: false,
          },
        }));
      });
  };

  return (
    <div className="notification-details">
      {activeNotification ? (
        <>
          <h1>{activeNotification.projectTitle}</h1>
          <p>
            <strong>From: </strong>
            {activeNotification.fullName} ({activeNotification.email})
          </p>

          <p>Job Description</p>
          <div className="job-desc-div">
            {activeNotification.projectDescription}
          </div>

          <textarea
            className="notification-mail-message"
            placeholder="Your message..."
            maxLength={2000}
            onChange={(e) => handleMailMessageChange(e, activeNotification._id)}
            value={
              notificationStates[activeNotification._id]?.mailMessage || ""
            }
            style={{ border: 0, backgroundColor: "#f0f0f0" }}
          />

          <div className="notification-buttons-div">
            {notificationStates[activeNotification._id]?.statusMessage ? (
              <p className="status-message">
                {notificationStates[activeNotification._id]?.statusMessage}
              </p>
            ) : (
              <>
                <button
                  className="notification-detail"
                  onClick={() => openModal("accept", activeNotification._id)}
                >
                  Accept
                </button>
                <button
                  className="notification-detail"
                  onClick={() => openModal("reject", activeNotification._id)}
                >
                  Reject
                </button>
              </>
            )}
          </div>

          {/* Modal */}
          {notificationStates[activeNotification._id]?.isModalOpen && (
            <div className="notification-modal">
              <div className="notification-modal-content">
                <h2>
                  {notificationStates[activeNotification._id].decision ===
                  "accept"
                    ? "Accept this job?"
                    : "Reject this job?"}
                </h2>
                <p>
                  {notificationStates[activeNotification._id].decision ===
                  "accept"
                    ? "Are you sure you want to accept this project?"
                    : "Are you sure you want to reject this project?"}
                </p>
                <button onClick={() => handleConfirm(activeNotification._id)}>
                  {notificationStates[activeNotification._id].decision ===
                  "accept"
                    ? "Yes, Accept"
                    : "Yes, Reject"}
                </button>
                <button
                  onClick={() =>
                    setNotificationStates((prevStates) => ({
                      ...prevStates,
                      [activeNotification._id]: {
                        ...prevStates[activeNotification._id],
                        isModalOpen: false,
                      },
                    }))
                  }
                >
                  Cancel
                </button>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="no-notification">
          <h1 className="no-notification-title">
            No message has reached you yet!
          </h1>
          <p className="no-notification-subtitle">
            You can improve your portfolio to attract more customers!
          </p>
        </div>
      )}
    </div>
  );
}

export default NotificationDetail;
