import React, { useState } from 'react';

const styles = {
    container: {
        padding: '20px',
        backgroundColor: '#ffffff',
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '20px',
    },
    title: {
        fontSize: '24px',
        fontWeight: 'bold',
        margin: 0,
    },
    label: {
        fontWeight: 'bold',
        marginBottom: '5px',
        display: 'block',
        fontSize: '14px',
        color: '#333',
    },
    input: {
        width: '100%',
        padding: '10px',
        marginBottom: '15px',
        border: '1px solid #ced4da',
        borderRadius: '4px',
        fontSize: '16px',
    },
    text: {
        fontSize: '16px',
        marginBottom: '15px',
        padding: '10px',
        backgroundColor: '#e9ecef',
        borderRadius: '4px',
        border: '1px solid #ced4da',
        wordBreak: 'break-word',
        overflowWrap: 'break-word',
    },
    button: {
        marginRight: '10px',
    },
    visitButton: {
        marginTop: '10px',
        padding: '8px 15px',
        backgroundColor: '#007bff',
        color: '#fff',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        textDecoration: 'none',
        display: 'inline-block',
    },
};

export default function ViewFileDetails({ initialFile, onSubmit, onDelete, onClose }) {
    const [file, setFile] = useState(initialFile);
    const [isEditing, setIsEditing] = useState(false);

    const handleInputChange = (field, value) => {
        setFile(prevFile => ({ ...prevFile, [field]: value }));
    };

    const handleEditToggle = () => {
        setIsEditing(!isEditing);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(file);
        setIsEditing(false);
    };

    const handleVisitLink = () => {
        if (file.fileLink) {
            window.open(file.fileLink, '_blank'); // Yeni sekmede linki açar
        }
    };

    return (
        <div style={styles.container}>
            <div style={styles.header}>
                <h2 style={styles.title}>File Details</h2>
                <div>
                    <button
                        type="button"
                        className="btn btn-primary"
                        style={styles.button}
                        onClick={handleEditToggle}
                    >
                        {isEditing ? 'Cancel' : 'Edit'}
                    </button>
                    <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => {
                            onDelete(file._id);
                        }}
                    >
                        Delete
                    </button>
                </div>
            </div>

            <form onSubmit={handleSubmit}>
                <div>
                    <label style={styles.label}>File Name</label>
                    {isEditing ? (
                        <input
                            style={styles.input}
                            type="text"
                            value={file.fileName}
                            onChange={(e) => handleInputChange('fileName', e.target.value)}
                        />
                    ) : (
                        <div style={styles.text}>{file.fileName}</div>
                    )}
                </div>

                <div>
                    <label style={styles.label}>File Link</label>
                    {isEditing ? (
                        <input
                            style={styles.input}
                            type="text"
                            value={file.fileLink}
                            onChange={(e) => handleInputChange('fileLink', e.target.value)}
                        />
                    ) : (
                        <>
                            <div style={styles.text}>{file.fileLink}</div>
                            {file.fileLink && (
                                <button
                                    type="button"
                                    style={styles.visitButton}
                                    onClick={handleVisitLink}
                                >
                                    Visit Link
                                </button>
                            )}
                        </>
                    )}
                </div>

                {isEditing && (
                    <button
                        type="submit"
                        className="btn btn-success"
                        style={{ marginTop: '20px' }}
                    >
                        Save Changes
                    </button>
                )}
            </form>
        </div>
    );
}
