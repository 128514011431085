export const styles = {
    main: {
        paddingTop: 70,
        paddingLeft: 50,
        paddingRight: 50,
    },
    experienceDiv: {
        display: 'flex',
        paddingBottom: 30,
        paddingTop: 30,
        marginBottom: 30,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    experienceRight: {
        textAlign: 'right',
        width: '30%',
    }
}