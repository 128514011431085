import React, { useState } from "react";

function SearchBar({ onSearch }) {
  const [query, setQuery] = useState("");

  const handleChange = (e) => {
    const newQuery = e.target.value;
    setQuery(newQuery);
    onSearch(newQuery);
  };

  return (
    <div className="search-bar">
      <input
        type="text"
        placeholder="Search . . ."
        value={query}
        onChange={handleChange}
        className="form-control"
        style={{ height: "45px" }}
      />
    </div>
  );
}

export default SearchBar;
