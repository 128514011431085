import React, { useEffect, useState } from "react";
import { CiLocationOn } from "react-icons/ci";
import { sendNotification } from "../../../../helpers/sendNotification";
import "../styles/FooterSection.scss";
import ContentEditableExample from "../../../../components/ContentEditable";

const FooterSection = ({
  portfolio,
  handleNotificationInfo,
  notificationInfo,
  setNotificationInfo,
}) => {
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const handleClickOutside = () => {
      setErrors({});
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleValidation = () => {
    const newErrors = {};
    if (!notificationInfo.email) newErrors.email = "Email is required.";
    if (!notificationInfo.fullName)
      newErrors.fullName = "Full Name is required.";
    if (!notificationInfo.projectTitle)
      newErrors.projectTitle = "Job Title is required.";
    if (!notificationInfo.projectDescription)
      newErrors.projectDescription = "Job Description is required.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.stopPropagation();
    if (handleValidation()) {
      sendNotification(portfolio.userId, notificationInfo);
      setNotificationInfo({
        email: "",
        fullName: "",
        projectDescription: "",
        projectTitle: "",
      });
      setErrors({});
    }
  };

  return (
    <div className="footer-section-light">
      <div className="profile-card">
        <div className="card-left">
          <div className="avatar">
            <img
              className="dashboard-partnership-img"
              src={portfolio.profileImg}
            />
          </div>
          <h2>{portfolio.fullName}</h2>
          <p className="location">
            <CiLocationOn style={{ margin: 5 }} />
            {portfolio.city} / {portfolio.country}
          </p>

          <p className="description">{portfolio.about}</p>
          {/* <div className="social-icons">
          {
            portfolio.socialMedias.map((link) => {
              switch (link.placeholder) {
                case "Instagram":
                  return (
                    <a href="#">
                      <FaSquareInstagram />
                    </a>
                  )
                case "":
                  return
                default:
                  break;
              }
              console.log(link)

            }

            )
          }
          <a href="#">
            <FaSquareXTwitter />
          </a>
          <a href="#">
            <FaSquareInstagram />
          </a>
          <a href="#">
            <IoMdMail />
          </a>
        </div> */}
        </div>
      </div>
      <div className="contact-form">
        <div className="form-fields">
          <div className="form">
            <input
              type="email"
              required
              placeholder="Your Email"
              onChange={(e) => handleNotificationInfo(e.target.value, "email")}
              value={notificationInfo.email}
              maxLength={50}
            />

            <input
              type="text"
              required
              placeholder="Job Title"
              onChange={(e) =>
                handleNotificationInfo(e.target.value, "projectTitle")
              }
              value={notificationInfo.projectTitle}
              maxLength={50}
            />

            <input
              type="text"
              required
              placeholder="Full Name"
              onChange={(e) =>
                handleNotificationInfo(e.target.value, "fullName")
              }
              value={notificationInfo.fullName}
              maxLength={50}
            />

            <textarea
              placeholder="Job Description"
              required
              onChange={(e) =>
                handleNotificationInfo(e.target.value, "projectDescription")
              }
              value={notificationInfo.projectDescription}
              maxLength={1500}
            ></textarea>

            <button onClick={handleSubmit}>Send Job Request!</button>
            {errors.email && <p className="error-text">{errors.email}</p>}
            {errors.projectTitle && (
              <p className="error-text">{errors.projectTitle}</p>
            )}
            {errors.fullName && <p className="error-text">{errors.fullName}</p>}
            {errors.projectDescription && (
              <p className="error-text">{errors.projectDescription}</p>
            )}
          </div>
        </div>
        <div className="job-description-container"></div>
      </div>
    </div>
  );
};

export default FooterSection;
