import React from 'react'
import { Offcanvas, OffcanvasBody, OffcanvasHeader, OffcanvasFooter } from 'reactstrap'
import { Download, Trash, PlusCircle } from 'react-bootstrap-icons';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import * as Queries from '../../../../utils/queries';
import { Avatar, Input, Textarea, Button } from './general';
import UpdateProposal from './updateProposal';

const OffcanvasProposal = ({
    proposal,
    handleProposal,
    isOpen,
    toggle,
    isClient
}) => {
    const componentRef = React.useRef();
    const queryClient = useQueryClient();

    const { mutate: acceptMutate } = useMutation({
        mutationKey: ['accept-proposal', proposal._id],
        mutationFn: () => Queries.acceptProposal(proposal._id,),
        onSuccess: async (res) => {
            await queryClient.invalidateQueries('proposal');
            toggle();
        }
    });

    const { mutate: rejectMutate } = useMutation({
        mutationKey: ['reject-proposal', proposal._id],
        mutationFn: () => Queries.rejectProposal(proposal._id),
        onSuccess: async (res) => {
            await queryClient.invalidateQueries('proposal');
            toggle();
        }
    });

    const approved = () => {
        acceptMutate()
        handleProposal(true)
    }

    const rejected = () => {
        rejectMutate()
        handleProposal(false)
    }

    const handleDownloadPDF = async () => {
        const input = componentRef.current;
        const canvas = await html2canvas(input, {
            scale: 2,
            useCORS: true,
        });

        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const margin = 10;
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth() - 2 * margin;
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, 'PNG', margin, margin, pdfWidth, pdfHeight);
        pdf.save('proposal.pdf');
    };

    return (
        <Offcanvas
            toggle={toggle}
            isOpen={isOpen}
            className='offcanvas'
        >
            <OffcanvasHeader toggle={toggle}>
                Proposal Details
            </OffcanvasHeader>
            <OffcanvasBody>
                {isClient ? (
                    <>
                        <div className="tw-w-full tw-p-8 " ref={componentRef}>
                            <div className='tw-w-full tw-h-full'>
                                <div className="tw-flex tw-justify-between tw-items-start tw-mb-8">
                                    <div>
                                        <h2 className="tw-text-2xl tw-font-semibold tw-mb-2">
                                            {proposal?.title || 'Title'}
                                        </h2>
                                    </div>
                                    <img
                                        src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/siyah%20svg%20board-riV9cS1QEaGJ9vRSamh6mcLb293Ob4.svg"
                                        alt="Company Logo"
                                        className="tw-w-32 tw-h-auto tw-m-5 tw-hidden lg:tw-block"
                                    />
                                </div>
                                <div className="tw-mb-8">
                                    <h3 className="tw-text-lg tw-font-semibold tw-mb-2">Executive Summary</h3>
                                    <p
                                        className="tw-text-sm tw-text-gray-700"
                                        style={{
                                            overflowWrap: 'break-word',
                                        }}
                                    >
                                        {proposal?.description || 'Your proposal message will appear here.'}
                                    </p>
                                </div>
                                <div className="tw-mb-8">
                                    <h3 className="tw-text-lg tw-font-semibold tw-mb-2">Proposed Services</h3>
                                    {proposal?.services?.map((service, index) => (
                                        <div key={index} className="tw-flex tw-justify-between tw-mb-2 tw-text-sm">
                                            <span>{service.name || 'Service Name'}</span>{" "}
                                            <span>
                                                {service.price || '0'} {service.currency || 'USD'}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                                <div className="tw-mb-8">
                                    <h3 className="tw-text-lg tw-font-semibold tw-mb-2">Duration</h3>
                                    <span>
                                        {proposal?.duration.value || '0'} {proposal?.duration.unit || 'hours'}
                                    </span>
                                </div>
                                <div className="tw-flex tw-items-center tw-mb-8 tw-gap-2">
                                    <Avatar src={proposal.freelancer?.profileImg || 'https://via.placeholder.com/60'} />
                                    <div className="tw-gap-2 tw-flex tw-justify-center tw-flex-col">
                                        <p className="tw-font-semibold tw-mb-0 ">{proposal.freelancer?.fullName || 'Full Name'}</p>
                                        <p className="tw-text-sm tw-text-gray-500 tw-mb-0">{proposal.freelancer?.role || 'Role'}</p>
                                    </div>
                                </div>
                                <div className="tw-text-sm tw-text-gray-500">
                                    <p>Thank you for considering our proposal. We look forward to working with you.</p>
                                </div>
                            </div>
                        </div>
                        <div className='w-full d-flex flex-column flex-md-row justify-content-end align-items-end proposal-buttons tw-gap-3'>
                            <button className='custom-button btn tw-w-full tw-max-w-none md:tw-max-w-80 tw-bg-white tw-border tw-border-gray-300 tw-text-gray-700' onClick={handleDownloadPDF}>
                                <Download className="tw-h-4 tw-w-4 tw-mr-2" />
                                Send as PDF
                            </button>
                            <button type='button' className='custom-button btn tw-w-full tw-max-w-none md:tw-max-w-80 tw-bg-white tw-border tw-border-gray-300 tw-text-gray-700' onClick={approved}>
                                Accept
                            </button>
                            <button className='custom-button btn tw-w-full tw-max-w-none md:tw-max-w-80 cancel tw-bg-white' onClick={rejected}>
                                Reject
                            </button>
                        </div>
                    </>
                ) : <UpdateProposal initialPropsal={proposal} toggle={toggle} />}
            </OffcanvasBody>
        </Offcanvas>
    )
}

export default OffcanvasProposal