import React, { useEffect, useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useLocation } from "react-router-dom";
import { Button } from "reactstrap";
import PageLoadingAnimation from "../../../components/Loading/PageLoadingAnimation";
import { httpMethod } from "../../../constants/httpMethods";
import {
  CREATE_TASK,
  CREATE_TASKBOARD,
  DELETE_TASKBOARD,
  GET_TASKBOARD_BY_USER_ID,
  UPDATE_TASK_BOARD,
} from "../../../constants/links";
import useFetch from "../../../customHooks/useFetch";
import useMyMutations from "../../../customHooks/useMyMutations";
import { getUserIdFromCookie } from "../../../helpers/cookieHelper";
import AddColumnForm from "./AddColumnForm";
import AddTaskForm from "./AddTaskForm";
import BoardSelector from "./BoardSelector";
import CreateTaskBoardForm from "./components/CreateTaskBoardForm";
import DeleteModal from "./components/DeleteModal";
import SearchBar from "./components/SearchBar";
import TaskboardMenu from "./components/TaskBoardMenu";
import TaskEventModal from "./components/TaskEventModal";
import UserSelector from "./components/UserSelector";
import Task from "./Task";
import "./TaskBoard.scss";
import TaskColumn from "./TaskColumn";
import ViewTaskDetails from "./ViewTaskDetails";

const ColumnScrollIndicator = ({ totalDots = 10 }) => {
  const [activeDots, setActiveDots] = useState(1); // İlk nokta başlangıçta aktif

  useEffect(() => {
    const container = document.querySelector(".kanban-board__columns");
    if (!container) return;

    const handleScroll = () => {
      const scrollableWidth = container.scrollWidth - container.clientWidth;
      const currentScroll = container.scrollLeft;
      const scrollPercentage = currentScroll / scrollableWidth;
      const newActiveDots = Math.max(
        1,
        Math.round(scrollPercentage * totalDots)
      ); // En az 1 olacak şekilde ayarlandı
      setActiveDots(newActiveDots);
    };

    container.addEventListener("scroll", handleScroll);
    handleScroll(); // İlk yüklemede scroll durumunu kontrol et

    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [totalDots]);

  return (
    <div className="column-scroll-indicator">
      {[...Array(totalDots)].map((_, index) => (
        <span
          key={index}
          className={`dot ${index < activeDots ? "active" : ""}`}
        />
      ))}
    </div>
  );
};

// Örnek veri
// const initialBoards = [
//     {
//         id: 'board1',
//         name: 'Proje 1',
//         columns: [
//             { id: 'todo1', title: 'Yapılacaklar' },
//             { id: 'inProgress1', title: 'Devam Edenler' },
//             { id: 'done1', title: 'Tamamlananlar' },
//         ],
//         tasks: [
//             { id: 'task1', content: 'Görev 1', columnId: 'todo1' },
//             { id: 'task2', content: 'Görev 2', columnId: 'inProgress1' },
//         ],
//     },
//     {
//         id: 'board2',
//         name: 'Proje 2',
//         columns: [
//             { id: 'todo2', title: 'To Do' },
//             { id: 'done2', title: 'Done' },
//         ],
//         tasks: [{ id: 'task3', content: 'Task 3', columnId: 'todo2' }],
//     },
// ];

export default function TaskBoard() {
  const [modalContent, setModalContent] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [boards, setBoards] = useState();
  const [currentBoard, setCurrentBoard] = useState(null);
  // const [taskBoardLoading, setTaskBoardLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState(null);

  const [filterByUserId, setFilterByUserId] = useState(null);

  const currentBoardRef = useRef();

  const location = useLocation();

  const { mutation } = useMyMutations();

  const { loading, error, value } = useFetch(
    GET_TASKBOARD_BY_USER_ID,
    { method: "GET", headers: { userid: getUserIdFromCookie() } },
    []
  );

  useEffect(() => {
    if (!loading) {
      console.log(value);
      setBoards(value);
      if (location.state == null) {
        if (value.length == 0) {
          setCurrentBoard(null);
        } else {
          setCurrentBoard(value[0]);
        }
      } else {
        const getTaskBoard = value.filter(
          (board) => board._id === location.state.taskBoardId
        );
        console.log(getTaskBoard);
        console.log(location.state.taskBoardId);
        setCurrentBoard(getTaskBoard[0]);
      }
    }
  }, [loading]);

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  const filteredTasks = (tasks, column) => {
    return tasks.filter((task) => task.state === column);
  };

  const onDragEnd = async (result) => {
    const { destination, source } = result;
    if (!destination) return;
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return;

    if (source.droppableId === destination.droppableId) {
      const newTasks = Array.from(
        currentBoard.tasks.filter((task) => task.state === source.droppableId)
      );
      const [movedTask] = newTasks.splice(source.index, 1);
      newTasks.splice(destination.index, 0, movedTask);
      const otherTasks = currentBoard.tasks.filter(
        (task) => task.state !== source.droppableId
      );
      const updatedBoard = {
        ...currentBoard,
        tasks: [...newTasks, ...otherTasks],
      };

      setCurrentBoard((currentBoard) => updatedBoard);

      mutation.mutate({
        data: updatedBoard,
        url: UPDATE_TASK_BOARD,
        method: httpMethod.PUT,
        headers: { taskBoardId: currentBoard._id },
      });
    } else {
      const sourceTasks = Array.from(
        currentBoard.tasks.filter((task) => task.state === source.droppableId)
      );
      const destinationTasks = Array.from(
        currentBoard.tasks.filter(
          (task) => task.state === destination.droppableId
        )
      );
      const [movedTask] = sourceTasks.splice(source.index, 1);
      movedTask.state = destination.droppableId;
      destinationTasks.splice(destination.index, 0, movedTask);

      const otherTasks = currentBoard.tasks.filter(
        (task) =>
          task.state !== source.droppableId &&
          task.state !== destination.droppableId
      );
      const updatedBoard = {
        ...currentBoard,
        tasks: [...sourceTasks, ...destinationTasks, ...otherTasks],
      };

      setCurrentBoard((currentBoard) => updatedBoard);

      mutation.mutate({
        data: updatedBoard,
        url: UPDATE_TASK_BOARD,
        method: httpMethod.PUT,
        headers: { taskBoardId: currentBoard._id },
      });
    }
  };

  const addTask = (task) => {
    mutation.mutate(
      {
        data: { ...task, taskBoardId: currentBoard._id }, // Görevi göndermek için gerekli veriler
        url: CREATE_TASK,
        method: httpMethod.POST,
      },
      {
        onSuccess: (res) => {
          // API yanıtını kontrol edin
          console.log("API Response:", res.data.tasks);

          // Dönen görevlerde priority eksikse varsayılan bir değer atayın
          const updatedTasks = res.data.tasks;

          // Güncellenmiş görevleri currentBoard'a ekleyin
          setCurrentBoard((currentBoard) => ({
            ...currentBoard,
            tasks: updatedTasks,
          }));
        },
        onError: (error) => {
          console.error("Failed to create task:", error);
        },
      }
    );
  };

  const editTask = (id, newTask) => {
    console.log("Editing task:", id, newTask); // Debugging için

    const newBoard = {
      ...currentBoard,
      tasks: currentBoard.tasks.map((task) =>
        task._id === id ? { ...task, ...newTask } : task
      ),
    };

    mutation.mutate(
      {
        data: newBoard,
        url: UPDATE_TASK_BOARD,
        method: httpMethod.PUT,
        headers: { taskBoardId: currentBoard._id },
      },
      {
        onSuccess: (res) => {
          console.log("Task updated successfully:", res.data); // Debugging için
          setCurrentBoard((currentBoard) => ({
            ...currentBoard,
            tasks: res.data.tasks,
          }));
        },
      }
    );
  };

  const deleteTask = (id) => {
    const newBoard = {
      ...currentBoard,
      tasks: currentBoard.tasks.filter((task) => task._id !== id),
    };

    mutation.mutate(
      {
        data: newBoard,
        url: UPDATE_TASK_BOARD,
        method: httpMethod.PUT,
        headers: { taskBoardId: currentBoard._id },
      },
      {
        onSuccess: (res) => {
          setCurrentBoard((currentBoard) => ({
            ...currentBoard,
            tasks: res.data.tasks,
          }));
        },
      }
    );
  };

  const addColumn = (state) => {
    const newStates = [...currentBoard.states, state];
    const newBoard = { ...currentBoard, states: newStates };

    mutation.mutate(
      {
        data: newBoard,
        url: UPDATE_TASK_BOARD,
        method: httpMethod.PUT,
        headers: { taskBoardId: currentBoard._id },
      },
      {
        onSuccess: (res) => {
          setCurrentBoard((currentBoard) => ({
            ...currentBoard,
            states: res.data.states,
          }));
        },
      }
    );
  };

  // Sütun adını değiştirme fonksiyonu
  const editColumnTitle = (columnId, newTitle) => {
    const newBoard = {
      ...currentBoard,
      states: currentBoard.states.map((state) =>
        state === columnId ? newTitle : state
      ),
      tasks: currentBoard.tasks.map((task) =>
        task.state == columnId ? { ...task, state: newTitle } : task
      ),
    };

    mutation.mutate(
      {
        data: newBoard,
        url: UPDATE_TASK_BOARD,
        method: httpMethod.PUT,
        headers: { taskBoardId: currentBoard._id },
      },
      {
        onSuccess: (res) => {
          setCurrentBoard((currentBoard) => ({
            ...currentBoard,
            states: res.data.states,
            tasks: res.data.tasks,
          }));
        },
      }
    );
  };

  // Sütunu silme fonksiyonu
  const deleteColumn = (columnId) => {
    const newBoard = {
      ...currentBoard,
      states: currentBoard.states.filter((state) => state !== columnId),
      tasks: currentBoard.tasks.filter((task) => task.state !== columnId),
    };

    mutation.mutate(
      {
        data: newBoard,
        url: UPDATE_TASK_BOARD,
        method: httpMethod.PUT,
        headers: { taskBoardId: currentBoard._id },
      },
      {
        onSuccess: (res) => {
          setCurrentBoard((currentBoard) => ({
            ...currentBoard,
            states: res.data.states,
            tasks: res.data.tasks,
          }));
        },
      }
    );
  };

  const openAddTaskModal = () => {
    setModalContent({
      id: "addTaskModal",
      title: "Add New Task",
      content: (
        <AddTaskForm
          currentBoard={currentBoard}
          onSubmit={(task) => {
            addTask(task);
            closeModal();
          }}
        />
      ),
    });
    setModalVisible(true);
  };

  const openEditTaskModal = (task) => {
    setModalContent({
      id: "editTaskModal",
      title: "Edit Task",
      content: (
        <ViewTaskDetails
          currentBoard={currentBoard}
          initialTask={task}
          onSubmit={(newTask) => {
            editTask(task._id, newTask);
            closeModal();
          }}
        />
      ),
    });
    setModalVisible(true);
  };

  const openAddColumnModal = () => {
    setModalContent({
      id: "addColumnModal",
      title: "Add New Status",
      content: (
        <AddColumnForm
          onSubmit={(title) => {
            addColumn(title);
            closeModal();
          }}
        />
      ),
    });
    setModalVisible(true);
  };

  const openCreateTaskBoardModal = () => {
    setModalContent({
      id: "createTaskBoardModal",
      title: "Create New Task Board",
      content: (
        <CreateTaskBoardForm
          onSubmit={(title) => {
            mutation.mutate(
              {
                data: { name: title },
                url: CREATE_TASKBOARD,
                method: httpMethod.POST,
                headers: { userid: getUserIdFromCookie() },
              },
              {
                onSuccess: (res) => {
                  setCurrentBoard((currentBoard) => res.data);
                },
              }
            );
            closeModal();
          }}
        />
      ),
    });
    setModalVisible(true);
  };

  const openDeleteTaskModal = (id) => {
    setModalContent({
      id: "deleteTaskModal",
      // title: 'Are you sure to delete?',
      content: (
        <DeleteModal
          onSubmit={() => {
            deleteTask(id);
            closeModal();
          }}
          close={closeModal}
        />
      ),
    });
    setModalVisible(true);
  };

  const openDeleteColumnModal = (columnId) => {
    setModalContent({
      id: "deleteTaskModal",
      // title: 'Are you sure to delete?',
      content: (
        <DeleteModal
          onSubmit={() => {
            deleteColumn(columnId);
            closeModal();
          }}
          close={closeModal}
        />
      ),
    });
    setModalVisible(true);
  };
  const [deleteTaskBoardModalVisible, setDeleteTaskBoardModalVisible] =
    useState(false);

  const openDeleteTaskBoardModal = () => {
    setDeleteTaskBoardModalVisible(true);
  };

  const closeDeleteTaskBoardModal = () => {
    setDeleteTaskBoardModalVisible(false);
  };
  const DeleteTaskBoardModal = ({ taskBoards, onDelete }) => (
    <div className="delete-task-board-modal">
      <h2>Select a Task Board to Delete</h2>
      <ul>
        {taskBoards.map((board) => (
          <li
            key={board._id}
            className="task-board-item d-flex justify-content-between"
          >
            <span>{board.name}</span>
            <Button onClick={() => onDelete(board._id)}>Delete</Button>
          </li>
        ))}
      </ul>
      <Button onClick={closeDeleteTaskBoardModal}>Close</Button>
    </div>
  );
  const deleteTaskBoard = (boardId) => {
    console.log(`Deleting TaskBoard with ID: ${boardId}`);
    // Task Board silme işlemi için gerekli API çağrısı yapılır.
    // Silme işlemi başarılı olunca modalı kapatın ve state'i güncelleyin.
    const updatedBoards = boards.filter((board) => board._id !== boardId);
    setBoards(updatedBoards);
    setCurrentBoard(updatedBoards.length ? updatedBoards[0] : null); // İlk boarda geçiş yap

    mutation.mutate(
      {
        data: {},
        url: `${DELETE_TASKBOARD}/${boardId}`,
        method: httpMethod.DELETE,
      },
      {
        onSuccess: () => {
          closeDeleteTaskBoardModal();
        },
      }
    );
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  if (loading) {
    return <PageLoadingAnimation />;
  }

  return (
    <div className="container-fluid-task min-vh-100 p-4">
      <div className="task-board-header">
        <div className="row align-items-center mb-4">
          <div className="col-md-9 d-flex flex-column">
            {/* BoardSelector ve TaskboardMenu üstte */}
            <div className="d-flex flex-wrap align-items-center mb-2">
              <h2 style={{ marginRight: 25 }}>Task Board </h2>
              <div className="board-selector-wrapper me-2">
                <BoardSelector
                  boards={boards}
                  currentBoard={currentBoard}
                  onSelectBoard={(board) => setCurrentBoard(board)}
                />
              </div>
              <TaskboardMenu
                onCreateTaskBoard={openCreateTaskBoardModal}
                onDeleteTaskBoard={openDeleteTaskBoardModal}
              />
            </div>

            {/* SearchBar ve UserSelector aynı yükseklikte */}
            {currentBoard && (
              <div className="search-bar-wrapper mb-2 d-flex">
                <UserSelector
                  users={currentBoard.users}
                  currentBoard={currentBoard}
                  onSelectUser={(userId) => setFilterByUserId(userId)}
                  filterByUserId={filterByUserId}
                  style={{
                    marginRight: "1rem",
                    flex: "1",
                  }}
                />
                <SearchBar
                  onSearch={handleSearch}
                  style={{
                    flex: "2",
                  }}
                />
              </div>
            )}
          </div>

          {currentBoard && (
            <div className="col-md-3 d-flex flex-column">
              <div className="button-group d-flex justify-content-start gap-2">
                <Button className="add-task-btn" onClick={openAddTaskModal}>
                  Add Task
                </Button>
                <Button className="add-task-btn" onClick={openAddColumnModal}>
                  Add Status
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* {
                taskBoardLoading ? (
                    <div className="container-fluid bg-light min-vh-100 p-4">
                        <div className="row">
                            {[1, 2, 3, 4].map((skeleton) => (
                                <div key={skeleton} className="col-md-6 col-lg-3 mb-4">
                                    <div className="card skeleton-card">
                                        <div className="card-body">
                                            <h5 className="skeleton skeleton-title mb-4"></h5>
                                            <div className="skeleton skeleton-task mb-2"></div>
                                            <div className="skeleton skeleton-task mb-2"></div>
                                            <div className="skeleton skeleton-task mb-2"></div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )
                    : ( */}
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="kanban-board__columns">
          {currentBoard !== null ? (
            currentBoard.states.map((column) => (
              <TaskColumn
                key={column}
                column={column}
                onEditTitle={(newTitle) => editColumnTitle(column, newTitle)}
                onDeleteColumn={() => openDeleteColumnModal(column)}
              >
                <Droppable droppableId={column}>
                  {(provided) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className="kanban-board__task-list"
                    >
                      {filteredTasks(currentBoard.tasks, column).map(
                        (task, index) => {
                          if (filterByUserId) {
                            if (filterByUserId === task.appointedUserId) {
                              return (
                                <Draggable
                                  key={task._id}
                                  draggableId={task._id}
                                  index={index}
                                >
                                  {(provided) => {
                                    return (
                                      <Task
                                        currentBoard={currentBoard}
                                        task={task}
                                        startDate={task.startDate}
                                        endDate={task.endDate}
                                        provided={provided}
                                        onEdit={() => openEditTaskModal(task)}
                                        onDelete={(id) =>
                                          openDeleteTaskModal(id)
                                        }
                                      />
                                    );
                                  }}
                                </Draggable>
                              );
                            }
                          } else {
                            return (
                              <Draggable
                                key={task._id}
                                draggableId={task._id}
                                index={index}
                              >
                                {(provided) => {
                                  return (
                                    <Task
                                      currentBoard={currentBoard}
                                      task={task}
                                      provided={provided}
                                      onEdit={() => openEditTaskModal(task)}
                                      onDelete={(id) => openDeleteTaskModal(id)}
                                    />
                                  );
                                }}
                              </Draggable>
                            );
                          }
                        }
                      )}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </TaskColumn>
            ))
          ) : (
            <div className="col-12">No TaskBoard</div>
          )}
          <ColumnScrollIndicator
            totalDots={currentBoard ? currentBoard.states.length : 0}
          />
        </div>
      </DragDropContext>

      {/* )
            } */}

      {modalContent && (
        <TaskEventModal
          modalId={modalContent.id}
          show={modalVisible}
          onClose={closeModal}
          content={
            <div className="kanban-board__modal-content">
              <h2 className="kanban-board__modal-title">
                {modalContent.title}
              </h2>
              {modalContent.content}
            </div>
          }
          type="lg"
          width={500}
        />
      )}
      {deleteTaskBoardModalVisible && (
        <TaskEventModal
          modalId="deleteTaskBoardModal"
          show={deleteTaskBoardModalVisible}
          onClose={closeDeleteTaskBoardModal}
          content={
            <DeleteTaskBoardModal
              taskBoards={boards}
              onDelete={deleteTaskBoard}
            />
          }
          type="lg"
          width={500}
        />
      )}
    </div>
  );
}
