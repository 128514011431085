import React, { useState } from "react";
import Button from "../Button";
import { Edit2Icon, SaveIcon, DeleteIcon, CancelIcon } from "../Icons";
import Input from "../Input";
import "../SalesPipeline.scss";

export default function SalesColumn({
  column,
  cards,
  onEditTitle,
  onDeleteColumn,
  children,
  getColumnTotal,
}) {
  const [editing, setEditing] = useState(false);
  const [newTitle, setNewTitle] = useState(column);
  const [error, setError] = useState("");

  const handleSaveTitle = () => {
    if (newTitle.trim() === "") {
      setError("Column name cannot be empty.");
      return;
    }
    const result = onEditTitle(newTitle);
    if (typeof result === "string") {
      setError(result);
    } else {
      setEditing(false);
      setError("");
    }
  };

  const isSpecialColumn = column === "INCOME" || column === "EXPENSES";

  return (
    <div className="sales-column">
      <div className="column-header">
        {editing ? (
          <div style={{ position: "relative" }}>
            <div
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <Input
                type="text"
                value={newTitle}
                maxLength={35}
                onChange={(e) => {
                  setNewTitle(e.target.value);
                  setError("");
                }}
                onBlur={() => {
                  if (newTitle !== column) {
                    handleSaveTitle();
                  } else {
                    setEditing(false);
                  }
                }}
                autoFocus
                aria-label="Edit column title"
                style={{
                  borderColor: error ? "#ef4444" : undefined,
                  flexGrow: 1,
                  marginRight: "0.5rem",
                }}
              />
              <Button className="sales-btn" onClick={handleSaveTitle}>
                <SaveIcon />
              </Button>
              <Button
                className="sales-btn"
                onClick={() => {
                  setEditing(false);
                  setNewTitle(column);
                  setError("");
                }}
              >
                <CancelIcon />
              </Button>
            </div>
            {error && (
              <p
                className="error-message"
                style={{
                  color: "#ef4444",
                  fontSize: "0.75rem",
                  margin: "0.25rem 0 0",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span style={{ color: "#ef4444", marginRight: "0.25rem" }}>
                  ×
                </span>
                {error}
              </p>
            )}
          </div>
        ) : (
          <div className="column-info">
            <div className="column-header-content">
              <h2
                style={{
                  color:
                    column === "EXPENSES"
                      ? "#ef4444"
                      : column === "INCOME"
                      ? "#10b981"
                      : "inherit",
                }}
              >
                {column}
              </h2>

              <div
                style={{
                  color:
                    column === "EXPENSES"
                      ? "#ef4444"
                      : column === "INCOME"
                      ? "#10b981"
                      : "inherit",
                }}
                className="column-total"
                aria-label={`Column total: $${getColumnTotal(
                  column
                ).toLocaleString()}`}
              >
                {getColumnTotal(column).toLocaleString()} ₺
              </div>
            </div>
            <div className="column-actions">
              {!isSpecialColumn && (
                <>
                  <Button
                    className="icon-btn-sales"
                    onClick={() => setEditing(true)}
                  >
                    <span className="sr-only">Edit column</span>
                    <Edit2Icon aria-hidden="true" />
                  </Button>
                  <Button className="icon-btn-sales" onClick={onDeleteColumn}>
                    <span className="sr-only">Delete column</span>
                    <DeleteIcon aria-hidden="true" />
                  </Button>
                </>
              )}
            </div>
          </div>
        )}
      </div>
      {children}
    </div>
  );
}
