import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import PageLoadingAnimation from "../../../components/Loading/PageLoadingAnimation";
import UpperModal from "../../../components/UpperModal";
import { httpMethod } from "../../../constants/httpMethods";
import {
  GET_PROJECT_BY_ID,
  PROJECT,
  PROJECT_FILE,
  PROJECT_SEND_MESSAGE,
} from "../../../constants/links";
import useMyMutations from "../../../customHooks/useMyMutations";
import { getUserIdFromCookie } from "../../../helpers/cookieHelper";
import DeleteModal from "../task/components/DeleteModal";
import TaskEventModal from "../task/components/TaskEventModal";
import AddProjectFile from "./components/AddProjectFile";
import AddTeamMember from "./components/AddTeamMember";
import LeaveTheProject from "./components/LeaveTheProject";
import TaskListSection from "./components/TaskListSection";
import ViewFileDetails from "./components/ViewFileDetails";
import DeleteMember from "./components/DeleteTeamMember";
import { toast } from "react-toastify";
function ProjectDetail({ userProfile }) {
  const [projectDetail, setProjectDetail] = useState({
    projectTitle: "",
    projectDescription: "",
    startDate: "",
    endDate: "",
    freelancers: [],
    companies: [],
    files: [],
    chat: [],
    users: [],
    taskBoard: [],
    inviteId: "",
  });
  const currentUserId = getUserIdFromCookie();
  const [currentState, setCurrentState] = useState("");
  const location = useLocation();
  const [messageText, setMessageText] = useState("");
  const [projectId, setProjectId] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [activeSection, setActiveSection] = useState("details");
  const chatContainerRef = useRef(null);
  const detailsRef = useRef(null);

  const [modalContent, setModalContent] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  const { mutation } = useMyMutations();
  const navigate = useNavigate();

  const { fullName } = userProfile;

  useEffect(() => {
    if (location.state?.projectId) {
      setProjectId(location.state.projectId);
      fetchProjectDetails(location.state.projectId);
    }
  }, [location.state]);

  useEffect(() => {
    if (!loading && detailsRef.current) {
      detailsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [loading]);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [projectDetail.chat]);

  const fetchProjectDetails = (id) => {
    axios
      .get(GET_PROJECT_BY_ID, {
        headers: {
          projectid: id,
        },
      })
      .then((res) => {
        const data = res.data || {};
        console.log(res.data);
        const allMembers = new Set([
          ...(data.freelancers || []),
          ...(data.companies || []),
          ...(data.chat || []).map((message) => message.fullName),
        ]);
        setProjectDetail({
          ...data,
          teamMembers: Array.from(allMembers),
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const sendMessage = () => {
    if (!messageText.trim()) return;

    axios
      .post(PROJECT_SEND_MESSAGE, {
        projectId: projectId,
        userId: getUserIdFromCookie(),
        fullName: fullName,
        message: messageText,
        date: Date.now().toString(),
      })
      .then((res) => {
        setProjectDetail((prev) => ({
          ...prev,
          chat: [...(prev.chat || []), { fullName, message: messageText }],
        }));
        setMessageText("");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleDeleteMember = (memberId) => {
    setProjectDetail((prev) => ({
      ...prev,
      users: prev.users.filter((user) => user.userId !== memberId),
    }));
  };

  const openDeleteMemberModal = (member) => {
    setModalContent({
      id: "deleteMemberModal",
      content: (
        <DeleteMember
          member={member}
          projectId={projectId}
          onDelete={handleDeleteMember}
          close={closeModal}
        />
      ),
    });
    setModalVisible(true);
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
      setActiveSection(sectionId);
    }
  };

  const handleDeleteFile = (fileId) => {
    const newProject = {
      ...projectDetail,
      files: projectDetail.files.filter((file) => file._id !== fileId && file),
      users: projectDetail.users
        .filter((user) => user.userId !== projectDetail.projectOwnerId)
        .map((user) => user.userId),
    };

    console.log(newProject);

    mutation.mutate(
      {
        data: newProject,
        url: PROJECT,
        method: httpMethod.PUT,
        headers: { projectId: projectDetail._id },
      },
      {
        onSuccess: (res) => {
          setProjectDetail((projectDetail) => ({
            ...projectDetail,
            files: res.data.files,
          }));
        },
      }
    );
  };

  const handleEditFile = (newFile) => {
    const newProject = {
      ...projectDetail,
      files: projectDetail.files.map((file) =>
        file._id === newFile._id ? newFile : file
      ),
      users: projectDetail.users
        .filter((user) => user.userId !== projectDetail.projectOwnerId)
        .map((user) => user.userId),
    };

    mutation.mutate(
      {
        data: newProject,
        url: PROJECT,
        method: httpMethod.PUT,
        headers: { projectId: projectDetail._id },
      },
      {
        onSuccess: (res) => {
          setProjectDetail((projectDetail) => ({
            ...projectDetail,
            files: res.data.files,
          }));
        },
      }
    );
  };

  const openEditFileModal = (file) => {
    setModalContent({
      id: "deleteFileModal",
      content: (
        <ViewFileDetails
          initialFile={file}
          onSubmit={(newFile) => {
            handleEditFile(newFile);
            closeModal();
          }}
          onDelete={(fileId) => openDeleteFileModal(fileId)}
          close={closeModal}
        />
      ),
    });
    setModalVisible(true);
  };

  const openDeleteFileModal = (fileId) => {
    setModalContent({
      id: "deleteFileModal",
      content: (
        <DeleteModal
          onSubmit={() => {
            handleDeleteFile(fileId);
            closeModal();
          }}
          close={closeModal}
        />
      ),
    });
    setModalVisible(true);
  };

  const openCreateFileModal = () => {
    setModalContent({
      id: "createFileModal",
      title: "Create Link",
      content: (
        <AddProjectFile
          onSubmit={(file) => {
            handleCreateFile(file);
            closeModal();
          }}
          close={closeModal}
        />
      ),
    });
    setModalVisible(true);
  };

  const handleCreateFile = (file) => {
    mutation.mutate(
      {
        data: {
          ...file,
          projectId: projectId,
          userId: getUserIdFromCookie(),
          fullName: fullName,
          date: Date.now().toString(),
        },
        url: PROJECT_FILE,
        method: httpMethod.POST,
      },
      {
        onSuccess: (res) => {
          setProjectDetail((projectDetail) => ({
            ...projectDetail,
            files: res.data,
          }));
        },
      }
    );
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const getRandomColor = (name) => {
    const colors = [
      "#FF6B6B",
      "#4ECDC4",
      "#45B7D1",
      "#FFA07A",
      "#98D8C8",
      "#F06292",
      "#AED581",
      "#7986CB",
      "#4DB6AC",
      "#FF8A65",
    ];
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
    return colors[Math.abs(hash) % colors.length];
  };

  const openFileOrLink = (url) => {
    window.open(url, "_blank");
  };

  if (loading) return <PageLoadingAnimation />;

  return (
    <div className="container-fluid py-3 py-md-5 px-3 px-md-5">
      <ToastContainer />
      <UpperModal
        modalId={"addFile"}
        type={"lg"}
        contentHTML={
          <AddProjectFile
            projectId={projectId}
            fullName={fullName}
            setProjectDetail={setProjectDetail}
          />
        }
      />
      <UpperModal
        modalId={"addTeamMember"}
        type={"sm"}
        contentHTML={
          <AddTeamMember
            inviteId={projectDetail.inviteId}
            fullName={fullName}
          />
        }
      />
      <UpperModal
        modalId={"leaveTheProject"}
        type={"md"}
        contentHTML={
          <LeaveTheProject
            projectId={projectId}
            userCount={projectDetail.users?.length || 0}
          />
        }
      />

      <div className="mb-4 mb-md-5">
        <div className="d-flex justify-content-between align-items-center flex-wrap">
          <div>
            <h1 className="h3 fw-bold">{projectDetail.projectTitle}</h1>
          </div>
          <div className="d-flex gap-2 flex-wrap mt-3 mt-md-0">
            <button
              className={`btn ${
                activeSection === "details" ? "btn-dark" : "btn-light"
              } rounded-pill`}
              onClick={() => scrollToSection("details")}
            >
              Details
            </button>
            <button
              className={`btn ${
                activeSection === "files" ? "btn-dark" : "btn-light"
              } rounded-pill`}
              onClick={() => scrollToSection("files")}
            >
              Files
            </button>
            <button
              className={`btn ${
                activeSection === "tasks" ? "btn-dark" : "btn-light"
              } rounded-pill`}
              onClick={() => scrollToSection("tasks")}
            >
              Tasks
            </button>
            <button
              className={`btn ${
                activeSection === "chat" ? "btn-dark" : "btn-light"
              } rounded-pill`}
              onClick={() => scrollToSection("chat")}
            >
              Chat
            </button>
            <button
              className="btn btn-outline-primary rounded-pill"
              data-toggle="modal"
              data-target="#leaveTheProject"
            >
              Leave Project
            </button>
          </div>
        </div>
      </div>

      <div className="row mb-4 mb-md-5" ref={detailsRef}>
        <div className="col-md-8 mb-4 mb-md-0">
          <div id="details" className="card rounded-3 shadow-sm">
            <div className="card-body">
              <h5 className="card-title">Project Details</h5>
              <p>{projectDetail.projectDescription}</p>
              <div className="d-flex justify-content-between mt-3">
                <div>
                  <p className="mb-0 text-muted">Start Date</p>
                  <p>
                    {projectDetail.startDate
                      ? new Date(projectDetail.startDate).toLocaleDateString()
                      : "Not set"}
                  </p>
                </div>
                <div>
                  <p className="mb-0 text-muted">Due Date</p>
                  <p>
                    {projectDetail.endDate
                      ? new Date(projectDetail.endDate).toLocaleDateString()
                      : "Not set"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card rounded-3 shadow-sm h-100">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h5 className="card-title mb-0">Team Members</h5>
                <button
                  className="btn btn-outline-primary btn-sm rounded-pill"
                  data-toggle="modal"
                  data-target="#addTeamMember"
                >
                  Add Team Member
                </button>
              </div>
              <ul className="list-unstyled">
                {projectDetail.users &&
                  projectDetail.users.length !== 0 &&
                  projectDetail.users.map((user, index) => (
                    <li key={index} className="mb-2">
                      <div className="d-flex align-items-center">
                        <div className="me-2">
                          <img
                            className="rounded-circle"
                            style={{
                              width: "40px",
                              height: "40px",
                              objectFit: "cover",
                            }}
                            src={user.profileImg}
                            alt={user.fullName}
                          />
                        </div>
                        <span
                          onClick={() => {
                            console.log(
                              "Navigating to user profile:",
                              user.linkNick
                            );
                            navigate("/dashboard/" + user.linkNick);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          {user.fullName}{" "}
                          {user.userId === projectDetail.projectOwnerId && "👑"}
                        </span>
                        {/* Sadece projectOwner butonu görebilecek */}
                        {currentUserId === projectDetail.projectOwnerId &&
                          user.userId !== projectDetail.projectOwnerId && (
                            <button
                              className="btn btn-sm  ms-7"
                              style={{ marginLeft: "auto", color: "red" }} // Butonu en sağa yaslar
                              onClick={() => {
                                console.log(
                                  "Attempting to remove member:",
                                  user
                                );
                                openDeleteMemberModal(user);
                              }}
                            >
                              Remove
                            </button>
                          )}
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div id="files" className="card mb-4 mb-md-5 rounded-3 shadow-sm">
        <div className="card-body">
          <h5 className="card-title">Files & Links</h5>
          <ul className="list-group list-group-flush">
            {projectDetail.files &&
              projectDetail.files.map((file, index) => (
                <li
                  key={index}
                  className="list-group-item d-flex justify-content-between align-items-center border-0"
                >
                  <div>
                    <p className="mb-0 fw-bold">
                      {file.fileName.length > 100
                        ? file.fileName.substring(0, 100) + "..."
                        : file.fileName}
                    </p>
                    <small className="text-muted">
                      Uploaded by: {file.fullName}
                    </small>
                  </div>
                  <button
                    className="btn btn-sm btn-light rounded-pill"
                    onClick={() => openEditFileModal(file)}
                  >
                    View
                  </button>
                </li>
              ))}
          </ul>
          <button
            className="btn btn-primary mt-3 rounded-pill"
            onClick={openCreateFileModal}
          >
            Add File
          </button>
        </div>
      </div>

      <div id="tasks" className="mb-4 mb-md-5">
        {projectDetail.taskBoard && (
          <TaskListSection taskBoard={projectDetail.taskBoard} />
        )}
      </div>

      <div id="chat" className="card rounded-3 shadow-sm">
        <div className="card-body">
          <h5 className="card-title border-bottom pb-2 mb-3">
            {projectDetail.projectTitle} Chat
          </h5>
          <div
            ref={chatContainerRef}
            className="chat-messages mb-3"
            style={{ height: "300px", overflowY: "auto" }}
          >
            {projectDetail.chat &&
              projectDetail.chat.map((message, index) => (
                <div
                  key={index}
                  className={`mb-2 d-flex ${
                    message.fullName === fullName
                      ? "justify-content-end"
                      : "justify-content-start"
                  }`}
                >
                  <div
                    style={{
                      maxWidth: "70%",
                      backgroundColor:
                        message.fullName === fullName ? "#F0F4F8" : "#E8F0FE",
                    }}
                    className="p-2 rounded"
                  >
                    <strong style={{ color: getRandomColor(message.fullName) }}>
                      {message.fullName}
                    </strong>
                    <p className="mb-0">{message.message}</p>
                  </div>
                </div>
              ))}
          </div>
          <div className="input-group">
            <input
              type="text"
              className="form-control rounded-pill"
              placeholder="Type a message..."
              value={messageText}
              onChange={(e) => setMessageText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  sendMessage();
                }
              }}
            />
            <button
              className="btn btn-primary rounded-pill ms-2"
              onClick={sendMessage}
            >
              Send
            </button>
          </div>
        </div>
      </div>

      {modalContent && (
        <TaskEventModal
          modalId={modalContent.id}
          show={modalVisible}
          onClose={closeModal}
          content={
            <div className="kanban-board__modal-content">
              <h2 className="kanban-board__modal-title">
                {modalContent.title}
              </h2>
              {modalContent.content}
            </div>
          }
          type="lg"
          width={500}
        />
      )}
    </div>
  );
}

export default ProjectDetail;
