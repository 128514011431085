import React, { useEffect } from "react";
import ProjectCardDark from "./ProjectCardDark";
import "../styles/ProjectSectionDark.scss";
import { useGetBlogsByUserId } from "../../../../customHooks/useGetBlogsByUserId";

const ProjectsSectionDark = ({ userId }) => {
  const [blogs, loading] = useGetBlogsByUserId(userId);

  return (
    <div className="projects-section-dark">
      {blogs !== undefined &&
        blogs.map((blog, index) => <ProjectCardDark key={index} {...blog} />)}
    </div>
  );
};

export default ProjectsSectionDark;
