import axios from 'axios'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { Button, Container, Input } from 'reactstrap'
import { JOIN_PROJECT } from '../../../../constants/links'
import { getUserIdFromCookie } from '../../../../helpers/cookieHelper'

function JoinWithID({ userProfile }) {
    const [inviteId, setInviteId] = useState()

    const join = () => {

        axios.post(JOIN_PROJECT, {
            userId: getUserIdFromCookie(),
            inviteId
        })
            .then((res) => {
                toast.success(res.data, { theme: "colored", position: "bottom-right" })

                setInviteId('')
            })
            .catch((err) => {
                // toast.error(err.data.response.message, { theme: "colored", position: "bottom-right" })
                toast.error(err.response.data.message, { theme: "colored", position: "bottom-right" })
                setInviteId('')

            })


    }
    return (
        <div className="page-content" style={{ textAlign: 'center' }}>
            <Container className='d-flex' style={{ flexDirection: 'column', justifyContent: 'center' }}>

                <p>Join the project with ID</p>
                <h4>Enter Project ID</h4>
                <Input onChange={(e) => setInviteId(e.target.value)} />
                <Button type="submit" color="dark" className='mt-3' data-dismiss="modal" onClick={join}  >
                    Join Project
                </Button>
                {/* <Col lg="12"> */}
                {/* <Card style={{ border: 'none' }}>
                    <CardBody>
                        <CardTitle className="mb-4">Create New Ticket</CardTitle> */}

                {/* <Row className="justify-content-end">
                    <Col lg="10">
                        <Button type="submit" color="dark" >
                            Create Ticket
                        </Button>
                    </Col>
                </Row> */}
                {/* </CardBody>
                </Card> */}
                {/* </Col> */}
            </Container>
        </div>
    )
}

export default JoinWithID