import React, { useState } from "react";

function ContentEditableExample() {
  const [text, setText] = useState("");

  const handleInput = (e) => {
    setText(e.target.innerHTML); // innerHTML ile metni olduğu gibi alıyoruz
  };

  const handleSubmit = () => {
    console.log(text); // Metni işleyebilirsiniz, bu metin boşluklar ve satırlarla saklanacak
  };

  return (
    <div>
      <div
        contentEditable="true" // Bu alan kullanıcı tarafından düzenlenebilir
        onInput={handleInput} // Kullanıcı girişi takip ediliyor
        style={{
          border: "1px solid black",
          padding: "10px",
          width: "300px",
          height: "150px",
          overflow: "auto",
          whiteSpace: "pre-wrap", // Boşluklar ve satırlar korunacak
          wordWrap: "break-word",
        }}
      ></div>

      <br />
    </div>
  );
}

export default ContentEditableExample;
