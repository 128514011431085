import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GET_UNREAD_NOTIFICATIONS } from "../../constants/links";
import {
  cookieIsDefined,
  getUserIdFromCookie
} from "../../helpers/cookieHelper";
import useWindowDimensions from "../../helpers/useWindowDimension";
import { setUserProfile } from "../../redux/userProfile/userProfileSlice";
import { styles } from "../styles/topBarStyles";
import FreelancerMenuButton from "./components/FreelancerMenuButton";
function TopBar({ isMenuOpen, setIsMenuOpen, userProfile }) {
  const { height, width } = useWindowDimensions();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [unReadNotifications, setUnReadNotifications] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (cookieIsDefined()) {

      axios
        .get(GET_UNREAD_NOTIFICATIONS + getUserIdFromCookie())
        .then((res) => {
          setUnReadNotifications(res.data);
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, []);

  const logout = () => {
    Cookies.remove("jwt_user");
    dispatch(setUserProfile({}));
    navigate("/login");
  };

  return (
    <header id="page-topbar" style={{ color: "white" }}>
      <div class="navbar-header">
        <div class="d-flex">
          <div
            class="navbar-brand-box p-2"
            style={isMenuOpen ? {} : { width: "81px" }}
          >
            <a
              onClick={() => navigate("/dashboard")}
              style={{ cursor: "pointer" }}
              class=""
            >
              {isMenuOpen && width > 530 ? (
                <div
                  style={{
                    height: 50,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src="/assets/images/logo-text-white.svg"
                    alt=""
                    height="35"
                  />
                </div>
              ) : (
                <span class="logo-lg">
                  <img src="/assets/images/logo.png" alt="" height="45" />
                </span>
              )}
            </a>
          </div>

          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            type="button"
            class="btn btn-sm px-3 font-size-16 header-item"
            id="vertical-menu-btn"
          >
            <i class="fa fa-fw fa-bars header-vertical-btn"></i>
          </button>
          {/* 
                    <form class="app-search d-none d-xl-block">
                        <div class="position-relative">
                            <input type="text" class="form-control" placeholder="Search" />
                            <span class="bx bx-search-alt"></span>
                        </div>

                    </form> */}
        </div>

        <div class="d-flex">
          {/* <div class="dropdown d-none d-lg-inline-block ms-1" ngbDropdown>
                        <button type="button" class="btn header-item noti-icon dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown">
                            <i class="material-symbols-outlined">
                                psychology
                            </i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end" aria-labelledby="dropdownMenuButton">
                            <div class="px-lg-2">
                                <div class="row no-gutters">
                                    <a class="dropdown-item" href="#">
                                        ChatGPT
                                    </a>
                                    <a class="dropdown-item" href="#">
                                        Other Ai Bots
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div> */}

          {/* <div class="dropdown d-none d-lg-inline-block ms-1">
                        <button type="button" class="btn header-item noti-icon" data-toggle="fullscreen">
                            <i class="bx bx-fullscreen"></i>
                        </button>
                    </div> */}

          {/* <div class="dropdown d-inline-block" >
                        <button type="button" class="btn header-item noti-icon" data-toggle="dropdown" id="page-header-notifications-dropdown"
                            ngbDropdownToggle>
                            <i class="bx bx-bell"></i>
                            <span class="badge bg-danger rounded-pill">3</span>
                        </button>
                        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                            aria-labelledby="page-header-notifications-dropdown" >
                            <div class="p-3">
                                <div class="row align-items-center">
                                    <div class="col">
                                        <h6 class="m-0"> </h6>
                                    </div>
                                    <div class="col-auto">
                                        <a href="javascript: void(0);" class="small"></a>
                                    </div>
                                </div>
                            </div>
                            <div style={{ position: "relative", height: "230px;" }}>
                                <a href="javascript: void(0);" class="text-reset notification-item">
                                    <div class="media">
                                        <div class="avatar-xs me-3">
                                            <span class="avatar-title bg-primary rounded-circle font-size-16">
                                                <i class="bx bx-cart"></i>
                                            </span>
                                        </div>
                                        <div class="media-body">
                                            <h6 class="mt-0 mb-1"></h6>
                                            <div class="font-size-12 text-muted">
                                                <p class="mb-1"></p>
                                                <p class="mb-0"><i class="mdi mdi-clock-outline"></i>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                                <a href="javascript: void(0);" class="text-reset notification-item">
                                    <div class="media">
                                        <img src="assets/images/users/avatar-3.jpg" class="me-3 rounded-circle avatar-xs" alt="user-pic" />
                                        <div class="media-body">
                                            <h6 class="mt-0 mb-1"></h6>
                                            <div class="font-size-12 text-muted">
                                                <p class="mb-1"></p>
                                                <p class="mb-0"><i class="mdi mdi-clock-outline"></i></p>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                                <a href="javascript: void(0);" class="text-reset notification-item">
                                    <div class="media">
                                        <div class="avatar-xs me-3">
                                            <span class="avatar-title bg-success rounded-circle font-size-16">
                                                <i class="bx bx-badge-check"></i>
                                            </span>
                                        </div>
                                        <div class="media-body">
                                            <h6 class="mt-0 mb-1"></h6>
                                            <div class="font-size-12 text-muted">
                                                <p class="mb-1"></p>
                                                <p class="mb-0"><i class="mdi mdi-clock-outline"></i></p>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                                <a href="javascript: void(0);" class="text-reset notification-item">
                                    <div class="media">
                                        <img src="assets/images/users/avatar-4.jpg" class="me-3 rounded-circle avatar-xs" alt="user-pic" />
                                        <div class="media-body">
                                            <h6 class="mt-0 mb-1"></h6>
                                            <div class="font-size-12 text-muted">
                                                <p class="mb-1"></p>
                                                <p class="mb-0"><i class="mdi mdi-clock-outline"></i></p>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="p-2 border-top d-grid">
                                <a class="btn btn-sm btn-link font-size-14 text-center" href="javascript:void(0)">
                                    <i class="mdi mdi-arrow-right-circle me-1"></i> <span key="t-view-more">
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div> */}

          <button
            type="button"
            class="btn header-item "
            data-toggle="dropdown"
            id="notification-dropdown"
          >
            <i
              style={{ color: "white", fontSize: 35, position: "relative" }}
              class="bx bxs-bell"
            >
              {unReadNotifications.length !== 0 ? (
                <span
                  style={{
                    backgroundColor: "red",
                    position: "absolute",
                    top: 0,
                    right: 0,
                    display: "block",
                    fontSize: 13,
                    borderRadius: "50%",
                  }}
                  class="badge badge-pill badge-danger"
                >
                  {unReadNotifications.length}
                </span>
              ) : (
                <></>
              )}
            </i>
          </button>


          {/* Sadece freelancer için */}
          <div
            class="dropdown-menu left-0"
            aria-labelledby="notification-dropdown"
            style={{ backgroundColor: "#1a1a1a" }}
          >
            {unReadNotifications.length !== 0 ? (
              unReadNotifications.map((notification) => {
                return (
                  <div
                    // to={"/dashboard/notifications"}
                    onClick={() => {
                      setUnReadNotifications([]);
                      navigate("/dashboard/notifications", {
                        state: {
                          notification,
                        },
                      });
                    }}
                    className="dropdown-item"
                    style={{ color: "white", cursor: "pointer" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        <h6>{notification.fullName}</h6>
                        <p>{notification.jobDesc}</p>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div
                className="dropdown-item"
                style={{ color: "gray", cursor: "pointer" }}
              >
                <h6>No Notifications</h6>
              </div>
            )}
          </div>

          {loading ? (
            <ReactLoading
              type={"spinningBubbles"}
              color={"white"}
              height={"40px"}
              width={"40px"}
            />
          ) : (
            <div style={{ display: "flex", flexDirection: "row" }}>

              <FreelancerMenuButton
                profileImg={userProfile.profileImg}
                fullName={userProfile.fullName}
              />


              <div
                class="dropdown-menu"
                aria-labelledby="page-header-user-dropdown"
                style={{ backgroundColor: "#1a1a1a" }}
              >

                <a
                  onClick={() => navigate("/dashboard/account")}
                  style={styles.dropdownItem}
                  class="dropdown-item"
                >
                  <i class="bx bx-user font-size-16 align-middle me-1"></i>
                  My Account
                </a>
                <>
                  <a
                    onClick={() =>
                      navigate("/dashboard/" + userProfile.linkNick)
                    }
                    style={styles.dropdownItem}
                    class="dropdown-item"
                  >
                    <i class="bx bx-user font-size-16 align-middle me-1"></i>
                    My Portfolio
                  </a>
                  <a
                    onClick={() =>
                      navigate("/dashboard/upper-profile-create")
                    }
                    style={styles.dropdownItem}
                    class="dropdown-item"
                  >
                    <i class="bx bx-pencil font-size-16 align-middle me-1"></i>
                    Edit Portfolio
                  </a>
                </>


                {/* <a class="dropdown-item" href="javascript: void(0);"><i
                class="bx bx-wallet font-size-16 align-middle me-1"></i></a>
            <a class="dropdown-item d-block" href="javascript: void(0);"><span
                class="badge bg-success float-end">11</span><i class="bx bx-wrench font-size-16 align-middle me-1"></i>
            </a>
            <button className='btn btn-primary' onClick={() => setRole('freelancer')}>Freelancer</button>
            <button className='btn btn-warning' onClick={() => setRole('company')}>Company</button>
            <a class="dropdown-item" href="javascript: void(0);"><i
                class="bx bx-lock-open font-size-16 align-middle me-1"></i>
            </a>
            <div class="dropdown-divider"></div>
            */}
                <a
                  onClick={logout}
                  style={styles.dropdownItem}
                  class="dropdown-item text-danger"
                >
                  <i class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
                  Log Out
                </a>
              </div>
            </div>
          )}

          {/* <div class="dropdown d-inline-block">
                        <button type="button" class="btn header-item noti-icon right-bar-toggle" >
                            <i style={{ color: 'white' }} class="bx bx-cog"></i>
                        </button>
                    </div> */}
        </div>
      </div>
    </header>
  );
}

export default TopBar;
