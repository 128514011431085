import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import { GET_BY_LINK_NICK, GET_USER_PROFILE } from './constants/links'
import axios from "axios";
import ErrorPage from "../../../components/Errors/ErrorPage";
import PageLoadingAnimation from "../../../components/Loading/PageLoadingAnimation";
import { GET_BY_LINK_NICK, INC_VISIT_COUNT } from "../../../constants/links";
import useFetch from "../../../customHooks/useFetch";
import { cookieIsDefined } from "../../../helpers/cookieHelper";
import { getLinkNickFromUrl } from "../../../helpers/urlHelper";

import ShowFloykaThemeDark from "../../../Themes/FloykaTheme/Dark/ShowFloykaThemeDark";
import ShowFloykaTheme from "../../../Themes/FloykaTheme/Light/ShowFloykaTheme";
import ShowUpperProfile from "./ShowUpperProfile";
function ShowUpperProfileMain({ inDashboard }) {
  const navigate = useNavigate();
  const location = useLocation();

  const [emailFrom, setEmailFrom] = useState("");
  const [notificationInfo, setNotificationInfo] = useState({
    email: "",
    fullName: "",
    projectDescription: "",
    estimatedBudget: "",
    startDate: "",
    endDate: "",
  });

  const { linkNick, length } = getLinkNickFromUrl(location.pathname);

  const { loading, error, value } = useFetch(
    GET_BY_LINK_NICK + linkNick,
    { method: "GET" },
    [linkNick]
  );

  useEffect(() => {
    window.scrollTo(0, 0);

    if (cookieIsDefined()) {
      navigate("/dashboard/" + linkNick);
    } else if (!cookieIsDefined()) {
      navigate("/" + linkNick);
    }

    axios
      .get(INC_VISIT_COUNT + linkNick)
      .then((res) => { })
      .catch((err) => {
        console.log(err);
      });
  }, [location.pathname]);

  const handleNotificationInfo = (value, name) => {
    let updatedValue = { [name]: value };
    setNotificationInfo((notificationInfo) => ({
      ...notificationInfo,
      ...updatedValue,
    }));
    console.log(notificationInfo);
  };

  if (error) {
    return <ErrorPage error={error} />;
  }

  if (loading) {
    return <PageLoadingAnimation />;
  }

  const handleTheme = () => {
    switch (value.activeTheme) {
      case "FLOYKA":
        return (
          <ShowFloykaTheme
            portfolio={value}
            emailFrom
            handleNotificationInfo={handleNotificationInfo}
            inDashboard={inDashboard}
            notificationInfo={notificationInfo}
            setEmailFrom={setEmailFrom}
            setNotificationInfo={setNotificationInfo}
          />
        );
      case "FLOYKA_DARK":
        return (
          <ShowFloykaThemeDark
            portfolio={value}
            emailFrom
            handleNotificationInfo={handleNotificationInfo}
            inDashboard={inDashboard}
            notificationInfo={notificationInfo}
            setEmailFrom={setEmailFrom}
            setNotificationInfo={setNotificationInfo}
          />
        );

      default:
        return (
          <ShowUpperProfile
            activeTheme={value.activeTheme}
            portfolio={value}
            emailFrom
            handleNotificationInfo={handleNotificationInfo}
            inDashboard={inDashboard}
            notificationInfo={notificationInfo}
            setEmailFrom={setEmailFrom}
            setNotificationInfo={setNotificationInfo}
          />
        );
        break;
    }
  };

  return <div>{handleTheme()}</div>;
}

export default ShowUpperProfileMain;
