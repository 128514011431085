import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import * as Queries from '../../../utils/queries';
import { cookieIsDefined, getUser } from '../../../helpers/cookieHelper';
import Messages from './components/messages';
import PinCode from './components/pinCode';
import AcceptedProposal from './components/acceptedProposal';
import PageLoadingAnimation from "../../../components/Loading/PageLoadingAnimation";

import '../../../styles/pages/proposal/proposal.scss';

const useSearchParams = () => {
  const searchParams = new URLSearchParams(window.location.search);
  return Object.fromEntries(searchParams);
};

const useUser = () => (cookieIsDefined() ? getUser() : null);

const useProposalData = (chatId) => {
  return useQuery({
    queryKey: ['proposal', chatId],
    queryFn: () => Queries.getProposalById(chatId),
  });
};

const PrivateChat = () => {
  const searchParams = useSearchParams();
  const user = useUser();
  const [isPinCorrect, setIsPinCorrect] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);
  const [isClient, setIsClient] = useState(true);

  const { data, isLoading } = useProposalData(searchParams.chat);

  const handlePinCode = () => setIsPinCorrect(true);

  useEffect(() => {
    if (data?.status === 'accepted' && data.projectId) {
      if (isClient) {
        setIsAccepted(true);
      } else {
        document.location.href = '/dashboard/projects';
      }
    }
    if (user && data) {
      setIsClient(!user || (data?.freelancer?.id !== user?.userId));
    }
  }, [data, isClient]);

  if (isLoading) return <PageLoadingAnimation />

  if (isClient && !isPinCorrect) {
    return <PinCode setIsPinCorrect={handlePinCode} proposalId={searchParams.chat} defaultOtp={searchParams.pinCode} />;
  }

  return (
    <>
      {isAccepted && <AcceptedProposal projectId={data?.projectId} />}
      <Messages isClient={isClient} proposalInfo={data} />
    </>
  );
};

export default PrivateChat;