import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import PageLoadingAnimation from "../../../components/Loading/PageLoadingAnimation";
import { httpMethod } from "../../../constants/httpMethods";
import {
  GET_SALES_PIPELINE_BY_USER_ID,
  UPDATE_SALES_PIPELINE,
} from "../../../constants/links";
import useFetch from "../../../customHooks/useFetch";
import useMyMutations from "../../../customHooks/useMyMutations";
import { getUserIdFromCookie } from "../../../helpers/cookieHelper";
import DeleteModal from "../task/components/DeleteModal";
import TaskEventModal from "../task/components/TaskEventModal";
import Button from "./Button";
import Card from "./Card";
import CardContent from "./CardContent";
import { Edit2Icon, SaveIcon, PlusIcon, CancelIcon } from "./Icons";
import Input from "./Input";
import "./SalesPipeline.scss";
import AddNewCardForm from "./components/AddNewCardForm";
import SalesColumn from "./components/SalesColumn";
import EditCardForm from "./components/EditCardForm";
import ColumnScrollIndicator from "./components/ColumnScrollIndicator";
import SearchBar from "../task/components/SearchBar";
function SalesPipeline() {
  const [salesPipeline, setSalesPipeline] = useState({
    states: ["INCOME", "EXPENSES"],
    cashCards: [],
  });
  const [modalContent, setModalContent] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [columnError, setColumnError] = useState("");
  const [editingCardId, setEditingCardId] = useState(null);
  const [editingCardData, setEditingCardData] = useState({});
  const [editingColumn, setEditingColumn] = useState(null);
  const [newColumnTitle, setNewColumnTitle] = useState("");
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddColumnInput, setShowAddColumnInput] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [showAddColumnModal, setShowAddColumnModal] = useState(false);

  const { mutation } = useMyMutations();
  const { loading, error, value } = useFetch(
    GET_SALES_PIPELINE_BY_USER_ID,
    { method: httpMethod.GET, headers: { userid: getUserIdFromCookie() } },
    []
  );

  useEffect(() => {
    if (!loading) {
      console.log("Fetched value:", value);
      setSalesPipeline(value);
    }
  }, [loading]);
  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  const openEditModal = (card) => {
    setEditingCardData(card);
    setShowEditModal(true);
  };

  const closeEditModal = () => {
    setShowEditModal(false);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {
      const sources = salesPipeline.cashCards.filter(
        (card) => card.state === source.droppableId
      );
      const dests = salesPipeline.cashCards.filter(
        (card) => card.state === destination.droppableId
      );

      const otherCards = salesPipeline.cashCards.filter(
        (card) =>
          card.state !== destination.droppableId &&
          card.state !== source.droppableId
      );

      if (sources && dests) {
        const [removed] = sources.splice(source.index, 1);
        removed.state = destination.droppableId;
        dests.splice(destination.index, 0, removed);

        const newSalesPipeline = {
          ...salesPipeline,
          cashCards: [...dests, ...sources, ...otherCards],
        };

        setSalesPipeline((salesPipeline) => newSalesPipeline);

        mutation.mutate({
          data: newSalesPipeline,
          url: UPDATE_SALES_PIPELINE,
          method: httpMethod.PUT,
          headers: { salesPipelineId: salesPipeline._id },
        });
      }
    } else {
      const cards = salesPipeline.cashCards.filter(
        (card) => card.state === source.droppableId
      );
      const destCards = salesPipeline.cashCards.filter(
        (card) => card.state !== destination.droppableId
      );
      const copiedCards = Array.from(cards);
      const [removed] = copiedCards.splice(source.index, 1);
      copiedCards.splice(destination.index, 0, removed);

      const newSalesPipeline = {
        ...salesPipeline,
        cashCards: [...copiedCards, ...destCards],
      };
      setSalesPipeline((salesPipeline) => newSalesPipeline);

      mutation.mutate({
        data: newSalesPipeline,
        url: UPDATE_SALES_PIPELINE,
        method: httpMethod.PUT,
        headers: { salesPipelineId: salesPipeline._id },
      });
    }
  };

  const openAddColumnModal = () => {
    // [+]
    setShowAddColumnModal(true);
  };

  const closeAddColumnModal = () => {
    // [+]
    setShowAddColumnModal(false);
    setNewColumnTitle("");
    setColumnError("");
  };
  const openAddCardModal = () => {
    setModalContent({
      id: "addCardModal",
      title: "Add New Card",
      content: (
        <AddNewCardForm
          salesPipeline={salesPipeline}
          onSubmit={(card) => {
            addNewCard(card);
            closeModal();
          }}
        />
      ),
    });
    setModalVisible(true);
  };

  const openDeleteTaskModal = (id) => {
    setModalContent({
      id: "deleteCardModal",
      // title: 'Are you sure to delete?',
      content: (
        <DeleteModal
          onSubmit={() => {
            deleteCard(id);
            closeModal();
          }}
          close={closeModal}
        />
      ),
    });
    setModalVisible(true);
  };

  const openDeleteColumnModal = (columnId) => {
    setModalContent({
      id: "deleteColumnModal",
      // title: 'Are you sure to delete?',
      content: (
        <DeleteModal
          onSubmit={() => {
            deleteColumn(columnId);
            closeModal();
          }}
          close={closeModal}
        />
      ),
    });
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const deleteColumn = (columnId) => {
    const newSalesPipeline = {
      ...salesPipeline,
      states: salesPipeline.states.filter((state) => state !== columnId),
      cashCards: salesPipeline.cashCards.filter(
        (card) => card.state !== columnId
      ),
    };

    mutation.mutate(
      {
        data: newSalesPipeline,
        url: UPDATE_SALES_PIPELINE,
        method: httpMethod.PUT,
        headers: { salesPipelineId: salesPipeline._id },
      },
      {
        onSuccess: (res) => {
          setSalesPipeline((salesPipeline) => ({
            ...salesPipeline,
            states: res.data.states,
            cashCards: res.data.cashCards,
          }));
        },
      }
    );
  };

  const deleteCard = (cardId) => {
    const newSalesPipeline = {
      ...salesPipeline,
      cashCards: salesPipeline.cashCards.filter((card) => card._id !== cardId),
    };

    mutation.mutate(
      {
        data: newSalesPipeline,
        url: UPDATE_SALES_PIPELINE,
        method: httpMethod.PUT,
        headers: { salesPipelineId: salesPipeline._id },
      },
      {
        onSuccess: (res) => {
          setSalesPipeline((salesPipeline) => ({
            ...salesPipeline,
            cashCards: res.data.cashCards,
          }));
        },
      }
    );
  };

  const getColumnTotal = (columnId) => {
    // const cards = salesPipeline.cashCards.find((card) => card.state === columnId);
    const cards = salesPipeline.cashCards.filter(
      (card) => card.state === columnId
    );
    if (!cards) return 0;

    return cards.reduce((sum, card) => {
      const cardValue = isNaN(card.price) ? 0 : card.price;
      return sum + cardValue;
    }, 0);
  };
  const filteredCards = salesPipeline.cashCards.filter((card) =>
    card.title.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const addNewColumn = () => {
    const restrictedNames = ["INCOME", "EXPENSES"];

    if (newColumnTitle.trim() === "") {
      setColumnError("Column name cannot be empty.");
      console.log(columnError);
    } else if (restrictedNames.includes(newColumnTitle.toUpperCase())) {
      setColumnError("Column name cannot be 'INCOME' or 'EXPENSES'.");
    } else if (salesPipeline.states.includes(newColumnTitle)) {
      setColumnError("A column with the same name already exists.");
    } else {
      const newSalesPipeline = {
        ...salesPipeline,
        states: [...salesPipeline.states, newColumnTitle],
      };

      mutation.mutate(
        {
          data: newSalesPipeline,
          url: UPDATE_SALES_PIPELINE,
          method: httpMethod.PUT,
          headers: { salesPipelineId: salesPipeline._id },
        },
        {
          onSuccess: (res) => {
            setSalesPipeline((prevState) => ({
              ...prevState,
              states: res.data.states,
            }));
            setNewColumnTitle("");
            setColumnError("");
            setShowAddColumnModal(false);
            setShowAddColumnInput(false);
          },
        }
      );
    }
  };

  const editColumnTitle = (columnId, newTitle) => {
    const restrictedNames = ["INCOME", "EXPENSES"];

    if (newTitle.trim() === "") {
      return "Column name cannot be empty.";
    } else if (restrictedNames.includes(newTitle.toUpperCase())) {
      return "Column name cannot be 'INCOME' or 'EXPENSES'.";
    } else if (
      salesPipeline.states.includes(newTitle) &&
      newTitle !== columnId
    ) {
      return "A column with the same name already exists.";
    } else {
      const newSalesPipeline = {
        ...salesPipeline,
        states: salesPipeline.states.map((state) =>
          state === columnId ? newTitle : state
        ),
        cashCards: salesPipeline.cashCards.map((card) =>
          card.state === columnId ? { ...card, state: newTitle } : card
        ),
      };

      setSalesPipeline(newSalesPipeline);
      setEditingColumn(null);
      return null;
    }
  };

  const addNewCard = (card) => {
    const newSalesPipeline = {
      ...salesPipeline,
      cashCards: [...salesPipeline.cashCards, card],
    };

    mutation.mutate(
      {
        data: newSalesPipeline,
        url: UPDATE_SALES_PIPELINE,
        method: httpMethod.PUT,
        headers: { salesPipelineId: salesPipeline._id },
      },
      {
        onSuccess: (res) => {
          setSalesPipeline((salesPipeline) => ({
            ...salesPipeline,
            cashCards: res.data.cashCards,
          }));
        },
      }
    );
  };

  const editCard = (cardId) => {
    const card = salesPipeline.cashCards.find((card) => card._id === cardId);
    openEditModal(card);
  };
  const saveEditedCard = (updatedCard) => {
    const newSalesPipeline = {
      ...salesPipeline,
      cashCards: salesPipeline.cashCards.map((card) =>
        card._id === updatedCard._id ? updatedCard : card
      ),
    };

    mutation.mutate(
      {
        data: newSalesPipeline,
        url: UPDATE_SALES_PIPELINE,
        method: httpMethod.PUT,
        headers: { salesPipelineId: salesPipeline._id },
      },
      {
        onSuccess: (res) => {
          setSalesPipeline((salesPipeline) => ({
            ...salesPipeline,
            cashCards: res.data.cashCards,
          }));
          closeEditModal(); // Düzenlemeyi kaydettikten sonra modalı kapat
        },
      }
    );
  };

  const changeCardStatus = (cardId, newStatus) => {
    // `salesPipeline`'i klonluyoruz
    const newSalesPipeline = { ...salesPipeline };
    //REFERANS SİSTEMİ YENİ OBJE OLUŞTURMADAN DEĞİŞTİRMİYOR EKRANI UNUTMA

    //const updatedPipeline = salesPipeline ; YAPARSAN STATE DEĞİŞMEZ ÇÜNKÜ REFERANSI DEĞİŞMİYOR YENİ OBJE OLMADIĞI İÇİN

    // Güncellenecek kartı buluyoruz
    const editingCardIndex = newSalesPipeline.cashCards.findIndex(
      (card) => card._id === cardId
    );

    if (editingCardIndex !== -1) {
      // Kartın `state` durumunu güncelliyoruz
      newSalesPipeline.cashCards[editingCardIndex].state = newStatus;

      // Güncellenmiş pipeline'ı state'e set ediyoruz
      mutation.mutate(
        {
          data: newSalesPipeline,
          url: UPDATE_SALES_PIPELINE,
          method: httpMethod.PUT,
          headers: { salesPipelineId: salesPipeline._id },
        },
        {
          onSuccess: (res) => {
            setSalesPipeline((salesPipeline) => ({
              ...salesPipeline,
              cashCards: res.data.cashCards,
            }));
          },
        }
      );
    }
  };

  const saveCardChanges = (cardId) => {
    const newSalesPipeline = {
      ...salesPipeline,
      cashCards: salesPipeline.cashCards.map((card) =>
        card._id == cardId ? editingCardData : card
      ),
    };

    mutation.mutate(
      {
        data: newSalesPipeline,
        url: UPDATE_SALES_PIPELINE,
        method: httpMethod.PUT,
        headers: { salesPipelineId: salesPipeline._id },
      },
      {
        onSuccess: (res) => {
          setSalesPipeline((salesPipeline) => ({
            ...salesPipeline,
            cashCards: res.data.cashCards,
          }));
        },
      }
    );

    setEditingCardId(null);
  };

  const handleCardInputChange = (field, value) => {
    if (field === "price") {
      const formattedValue = value.replace(/\./g, "");
      const numericValue = parseInt(formattedValue, 10);
      setEditingCardData({
        ...editingCardData,
        [field]: isNaN(numericValue) ? 0 : numericValue,
      });
    } else {
      setEditingCardData({ ...editingCardData, [field]: value });
    }
  };

  const formatNumber = (num) => {
    return num.toLocaleString("tr-TR");
  };

  const totalWon = getColumnTotal("INCOME");
  const totalLost = getColumnTotal("EXPENSES");
  const netProfit = totalWon - totalLost;

  if (loading) return <PageLoadingAnimation />;

  return (
    <div className="sales-pipeline">
      <div className="scrollable-content">
        <div className="container">
          <div className="header">
            <h1>Sales Pipeline</h1>
          </div>

          <div className="net-profit">
            <h2>Net Profit</h2>

            <p
              className={`profit-amount ${
                netProfit >= 0 ? "positive" : "negative"
              }`}
            >
              {netProfit.toLocaleString()} ₺
            </p>
            <div className="profit-details">
              <p>
                Income:{" "}
                <span className="income">{totalWon.toLocaleString()} ₺</span>
              </p>
              <p>
                Expenses:{" "}
                <span className="expenses">{totalLost.toLocaleString()} ₺</span>
              </p>
            </div>
          </div>
          <div className="search-and-add">
            <div className="search-bar">
              <SearchBar onSearch={handleSearch} />
            </div>
            <div className="button-group">
              <Button className="add-button" onClick={openAddColumnModal}>
                {" "}
                {/* [+] */}
                {/* <PlusIcon />  */}
                Add Column
              </Button>
              <Button className="add-button" onClick={openAddCardModal}>
                {/* <PlusIcon />  */}
                Add Card
              </Button>
            </div>
          </div>

          {showAddColumnInput && (
            <div className="add-column-input">
              <Input
                type="text"
                maxLength={50}
                placeholder="Enter column name"
                value={newColumnTitle}
                onChange={(e) => {
                  setNewColumnTitle(e.target.value);
                  setColumnError("");
                }}
              />
              <Button className="add-button" onClick={addNewColumn}>
                Add
              </Button>
              <Button
                className="cancel-button"
                onClick={() => setShowAddColumnInput(false)}
              >
                <CancelIcon />
              </Button>
              {columnError && <p className="error-message">{columnError}</p>}
            </div>
          )}
          <DragDropContext onDragEnd={onDragEnd}>
            <div className="columns-container">
              {salesPipeline.states.map((state) => (
                <Droppable key={state} droppableId={state}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className="column"
                    >
                      <SalesColumn
                        column={state}
                        cards={filteredCards.filter(
                          (card) => card.state === state
                        )}
                        onEditTitle={(newTitle) =>
                          editColumnTitle(state, newTitle)
                        }
                        onDeleteColumn={() => openDeleteColumnModal(state)}
                        getColumnTotal={getColumnTotal}
                        onAddCard={openAddCardModal}
                      >
                        {filteredCards
                          .filter((card) => card.state === state)
                          .map((card, index) => (
                            <Draggable
                              key={card._id}
                              draggableId={card._id}
                              index={index}
                            >
                              {(provided) => (
                                <Card
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className={
                                    state === "INCOME"
                                      ? "income"
                                      : state === "EXPENSES"
                                      ? "expenses"
                                      : "default"
                                  }
                                >
                                  {editingCardId === card._id ? (
                                    <CardContent>
                                      <div className="card-edit-form">
                                        <Input
                                          type="text"
                                          value={editingCardData.title}
                                          maxLenght={100}
                                          onChange={(e) =>
                                            handleCardInputChange(
                                              "title",
                                              e.target.value
                                            )
                                          }
                                        />
                                        <Input
                                          type="text"
                                          value={editingCardData.description}
                                          maxLenght={250}
                                          onChange={(e) =>
                                            handleCardInputChange(
                                              "description",
                                              e.target.value
                                            )
                                          }
                                        />
                                        <Input
                                          type="number"
                                          value={editingCardData.price}
                                          maxLenght={6}
                                          min={0}
                                          onChange={(e) =>
                                            handleCardInputChange(
                                              "price",
                                              e.target.value
                                            )
                                          }
                                        />

                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Button
                                            className="sales-btn"
                                            style={{ color: "black" }}
                                            onClick={() =>
                                              saveCardChanges(card._id)
                                            }
                                          >
                                            <SaveIcon /> Save
                                          </Button>
                                          <Button
                                            className="sales-btn"
                                            style={{ color: "black" }}
                                            onClick={() =>
                                              saveCardChanges(false)
                                            }
                                          >
                                            <CancelIcon /> Cancel
                                          </Button>
                                        </div>
                                      </div>
                                    </CardContent>
                                  ) : (
                                    <CardContent>
                                      <div className="card-header">
                                        <div className="card-title">
                                          <h3>{card.title}</h3>
                                          <p>{card.description}</p>
                                        </div>
                                      </div>
                                      <div className="card-footer">
                                        <span>{card.timestamp}</span>
                                        <span>
                                          {" "}
                                          ₺{formatNumber(card.price)}
                                        </span>
                                      </div>

                                      <div className="card-actions">
                                        <Button
                                          onClick={() => editCard(card._id)}
                                        >
                                          <Edit2Icon /> Edit
                                        </Button>
                                        <Button
                                          onClick={() =>
                                            openDeleteTaskModal(card._id)
                                          }
                                        >
                                          🗑️ Delete
                                        </Button>

                                        {state !== "INCOME" &&
                                          state !== "EXPENSES" && (
                                            <>
                                              <Button
                                                onClick={() =>
                                                  changeCardStatus(
                                                    card._id,
                                                    "INCOME"
                                                  )
                                                }
                                              >
                                                Income
                                              </Button>
                                              <Button
                                                onClick={() =>
                                                  changeCardStatus(
                                                    card._id,
                                                    "EXPENSES"
                                                  )
                                                }
                                              >
                                                Expenses
                                              </Button>
                                            </>
                                          )}
                                      </div>
                                    </CardContent>
                                  )}
                                </Card>
                              )}
                            </Draggable>
                          ))}
                        {provided.placeholder}
                      </SalesColumn>
                    </div>
                  )}
                </Droppable>
              ))}
            </div>
          </DragDropContext>
          <ColumnScrollIndicator columns={salesPipeline.states} />
        </div>
        {modalContent && (
          <TaskEventModal
            modalId={modalContent.id}
            show={modalVisible}
            onClose={closeModal}
            content={
              <div className="kanban-board__modal-content">
                <h2 className="kanban-board__modal-title">
                  {modalContent.title}
                </h2>
                {modalContent.content}
              </div>
            }
            type="lg"
            width={500}
          />
        )}
        {showEditModal && (
          <TaskEventModal
            modalId="editCardModal"
            show={showEditModal}
            onClose={closeEditModal}
            content={
              <EditCardForm
                salesPipeline={salesPipeline}
                cardData={editingCardData}
                onSubmit={saveEditedCard}
              />
            }
            type="lg"
            width={500}
          />
        )}
        {showAddColumnModal && (
          <TaskEventModal
            modalId="addColumnModal"
            show={showAddColumnModal}
            onClose={closeAddColumnModal}
            content={
              <div className="add-column-modal">
                <h2>Add New Column</h2>
                <Input
                  type="text"
                  maxLength={50}
                  placeholder="Column name"
                  value={newColumnTitle}
                  onChange={(e) => {
                    setNewColumnTitle(e.target.value);
                    setColumnError("");
                  }}
                />
                {columnError && <p className="error-message">{columnError}</p>}
                <div className="modal-actions">
                  <Button className="add-button" onClick={addNewColumn}>
                    Add Column
                  </Button>
                </div>
              </div>
            }
            type="sm"
            width={400}
          />
        )}
      </div>
    </div>
  );
}

export default SalesPipeline;
