import instance from "./axios"

export const createProposal = (proposalDto) => instance.post('/proposal', proposalDto)

export const updateProposal = (proposalId, proposalDto) => instance.put(`/proposal/${proposalId}`, proposalDto)

export const getProposalById = (proposalId) => instance.get(`/proposal/detail/${proposalId}`)

export const joinChat = (id, pinCode) => instance.post(`/proposal/join-chat?id=${id}&pinCode=${pinCode}`)

export const acceptProposal = (id) => instance.post(`/proposal/accept-proposal?id=${id}&date=${new Date()}`)

export const rejectProposal = (id) => instance.post(`/proposal/reject-proposal?id=${id}`)

export const getAllProposals = ({ freelancerId, limit = 10, page = 1 }) => instance.get(`/proposal?freelancerId=${freelancerId}&limit=${limit}&page=${page}`)

export const markMessageAsRead = (proposalId, messageId) => instance.post(`/proposal/mark-message-as-read?proposalId=${proposalId}&messageId=${messageId}`)

export const getMessages = (proposalId) => instance.get(`/proposal/messages?proposalId=${proposalId}`)

export const sendMessage = (message) => instance.post(`/proposal/message`, message)

export const deleteProposal = (proposalId) => instance.delete(`/proposal/${proposalId}`)