import React, { useEffect, useState, useCallback, useMemo, useRef } from "react";
import io from "socket.io-client";
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import * as Queries from "../../../../utils/queries";

import OffcanvasProposal from "./offcanvasProposal";

const Messages = ({ isClient, proposalInfo }) => {
    const queryClient = useQueryClient();
    const sender = isClient ? "Client" : proposalInfo.freelancer.fullName;
    const [lastMessageCount, setLastMessageCount] = useState(0);
    const [refetchInterval, setRefetchInterval] = useState(5000); 
    const [message, setMessage] = useState("");
    const [isOpenProposal, setIsOpenProposal] = useState(false);
    const messagesEndRef = useRef(null);

    const { data: messages = [] } = useQuery(
        {
            queryKey: ['messages', proposalInfo._id],
            queryFn: () => Queries.getMessages(proposalInfo._id),
            refetchInterval,
        },
    );
    useEffect(() => {
        if (messages.length > 0) {
            if (messages.length === lastMessageCount) {
                setRefetchInterval(prev => {
                    const newInterval = Math.min(prev * 2, 60000);
                    return newInterval;
                });
            } else {
                setRefetchInterval(5000);
                setLastMessageCount(messages.length);
            }
        }
    }, [messages.length, lastMessageCount]);


    const mutate = useMutation({
        mutationFn: Queries.sendMessage,
        onMutate: async (newMessage) => {
            await queryClient.cancelQueries(['messages', proposalInfo._id]);

            const previousMessages = queryClient.getQueryData(['messages', proposalInfo._id]);

            queryClient.setQueryData(['messages', proposalInfo._id], (old) => {
                return [...old, newMessage];
            });

            return { previousMessages };
        },
        onError: (err, newMessage, context) => {
            queryClient.setQueryData(['messages', proposalInfo._id], context.previousMessages);
        },
        onSettled: () => {
            setRefetchInterval(5000);
            queryClient.invalidateQueries(['messages', proposalInfo._id]);
        }
    })



    const sendPrivateMessage = () => {
        if (message) {
            const newMessage = {
                message,
                proposalId: proposalInfo._id,
                sender
            }
            mutate.mutate(newMessage);
            setMessage("");
        }
    }

    const setIsOpenProposalHandler = () => {
        setIsOpenProposal(!isOpenProposal)
    }

    const scrollToBottom = () => {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }

    useEffect(() => {
        scrollToBottom()
    }, [messages]);

    const handleEnter = (e) => {
        if (e.key === "Enter" && e.target.value) {
            sendPrivateMessage();
        }
    }

    const handleProposal = (isApproved) => {
        const message = isApproved ? "Proposal Approved" : "Proposal Declined";
        mutate.mutate({
            message,
            proposalId: proposalInfo._id,
            sender: "System"
        });
    }

    return (
        <>
            <OffcanvasProposal proposal={proposalInfo} handleProposal={handleProposal} isOpen={isOpenProposal} toggle={setIsOpenProposalHandler} isClient={isClient} />
            <div className="py-4 relative">
                <h2 className="ps-4 pb-4 border-bottom border-2">
                    UpperBoard Proposal/{proposalInfo.freelancer.fullName.toLowerCase().replace(/\s/g, "")}
                </h2>

                <div className="container-md single-message-container" >
                    {
                        messages?.map((msg, index) => msg.isProposal ? (
                            <div key={index} className={`single-message ${msg.sender === sender ? "right" : ""}`}>
                                <div className="profile-icon">
                                    <i className="bi bi-person-fill"></i>
                                </div>
                                <div>
                                    <h4 className="sender">
                                        {msg.sender === sender ? "You" : msg.sender}
                                    </h4>
                                    <button className="message" onClick={setIsOpenProposalHandler}>
                                        {msg.message}
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <div key={index} className={`single-message ${msg.sender === sender ? "right" : ""}`}>
                                <div className="profile-icon">
                                    <i className="bi bi-person-fill"></i>
                                </div>
                                <div>
                                    <h4 className="sender">
                                        {msg.sender === sender ? "You" : msg.sender}
                                    </h4>
                                    <p className="message">
                                        {msg.message}
                                    </p>
                                </div>
                            </div>
                        ))
                    }
                    <div ref={messagesEndRef} />
                </div>

                <div className="send-message container-md">
                    <input
                        type="text"
                        placeholder="Your Message..."
                        className="message-input"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        onKeyUp={(e) => handleEnter(e)}
                    />
                    <button
                        className="send-button"
                        onClick={sendPrivateMessage}
                    >
                        Send
                    </button>
                </div>

            </div>
        </>

    );
};

export default Messages;
