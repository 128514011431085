export const themeEnumText = {
    "DEFAULT": "Default",
    "DEFAULT_DARK": "Default Dark",
    "FLOYKA": "Floyka",
    "FLOYKA_DARK": "Floyka Dark",
}

export const themeEnumImgPath = {
    "DEFAULT": "/assets/images/themes/default-theme.png",
    "DEFAULT_DARK": "/assets/images/themes/default-dark-theme.png",
    "FLOYKA": "/assets/images/themes/floyka-theme.jpg",
    "FLOYKA_DARK": "/assets/images/themes/floyka-dark-theme.jpg",
}

export const themeEnum = {
    "DEFAULT": "DEFAULT",
    "DEFAULT_DARK": "DEFAULT_DARK",
    "FLOYKA": "FLOYKA",
    "FLOYKA_DARK": "FLOYKA_DARK",
}