import React from "react";
import "../styles/ProjectSection.scss";
import ProjectCard from "./ProjectCard";
import { useGetBlogsByUserId } from "../../../../customHooks/useGetBlogsByUserId";

// const projects = [
//   {
//     image: "/assets/images/mukellef_button.png",
//     title: "Webly Agency All Branding",
//     description:
//       "Complete branding for Webly Agency, including logo and website design.",
//     link: "https://weblyagency.com/",
//   },
//   {
//     image: "/assets/images/linqi.png",
//     title: "Floyka Design - Rebranding",
//     description:
//       "Rebranding project for Floyka Design, including new logo and marketing materials.",
//     link: "https://floykadesign.com/",
//   },
//   {
//     image: "/assets/images/postuby.png",
//     title: "Izmir Blockchain",
//     description:
//       "Branding and design for IzmirBlockchain, focusing on modern and clean aesthetics.",
//     link: "https://izmirblockchain.com/",
//   },
//   {
//     image: "/assets/images/remotify_partners.png",
//     title: "Felina Project All Branding",
//     description:
//       "Complete branding for Felina Project, with emphasis on unique visual identity.",
//     link: "https://felinaproject.com/",
//   },
//   {
//     image: "/assets/images/copetract.png",
//     title: "Nois All Branding",
//     description:
//       "Branding for Nois, creating a distinctive and memorable visual experience.",
//     link: "https://noisbranding.com/",
//   },
//   {
//     image: "/assets/images/copetract.png",
//     title: "Icepole All Branding",
//     description:
//       "Full branding for Icepole, integrating modern and innovative design elements.",
//     link: "https://icepolebranding.com/",
//   },
// ];

const ProjectsSection = ({ userId }) => {
  const [blogs, loading] = useGetBlogsByUserId(userId);

  return (
    <div className="projects-section-light">
      {blogs !== undefined &&
        blogs.map((blog, index) => <ProjectCard key={index} {...blog} />)}
    </div>
  );
};

export default ProjectsSection;
