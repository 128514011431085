import axios from "axios";
import React, { useState } from "react";
import { USER_CHANGE_THEME } from "../../../../constants/links";
import { themeEnumImgPath, themeEnumText } from "../../../../constants/themeEnums";
import "./ThemetMarket.scss";

function ThemeMarket({ userProfile }) {

  const [activeTheme, setActiveTheme] = useState(userProfile.activeTheme);


  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImgSrc, setCurrentImgSrc] = useState("");


  const handleCloseModal = () => {
    setIsModalOpen(false);
    setCurrentImgSrc("");
  };

  const handleChangeTheme = (activeTheme) => {
    axios.post(USER_CHANGE_THEME, {
      activeTheme
    }, {
      headers: {
        userid: userProfile.userId
      }
    })
      .then((res) => {
        setActiveTheme(activeTheme)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <div className="theme-market-main">
      <h1 className="market-title">Market</h1>
      <div className="market-body">
        <h4 className="market-body-title">Your Themes</h4>
        <div className="your-themes">
          {userProfile.availableThemes.map((theme) => (
            <div key={theme.id} className="market-card" >
              {/* <div style={{ border: activeTheme === theme ? '2px green solid' : '' }}> */}
              <img
                src={themeEnumImgPath[theme]}
                // src={theme == "DEFAULT" ? '/assets/images/themes/default-theme.png' : '/assets/images/themes/floyka-theme.png'}
                alt={theme}
                className="market-img"
                style={{ padding: 5, border: activeTheme === theme ? '3px green solid' : '' }}
              />

              {/* </div> */}
              <h4 className="market-card-title">{themeEnumText[theme]}</h4>
              <button className="market-button" onClick={() => handleChangeTheme(theme)}>Use This Theme</button>
            </div>
          ))}
        </div>
      </div>

      {isModalOpen && (
        <div className="modal" onClick={handleCloseModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-button" onClick={handleCloseModal}>
              &times;
            </button>
            <img src={currentImgSrc} alt="Preview" className="modal-image" />
          </div>
        </div>
      )}
    </div>
  );
}

export default ThemeMarket;
