import React, { forwardRef } from "react";

const Card = forwardRef(({ className, children, ...props }, ref) => (
  <div ref={ref} className={`card ${className || ""}`} {...props}>
    {children}
  </div>
));

Card.displayName = "Card";

export default Card;
