import {
  AlignCenter,
  AlignLeft,
  AlignRight,
  Check,
  ChevronDown,
  ChevronUp,
  FileText,
  Image as ImageIcon,
  PlusCircle,
  Type,
  X
} from "lucide-react";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { getUserIdFromCookie } from "../../../../helpers/cookieHelper";
import { createBlog, getBlogById, updateBlog } from "./requests/requests";

// Recreated UI components
const Button = ({
  children,
  onClick,
  variant = "default",
  size = "default",
  className = "",
}) => {
  const baseClass =
    "tw-inline-flex tw-items-center tw-justify-center tw-rounded-md tw-font-medium tw-transition-colors focus-visible:tw-outline-none focus-visible:tw-ring-1 focus-visible:tw-ring-ring disabled:tw-pointer-events-none disabled:tw-opacity-50";
  const variantClass =
    variant === "outline"
      ? "tw-border tw-border-input tw-bg-background hover:tw-bg-accent hover:tw-text-accent-foreground"
      : "tw-bg-primary tw-text-primary-foreground hover:tw-bg-primary/90";
  const sizeClass =
    size === "sm" ? "tw-h-8 tw-px-3 tw-text-xs" : "tw-h-9 tw-px-4 tw-py-2";
  return (
    <button
      className={`${baseClass} ${variantClass} ${sizeClass} ${className}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

const Input = ({
  textAlign = "left",
  value,
  onChange,
  placeholder,
  className = "",
}) => (
  <input
    value={value}
    onChange={onChange}
    placeholder={placeholder}
    style={{ textAlign: textAlign }}
    className={`tw-flex tw-h-9 tw-w-full tw-rounded-md tw-border tw-border-input tw-bg-transparent tw-px-3 tw-py-1 tw-text-sm tw-shadow-sm transition-colors file:tw-border-0 file:tw-bg-transparent file:tw-text-sm file:tw-font-medium placeholder:tw-text-muted-foreground focus-visible:tw-outline-none focus-visible:tw-ring-1 focus-visible:tw-ring-ring disabled:tw-cursor-not-allowed disabled:tw-opacity-50 ${className}`}
  />
);

const Textarea = ({
  textAlign = "left",
  value,
  onChange,
  placeholder,
  className = "",
}) => (
  <textarea
    style={{ textAlign: textAlign }}
    value={value}
    onChange={onChange}
    placeholder={placeholder}
    className={`tw-flex tw-min-h-[60px] tw-w-full tw-rounded-md tw-border tw-border-input tw-bg-transparent tw-px-3 tw-py-2 tw-text-sm tw-shadow-sm placeholder:tw-text-muted-foreground focus-visible:tw-outline-none focus-visible:tw-ring-1 focus-visible:tw-ring-ring disabled:tw-cursor-not-allowed disabled:tw-opacity-50 ${className}`}
  />
);

const Card = ({ children, className = "" }) => (
  <div
    className={`tw-rounded-lg tw-border tw-bg-card tw-text-card-foreground tw-mb-10 tw-shadow-sm ${className}`}
  >
    {children}
  </div>
);

const CardHeader = ({ children }) => (
  <div className="tw-flex tw-flex-col tw-space-y-1.5 tw-p-6">{children}</div>
);

const CardTitle = ({ children }) => (
  <h3 className="tw-font-semibold tw-leading-none tw-tracking-tight">
    {children}
  </h3>
);

const CardContent = ({ children }) => (
  <div className="tw-p-6 tw-pt-0">{children}</div>
);

export default function IntegratedBlogCreator({ userProfile }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [blogTitle, setBlogTitle] = useState("");
  const [blogDesc, setBlogDesc] = useState("");
  const [blogCardImg, setBlogCardImg] = useState("");
  const [contentInputs, setContentInputs] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [directLink, setDirectLink] = useState("");
  const [currentEdit, setCurrentEdit] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const fileInputRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (location.state?.itemId) {
      getBlogById(location.state.itemId)
        .then((res) => {
          setContentInputs(res.contents);
          setBlogTitle(res.title);
          setBlogDesc(res.description);
          setBlogCardImg(res.cardImg);
          setDirectLink(res.directLink);
          setIsUpdate(true);
        })
        .catch((err) => {
          console.error("Error fetching blog data:", err);
          toast.error("Failed to load blog data. Please try again.");
        });
    }
  }, [location.state]);

  const handleCoverImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setBlogCardImg(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleInputChange = (event, setValue) => {
    const inputValue = event.target.value;
    const words = inputValue
      .split(" ")
      .map((word) => (word.length > 40 ? word.slice(0, 40) : word));
    setValue(words.join(" "));
  };
  const handleLimitedInputChange = (value, setValue) => {
    const words = value
      .split(" ")
      .map((word) => (word.length > 40 ? word.slice(0, 40) : word));
    setValue(words.join(" "));
  };


  const addItem = (itemName) => {
    if (contentInputs.length < 10) {
      if (itemName === "img") {
        fileInputRef.current.click();
      } else {
        let newItem;
        switch (itemName) {
          case "title":
            newItem = {
              devTag: "input",
              tag: "h2",
              type: "text",
              placeholder: "Headline",
              className: "tw-text-2xl tw-font-bold",
              align: "left",
              content: "",
            };
            break;
          case "text":
            newItem = {
              devTag: "textarea",
              tag: "p",
              type: "text",
              placeholder: "Tell your story...",
              className: "tw-text-base",
              align: "left",
              content: "",
            };
            break;
          default:
            return;
        }
        setContentInputs([...contentInputs, newItem]);
        setCurrentEdit(contentInputs.length);
      }
    } else {
      toast.error("You can add up to 10 items.", {
        theme: "colored",
        position: "bottom-center",
      });
    }
  };

  const handleOnChange = (index, value) => {
    handleLimitedInputChange(value, (newValue) => {
      const newContentInputs = [...contentInputs];
      newContentInputs[index].content = newValue;
      setContentInputs(newContentInputs);
    });
  };

  const setAlign = (index, align) => {
    console.log(`Setting align for item ${index} to`, align);
    const newContentInputs = [...contentInputs];
    newContentInputs[index] = {
      ...newContentInputs[index],
      align: align,
    };
    setContentInputs(newContentInputs);
  };

  const handleChangeImgScale = (index) => {
    const newContentInputs = [...contentInputs];
    const currentScale = newContentInputs[index].scale;
    newContentInputs[index].scale = currentScale === 3 ? 1 : currentScale + 1;
    setContentInputs(newContentInputs);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const filteredContentInputs = contentInputs.filter(
      (item) => item.content !== ""
    );
    if (directLink && filteredContentInputs.length > 0) {
      toast.error(
        "Since you’ve added a direct link, the content you've uploaded will not be displayed.",
        {
          theme: "colored",
          position: "bottom-center",
        }
      );
      setIsSubmitting(false);
      return;
    }

    const blogData = {
      title: blogTitle,
      directLink,
      contents: filteredContentInputs,
      description: blogDesc,
      cardImg: blogCardImg || "/assets/images/works/work_default.png",
      linkNick: userProfile.linkNick,
      userId: userProfile.userId,
    };

    const headers = {
      Authorization: getUserIdFromCookie(),
      BlogId: location.state?.itemId,
    };

    console.log("Submitting blog data:", blogData);
    console.log("Headers:", headers);

    try {
      const action = isUpdate ? updateBlog : createBlog;
      const res = await action(blogData, { headers });
      console.log("Server response:", res);

      toast.success("Blog post saved successfully!", {
        theme: "colored",
        position: "bottom-center",
      });
      navigate(-1);
    } catch (err) {
      console.error("Error saving blog post:", err);

      if (err.response) {
        console.error("Error response:", err.response);
      }
      let errorMessage = "Failed to save blog post. Please try again.";
      if (err.response && err.response.data && err.response.data.message) {
        errorMessage = err.response.data.message;
      }
      toast.error(errorMessage, {
        theme: "colored",
        position: "bottom-center",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const newItem = {
          devTag: "input",
          tag: "img",
          type: "file",
          placeholder: "Choose an image",
          className: "tw-max-w-full tw-h-auto",
          align: "left",
          content: e.target.result,
          scale: 1,
        };
        setContentInputs([...contentInputs, newItem]);
        setCurrentEdit(contentInputs.length);
      };
      reader.readAsDataURL(file);
    }

    e.target.value = null;
  };

  const handleDeleteItem = (index) => {
    const newContentInputs = contentInputs.filter((_, i) => i !== index);
    setContentInputs(newContentInputs);
    setCurrentEdit(null);
  };

  const handleReLocateItems = (index, type) => {
    if (
      (type === "up" && index === 0) ||
      (type === "down" && index === contentInputs.length - 1)
    ) {
      toast.error(`You can't move ${type} this item.`, {
        theme: "colored",
        position: "bottom-center",
      });
      return;
    }

    const newContentInputs = [...contentInputs];
    const targetIndex = type === "up" ? index - 1 : index + 1;
    const temp = newContentInputs[index];
    newContentInputs[index] = newContentInputs[targetIndex];
    newContentInputs[targetIndex] = temp;

    setContentInputs(newContentInputs);
    setCurrentEdit(targetIndex);
  };

  return (
    <div className="tw-w-full tw-mx-auto tw-p-3 tw-space-y-8 ">
      <ToastContainer />
      <Card>
        <CardHeader>
          <CardTitle className="tw-text-2xl tw-font-bold ">
            Create a New Project / Blog Content
          </CardTitle>
        </CardHeader>
        <CardContent className="tw-space-y-6">
          <div>
            <label
              htmlFor="title"
              className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1"
            >
              Project Title
            </label>
            <Input
              value={blogTitle}
              onChange={(e) => handleInputChange(e, setBlogTitle)}
              placeholder="Give your project a title"
              className="tw-w-full  tw-mb-7"
            />
          </div>

          <div>
            <label
              htmlFor="description"
              className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1"
            >
              Project Description (Optional)
            </label>
            <Textarea
              value={blogDesc}
              // onChange={(e) => setBlogDesc(e.target.value)}
              onChange={(e) => handleInputChange(e, setBlogDesc)}
              placeholder="Describe your project"
              className="tw-w-full tw-mb-7"
            />
          </div>

          <div>
            <label className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
              Project Cover
            </label>
            <div className="tw-mt-1 tw-flex tw-justify-center tw-px-6 tw-pt-5 tw-pb-6 tw-border-2 tw-border-gray-300 tw-border-dashed tw-rounded-md tw-mb-7">
              <div className="tw-space-y-1 tw-text-center">
                <label
                  htmlFor="cover-upload"
                  className="tw-cursor-pointer tw-flex tw-flex-col tw-items-center tw-space-y-2"
                >
                  {blogCardImg ? (
                    <img
                      src={blogCardImg}
                      alt="Cover"
                      className="tw-h-32 tw-w-32 tw-object-cover tw-rounded-md"
                    />
                  ) : (
                    <PlusCircle className="tw-h-12 tw-w-12 tw-text-gray-400" />
                  )}
                  <span className="tw-text-sm tw-font-medium tw-text-blue-600 hover:tw-text-primary/90">
                    Upload a file
                  </span>
                  <input
                    id="cover-upload"
                    name="cover-upload"
                    type="file"
                    className="tw-sr-only"
                    onChange={handleCoverImageUpload}
                    accept="image/*"
                  />
                </label>
                <p className="tw-text-xs tw-text-gray-500">
                  PNG, JPG, GIF up to 10MB
                </p>
              </div>
            </div>
          </div>

          <div>
            <label
              htmlFor="direct-link"
              className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1"
            >
              Direct Link (Optional)
            </label>
            <Input
              value={directLink}
              // onChange={(e) => setDirectLink(e.target.value)}
              onChange={(e) => handleInputChange(e, setDirectLink)}
              placeholder="https://"
              className="tw-w-full"
            />
            <p className="tw-mt-2 tw-text-sm tw-text-gray-500">
              If you add a link in this field, no text or visual content can be
              added to the project. The content will be directly redirected to
              the provided link.
            </p>
          </div>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle className="tw-text-xl tw-font-bold">Content</CardTitle>
        </CardHeader>
        <CardContent className="tw-space-y-6">
          {contentInputs.map((item, index) => {
            console.log(`Item ${index} align:`, item.align);
            return (
              <Card key={index} className="tw-overflow-hidden tw-w-full">
                <CardContent className="tw-p-4">
                  {currentEdit === index ? (
                    <div className="tw-space-y-4">
                      {item.tag === "img" ? (
                        <div
                          className={`tw-space-y-2 tw-w-full  `}
                          style={{ textAlign: item.align }}
                        >
                          {/* <Input
                          type="file"
                          accept="image/*"
                          onChange={(e) => handleFileChange(e)}
                          className="tw-w-full"
                        /> */}
                          {item.content && (
                            <img
                              src={item.content}
                              alt="Uploaded"
                              className={`tw-max-h-64 tw-object-cover tw-rounded tw-mx-auto tw-transform ${item.scale === 1
                                  ? "tw-scale-90"
                                  : item.scale === 2
                                    ? "tw-scale-100"
                                    : "tw-scale-110"
                                } tw-my-4`}
                              style={{
                                maxWidth:
                                  item.scale === 1
                                    ? "70%"
                                    : item.scale === 2
                                      ? "90%"
                                      : "100%",
                              }}
                            />
                          )}
                        </div>
                      ) : item.devTag === "input" ? (
                        <Input
                          value={item.content}
                          onChange={(e) =>
                            handleOnChange(index, e.target.value)
                          }
                          placeholder={item.placeholder}
                          className={`${item.className} tw-w-full`}
                          textAlign={item.align}
                        />
                      ) : (
                        <Textarea
                          value={item.content}
                          onChange={(e) =>
                            handleOnChange(index, e.target.value)
                          }
                          placeholder={item.placeholder}
                          className={`${item.className} tw-min-h-[100px] tw-w-full`}
                          textAlign={item.align}
                        />
                      )}
                      <div className="tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-gap-2 ">
                        <div className="tw-flex tw-flex-wrap tw-items-center tw-gap-2 ">
                          <Button
                            className="tw-bg-white "
                            variant="outline"
                            size="sm"
                            onClick={() => setAlign(index, "left")}
                          >
                            <AlignLeft className="tw-h-4 tw-w-4" />
                          </Button>
                          <Button
                            className="tw-bg-white"
                            variant="outline"
                            size="sm"
                            onClick={() => setAlign(index, "center")}
                          >
                            <AlignCenter className="tw-h-4 tw-w-4" />
                          </Button>
                          <Button
                            className="tw-bg-white "
                            variant="outline"
                            size="sm"
                            onClick={() => setAlign(index, "right")}
                          >
                            <AlignRight className="tw-h-4 tw-w-4" />
                          </Button>
                          {item.tag === "img" && (
                            <Button
                              className="tw-bg-white "
                              variant="outline"
                              size="sm"
                              onClick={() => handleChangeImgScale(index)}
                            >
                              Scale: {item.scale}
                            </Button>
                          )}
                        </div>
                        <div className="tw-flex tw-space-x-2">
                          <Button
                            className="tw-bg-white "
                            variant="outline"
                            size="sm"
                            onClick={() => handleReLocateItems(index, "up")}
                            disabled={index === 0}
                          >
                            <ChevronUp className="tw-h-4 tw-w-4" />
                          </Button>
                          <Button
                            className="tw-bg-white"
                            variant="outline"
                            size="sm"
                            onClick={() => handleReLocateItems(index, "down")}
                            disabled={index === contentInputs.length - 1}
                          >
                            <ChevronDown className="tw-h-4 tw-w-4" />
                          </Button>
                          <Button
                            className="tw-bg-white"
                            variant="outline"
                            size="sm"
                            onClick={() => handleDeleteItem(index)}
                          >
                            <X className="tw-mr-2 tw-h-4 tw-w-4" /> Delete
                          </Button>
                          <Button
                            className="tw-bg-white"
                            variant="outline"
                            size="sm"
                            onClick={() => setCurrentEdit(null)}
                          >
                            <Check className="tw-mr-2 tw-h-4 tw-w-4" /> Save
                          </Button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="tw-cursor-pointer"
                      onClick={() => setCurrentEdit(index)}
                      style={{
                        display: "flex",
                        justifyContent:
                          item.align === "right"
                            ? "flex-end"
                            : item.align === "center"
                              ? "center"
                              : "flex-start",
                        textAlign: item.align,
                      }}
                    >
                      {item.tag === "img" ? (
                        <img
                          src={item.content}
                          alt="Content"
                          className="tw-max-h-64 tw-object-cover tw-rounded"
                          style={{
                            maxWidth:
                              item.scale === 1
                                ? "70%"
                                : item.scale === 2
                                  ? "90%"
                                  : "100%",
                          }}
                        />
                      ) : (
                        <div
                          className={`${item.className}`}
                          style={{
                            textAlign: item.align, // textAlign uygulaması
                          }}
                        >
                          {item.content || `Click to edit ${item.tag}`}
                        </div>
                      )}
                    </div>
                  )}
                </CardContent>
              </Card>
            );
          })}

          <div className="tw-flex tw-flex-wrap tw-gap-2">
            <Button
              className="tw-bg-white"
              variant="outline"
              onClick={() => addItem("title")}
            >
              <Type className="tw-mr-2 tw-h-4 tw-w-4" /> Add Title
            </Button>
            <Button
              className="tw-bg-white"
              variant="outline"
              onClick={() => addItem("text")}
            >
              <FileText className="tw-mr-2 tw-h-4 tw-w-4" /> Add Paragraph
            </Button>
            <Button
              className="tw-bg-white"
              variant="outline"
              onClick={() => addItem("img")}
            >
              <ImageIcon className="tw-mr-2 tw-h-4 tw-w-4" /> Add Image
            </Button>
            <input
              type="file"
              variant="outline"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
              accept="image/*"
            />
          </div>
        </CardContent>
      </Card>

      <div className="tw-flex tw-justify-end tw-space-x-2 tw-mt-8">
        <Button
          className="tw-bg-white"
          variant="outline"
          onClick={() => navigate(-1)}
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button
          className="tw-bg-white tw-border-gray-300"
          onClick={handleSubmit}
          disabled={isSubmitting}
        >
          {isSubmitting ? "Saving..." : isUpdate ? "Update" : "Create"}
        </Button>
      </div>
    </div>
  );
}
