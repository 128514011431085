import React from "react";
import { sendNotification } from "../../../../../helpers/sendNotification";
import { styles } from "./styles/contactStyles";

function ContactDark({
  userId,
  notificationInfo,
  setNotificationInfo,
  handleNotificationInfo,
  socialMedias,
  email,
}) {
  return (
    <section className="contact-crev" style={styles.mainSection}>
      <div>
        <div>
          <div class="row">
            <div class="col-lg-6">
              <div>
                <h2>
                  <span style={styles.contactSpan}>Contact</span>
                </h2>
                <ul style={styles.gmailUl}>
                  <li>
                    <a>
                      <span>Gmail</span>
                    </a>
                    <p>{email}</p>
                  </li>
                </ul>
                <div style={styles.linksDiv}>
                  {socialMedias != undefined &&
                    socialMedias.map((item, index) => {
                      if (item.value !== "" && item.value !== undefined) {
                        return (
                          <a href={item.value}>
                            <i
                              style={{ ...styles.icon, color: "white" }}
                              class={item.icon}
                            ></i>
                          </a>
                        );
                      }
                    })}
                </div>
              </div>
            </div>
            <div class="col-lg-6 ">
              <div>
                <div class="row">
                  <div class="col-lg-12">
                    <div style={styles.emailInput}>
                      <input
                        onChange={(e) =>
                          handleNotificationInfo(e.target.value, "email")
                        }
                        value={notificationInfo.email}
                        maxLength={50}
                        type="email"
                        name="from_name"
                        placeholder="Your Email"
                      />
                    </div>
                  </div>

                  <div class="col-lg-12">
                    <div style={styles.emailInput}>
                      <input
                        onChange={(e) =>
                          handleNotificationInfo(e.target.value, "fullName")
                        }
                        value={notificationInfo.fullName}
                        maxLength={50}
                        type="email"
                        name="from_name"
                        placeholder="Full Name"
                      />
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div style={styles.emailInput}>
                      <input
                        onChange={(e) =>
                          handleNotificationInfo(e.target.value, "projectTitle")
                        }
                        value={notificationInfo.projectTitle}
                        maxLength={50}
                        type="text"
                        name="project_title"
                        placeholder="Project Title"
                      />
                    </div>
                  </div>

                  <div class="col-12">
                    <div>
                      <textarea
                        onChange={(e) =>
                          handleNotificationInfo(
                            e.target.value,
                            "projectDescription"
                          )
                        }
                        value={notificationInfo.projectDescription}
                        maxLength={3000}
                        name="message"
                        placeholder="Job Description"
                        rows="4"
                      />
                    </div>
                    <div class="mt-30">
                      <button
                        onClick={() => {
                          sendNotification(userId, notificationInfo);
                          setNotificationInfo({
                            email: "",
                            fullName: "",
                            projectDescription: "",
                            projectTitle: "",
                          });
                        }}
                        class="butn butn-full butn-bord"
                        style={{ borderRadius: 30 }}
                      >
                        <span>Send Job Request</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactDark;
