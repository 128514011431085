import React, { useState, useCallback, useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';
import * as Queries from '../../../../utils/queries';
import OtpInput from './otp';
import { toast, ToastContainer } from 'react-toastify';

const NUMBER_OF_DIGITS = 4;

const PinCode = ({
  proposalId,
  setIsPinCorrect,
  defaultOtp 
}) => {
  const [otp, setOtp] = useState(defaultOtp ? defaultOtp.split("") : new Array(NUMBER_OF_DIGITS).fill(""));

  const { mutate } = useMutation({
    mutationKey: ['verifyOtp'],
    mutationFn: (otp) => Queries.joinChat(proposalId, otp),
    onSuccess: (data) => {
      if (data.success) {
        setIsPinCorrect(data);
        return;
      }
      toast.error(data.message, { theme: "colored", position: "bottom-center" });
    },
    onError: (error) => {
      toast.error("An error occurred while verifying the OTP.", { theme: "colored", position: "bottom-center" });
    }
  });

  const onOtp = useCallback((otp) => {
    setOtp(otp);
  }, []);

  const verifyOTP = useCallback(() => {
    const otpString = otp.join('');
    mutate(otpString);
  }, [otp, mutate]);

  useEffect(() => {
    if (defaultOtp) {
      verifyOTP();
    }
  }, [defaultOtp, verifyOTP]);

  return (
    <>
      <div className='container-md min-vh-100'>
        <div className='row justify-content-center align-items-center min-vh-100'>
          <div className='card card-body' style={{ maxWidth: '35rem' }}>
            <h2 className="card-title text-center mb-4">
              Enter Pin Code
            </h2>
            <p className="text-center">
              We have sent an pin code to your email address. Please enter the code below.
            </p>
            <div className="mb-3">
              <OtpInput
                numberOfDigits={NUMBER_OF_DIGITS}
                correctOTP="123456"
                setOtp={onOtp}
                otp={otp}
              />
            </div>
            <div className="d-grid">
              <button className="btn btn-primary btn-lg" onClick={verifyOTP} aria-label="Verify OTP">
                Verify Code
              </button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default PinCode;