import React from "react";
import { Container } from "reactstrap";

function DeleteModal({ onSubmit, close }) {
  return (
    <div className="py-5" style={{ textAlign: "center" }}>
      <Container
        className="d-flex"
        style={{ flexDirection: "column", justifyContent: "center" }}
      >
        <h4>Are you sure to delete?</h4>

        <div style={{ marginTop: 30 }}>
          <button
            className="common-purple-button px-5 py-2"
            style={{
              marginRight: 20,
              border: "1px solid #6c757d",
              backgroundColor: "#f8f9fa",
              color: "#6c757d",
              borderRadius: "5px",
              transition: "all 0.3s ease",
              cursor: "pointer",
            }}
            onClick={close}
            onMouseOver={(e) => (e.target.style.backgroundColor = "#e2e6ea")}
            onMouseOut={(e) => (e.target.style.backgroundColor = "#f8f9fa")}
          >
            No
          </button>
          <button
            className="common-purple-button px-5 py-2"
            data-dismiss="modal"
            onClick={onSubmit}
            style={{
              border: "1px solid #007bff",
              backgroundColor: "#007bff",
              color: "white",
              borderRadius: "5px",
              transition: "all 0.3s ease",
              cursor: "pointer",
            }}
            onMouseOver={(e) => (e.target.style.backgroundColor = "#0056b3")}
            onMouseOut={(e) => (e.target.style.backgroundColor = "#007bff")}
          >
            Yes
          </button>
        </div>
      </Container>
    </div>
  );
}

export default DeleteModal;
