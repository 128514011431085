import axios from "axios";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import UpperModal from "../../../components/UpperModal";
import { httpMethod } from "../../../constants/httpMethods";
import {
  CREATE_PROJECT,
  GET_NOTIFICATIONS,
  NOTIFICATION_SET_ACCEPTED,
  NOTIFICATION_SET_READ,
} from "../../../constants/links";
import useFetch from "../../../customHooks/useFetch";
import useMyMutation from "../../../customHooks/useMyMutations";
import {
  getAuthRoleFromCookie,
  getUserIdFromCookie,
} from "../../../helpers/cookieHelper";
import { getJobRequestEmailBody } from "../../../helpers/getEmailBody";
import { sendEmail } from "../../../helpers/sendEmail";
import ContactMessageDetail from "./components/ContactMessageDetail";
import DismissModal from "./components/DismissModal";
import NotificationDetail from "./components/NotificationDetail";

function Notifications({ userProfile }) {
  const [notifications, setNotifications] = useState(null);
  const [contactMessages, setContactMessages] = useState([]);
  const [activeNotification, setActiveNotification] = useState(null);
  const [mailMessage, setMailMessage] = useState("");
  const [activeMessage, setActiveMessage] = useState(null);
  const [activeTab, setActiveTab] = useState("notifications");
  const [searchTerm, setSearchTerm] = useState(""); // Arama terimi

  const location = useLocation();

  const { mutation } = useMyMutation();

  const { loading, error, value } = useFetch(
    GET_NOTIFICATIONS + getUserIdFromCookie(),
    { method: httpMethod.GET },
    []
  );

  const getHeaderTitle = () => {
    if (activeTab === "notifications") {
      return "Notifications"; // Notification başlığı
    } else if (activeTab === "contactMessages") {
      return "System Message"; // System Message başlığı
    }
    return "Notifications"; // Varsayılan başlık
  };

  useEffect(() => {
    // Örnek Bildirimler

    if (!loading) {
      setNotifications((notifications) => value);
    }

    if (location.state !== undefined && location.state !== null) {
      const { notification } = location.state;
      setActiveNotification(notification);

      mutation.mutate({
        data: {
          userId: getUserIdFromCookie(),
          notificationId: notification._id,
        },
        url: NOTIFICATION_SET_READ,
        method: httpMethod.POST,
      });
    }

    const testContactMessages = [
      {
        _id: "1",
        senderName: "Support",
        senderEmail: "info@uppersocial.com",
        subject: "Welcome to UpperBoard!",
        messageContent:
          "We're excited to have you with us! 🎉 UpperBoard is designed to help you showcase your skills, connect with clients, and manage your projects effortlessly — all in one place.<br/><br/>Here’s a quick guide to get you started:<br/><br/><strong>Build Your Profile:</strong> First things first, complete your profile by adding your skills, experience, and works. The more detailed your profile, the easier it will be for clients to find and trust you.<br/><br/><strong>Showcase Your Work:</strong> Create a portfolio to display your best projects. This is your chance to shine and show clients what you’re capable of. Clients can contact you directly through your portfolio’s contact form to start a conversation.<br/><br/><strong>Connect with Clients:</strong> Use our platform to communicate directly with potential clients, discuss projects, and start building valuable relationships. All inquiries can be easily managed through your portfolio’s contact form.<br/><br/><strong>Manage Your Projects:</strong> Whether you’re creating new projects or managing ongoing ones, UpperBoard makes it simple to stay organized and on top of your work.<br/><br/><strong>Send Proposals:</strong> If you spot a potential opportunity, send a proposal through UpperBoard, including your rates and terms. Once accepted, you can convert that proposal into a project and manage everything right here on UpperBoard.<br/><br/>We’re here to support you every step of the way. If you have any questions or need assistance, feel free to reach out to us.<br/><br/>Welcome aboard, and here’s to your success! 🚀<br/><br/>Best,<br/>The Upper Social Team",
        timestamp: "2024-08-22T10:00:00Z",
        read: false,
      },
    ];

    setContactMessages(testContactMessages);
  }, [loading]);

  const setActiveNotificationFilter = (index) => {
    const selectedNotification = notifications[index];
    setActiveNotification(selectedNotification);

    // Okundu olarak işaretle
    const updatedNotifications = notifications.map((notification, i) =>
      i === index ? { ...notification, read: "true" } : notification
    );
    setNotifications(updatedNotifications);

    mutation.mutate({
      data: {
        userId: getUserIdFromCookie(),
        notificationId: notifications[index]._id,
      },
      url: NOTIFICATION_SET_READ,
      method: httpMethod.POST,
    });

    // 475px ve altı ekranlar için bildirim listesini gizleyip detayları gösterme
    if (window.innerWidth <= 475) {
      document
        .querySelector(".notification-section")
        .classList.add("show-detail");
      document
        .querySelector(".notification-detail-section")
        .classList.add("show-detail");
    }
  };

  // Geri butonu ekleyerek tekrar bildirimler listesine dönebilirsiniz
  const handleBackToNotifications = () => {
    document
      .querySelector(".notification-section")
      .classList.remove("show-detail");
    document
      .querySelector(".notification-detail-section")
      .classList.remove("show-detail");
  };

  const setActiveMessageFilter = (index) => {
    const selectedMessage = contactMessages[index];
    setActiveMessage(selectedMessage);

    // Hangi mesajın seçildiğini ve okunduğunu izleyin
    const updatedMessages = contactMessages.map((message, i) =>
      i === index ? { ...message, read: true } : message
    );
    setContactMessages(updatedMessages);

    // 475px ve altı ekranlar için mesaj listesini gizleyip mesaj detaylarını gösterme
    if (window.innerWidth <= 475) {
      document
        .querySelector(".notification-section")
        .classList.add("show-detail");
      document
        .querySelector(".notification-detail-section")
        .classList.add("show-detail");
    }
  };

  const setNotificationAccepted = (acceptedValue) => {
    axios
      .post(NOTIFICATION_SET_ACCEPTED, {
        notificationId: activeNotification._id,
        userId: jwtDecode(Cookies.get("jwt_user")).userId,
        accepted: acceptedValue,
      })
      .then(async (res) => {
        if (acceptedValue) {
          axios
            .post(CREATE_PROJECT, {
              ...activeNotification,
              authRole: getAuthRoleFromCookie(),
              projectOwnerId: getUserIdFromCookie(),
              userProfile: {
                userId: userProfile.userId,
                fullName: userProfile.fullName,
                profileImg: userProfile.profileImg,
                role: userProfile.role,
                linkNick: userProfile.linkNick,
              },
            })
            .then((res) => {
              console.log(res.data);
              toast.success(res.data, {
                theme: "colored",
                position: "bottom-right",
              });

              sendEmail(
                activeNotification.email,
                "About Job Request",
                getJobRequestEmailBody(
                  activeNotification.fullName,
                  acceptedValue,
                  mailMessage,
                  res.data.inviteId
                )
              );

              let index = notifications.indexOf(activeNotification);
              let tmpActiveNotification = { ...activeNotification };
              tmpActiveNotification.accepted = "true";
              setActiveNotification(tmpActiveNotification);

              let tempNotifications = [...notifications];
              tempNotifications[index].accepted = "true";
              setNotifications(tempNotifications);
              setMailMessage("");
            })
            .catch((err) => {
              console.log(err);
              toast.error(err.data.response.message, {
                theme: "colored",
                position: "bottom-right",
              });
            });
        } else {
          sendEmail(
            activeNotification.email,
            "About Job Request",
            getJobRequestEmailBody(
              activeNotification.fullName,
              acceptedValue,
              mailMessage
            )
          );

          let index = notifications.indexOf(activeNotification);
          let tmpActiveNotification = { ...activeNotification };
          tmpActiveNotification.accepted = "false";
          setActiveNotification(tmpActiveNotification);

          let tempNotifications = [...notifications];
          tempNotifications[index].accepted = "false";
          setNotifications(tempNotifications);
          setMailMessage("");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleTabChange = (tabName) => {
    setActiveTab(tabName);

    // Sekme değiştirildiğinde detay sayfasını gizleyip listeyi gösterin
    document
      .querySelector(".notification-section")
      .classList.remove("show-detail");
    document
      .querySelector(".notification-detail-section")
      .classList.remove("show-detail");
  };

  const filteredNotifications = notifications?.filter(
    (notification) =>
      (notification.projectTitle &&
        notification.projectTitle
          .toLowerCase()
          .includes(searchTerm.toLowerCase())) ||
      notification.email.toLowerCase().includes(searchTerm.toLowerCase())
  );


  const filteredContactMessages = contactMessages.filter(
    (message) =>
      message.subject.toLowerCase().includes(searchTerm.toLowerCase()) ||
      message.senderEmail.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="notifications-main">
      <h1 className="header-title">{getHeaderTitle()}</h1>
      <ToastContainer />
      <UpperModal
        modalId="notification-dismiss-modal"
        contentHTML={<DismissModal />}
      />

      <div className="notification-tabs">
        <button
          className={`notification-tab ${activeTab === "notifications" ? "active" : ""
            }`}
          onClick={() => handleTabChange("notifications")}
        >
          Notifications ({notifications?.length || 0}){" "}
        </button>
        <button
          className={`notification-tab ${activeTab === "contactMessages" ? "active" : ""
            }`}
          onClick={() => handleTabChange("contactMessages")}
        >
          System Message ({contactMessages?.length || 0}){" "}
        </button>
      </div>

      {/* Arama Çubuğu */}
      <input
        type="text"
        className="notification-search-bar"
        placeholder="Search..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      <div className="content-wrapper">
        <div className="notification-section">
          {activeTab === "notifications" &&
            (filteredNotifications && filteredNotifications.length !== 0 ? (
              filteredNotifications.map((notification, index) => (
                <div
                  className={`notification-item ${activeNotification === notification ? "active" : ""
                    } ${notification.read == "true" ? "read" : "unread"}`}
                  onClick={() => setActiveNotificationFilter(index)}
                  key={notification._id}
                >
                  <div>
                    <p>
                      {notification.projectTitle && notification.projectTitle.length > 38
                        ? notification.projectTitle.substring(0, 38) + "..."
                        : notification.projectTitle}
                    </p>
                    <p style={{ fontWeight: "bold" }}>
                      {notification.fullName}
                    </p>
                    <p>{notification.email}</p>
                  </div>
                </div>
              ))
            ) : (
              <div className="notification-item">
                <h4>No Notification</h4>
              </div>
            ))}

          {activeTab === "contactMessages" &&
            (filteredContactMessages.length !== 0 ? (
              filteredContactMessages.map((message, index) => (
                <div
                  className={`notification-item ${activeMessage === message ? "active" : ""
                    } ${message.read ? "read" : "unread"}`}
                  onClick={() => setActiveMessageFilter(index)}
                  key={message._id}
                >
                  <div className="status-dot"></div>
                  <div>
                    <p>{message.subject}</p>
                    <p style={{ fontWeight: "bold" }}>{message.senderName}</p>
                    <p>{message.senderEmail}</p>
                  </div>
                </div>
              ))
            ) : (
              <div className="notification-item">
                <h4>No Contact Messages</h4>
              </div>
            ))}
        </div>

        <div className="notification-detail-section">
          {window.innerWidth <= 475 && (
            <button
              onClick={handleBackToNotifications}
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                backgroundColor: "transparent",
                border: "none",
                fontSize: "30px",
                cursor: "pointer",
                alignItems: "start",
                marginBottom: 25,
                padding: 0,
                width: "5%",
              }}
            >
              <FaArrowAltCircleLeft />
            </button>
          )}
          {activeNotification && activeTab === "notifications" && (
            <NotificationDetail
              activeNotification={activeNotification}
              setNotificationAccepted={setNotificationAccepted}
              mailMessage={mailMessage}
              setMailMessage={setMailMessage}
            />
          )}

          {activeMessage && activeTab === "contactMessages" && (
            <ContactMessageDetail activeMessage={activeMessage} />
          )}
        </div>
      </div>
    </div>
  );
}

export default Notifications;
