import React, { useEffect, useRef, useState } from 'react';

const styles = {
    boardSelector: {
        position: 'relative',
        fontFamily: 'Arial, sans-serif',
        display: 'inline-block',
        marginLeft: '5px',
        marginRight: '7px'
    },
    selectedBoard: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px 15px',
        backgroundColor: '#ffffff',
        border: '1px solid #e0e0e0',
        borderRadius: '4px',
        cursor: 'pointer',
        userSelect: 'none',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    arrow: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '10px',
        flexShrink: 0,
    },
    dropdown: {
        position: 'absolute',
        top: '100%',
        left: '0',
        backgroundColor: 'white',
        border: '1px solid #e0e0e0',
        borderTop: 'none',
        borderRadius: '0 0 4px 4px',
        boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
        zIndex: 1000,
        overflowX: 'hidden',
    },
    dropdownItem: {
        padding: '10px 15px',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    dropdownItemHover: {
        backgroundColor: '#f5f5f5',
    },
    checkmark: {
        color: '#4CAF50',
        fontWeight: 'bold',
        marginLeft: '10px',
        flexShrink: 0,
    },
    userInfo: {
        display: "flex",
        alignItems: "center",
    },
    userImage: {
        width: "25px",
        height: "25px",
        marginRight: "10px",
        borderRadius: "50%",
    },
};

const ArrowIcon = ({ isOpen }) => (
    <svg
        width="10"
        height="6"
        viewBox="0 0 10 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}
    >
        <path
            d="M1 1L5 5L9 1"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default function UserSelector({ users, currentBoard, onSelectUser, filterByUserId }) {
    const [isOpen, setIsOpen] = useState(false);
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [width, setWidth] = useState(200); // Başlangıç genişliği
    const selectedUserRef = useRef(null);

    useEffect(() => {
        updateWidth();
    }, [currentBoard]);

    const updateWidth = () => {
        if (selectedUserRef.current) {
            const textWidth = selectedUserRef.current.scrollWidth;
            setWidth(Math.max(textWidth + 40, 200)); // 40px ek alan için, minimum 200px
        }
    };

    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleSelectBoard = (userId) => {
        onSelectUser(userId);
        setIsOpen(false);
    };

    const filteredUser = filterByUserId ? currentBoard.users.find((user) => user.userId == filterByUserId) : null

    return (
        <div style={{ ...styles.boardSelector, width: `${width}px` }}>
            <div style={styles.selectedBoard} onClick={toggleDropdown}>
                <span ref={selectedUserRef} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {/* {filterByUserId ? currentBoard.users[0].fullName : 'Select a board'} */}
                    {filteredUser ? filteredUser.fullName : "Select a User"

                    }
                </span>
                <span style={styles.arrow}>
                    <ArrowIcon isOpen={isOpen} />
                </span>
            </div>
            {isOpen && (
                <div style={{ ...styles.dropdown, width: `${width}px` }}>
                    <div
                        key={"1"}
                        onClick={() => handleSelectBoard(null)}
                        // onMouseEnter={() => setHoveredIndex(index)}
                        // onMouseLeave={() => setHoveredIndex(null)}
                        style={{
                            ...styles.dropdownItem,
                            // ...(hoveredIndex === index ? styles.dropdownItemHover : {})
                        }}
                    >
                        <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            All
                        </span>
                        {!filteredUser && (
                            <span style={styles.checkmark}>✓</span>
                        )}
                    </div>

                    {users && users.map((user, index) => (
                        <div
                            key={user.userId}
                            onClick={() => handleSelectBoard(user.userId)}
                            onMouseEnter={() => setHoveredIndex(index)}
                            onMouseLeave={() => setHoveredIndex(null)}
                            style={{
                                ...styles.dropdownItem,
                                ...(hoveredIndex === index ? styles.dropdownItemHover : {})
                            }}
                        >
                            <div style={styles.userInfo}>
                                <img
                                    src={user.profileImg}
                                    alt={user.fullName}
                                    style={styles.userImage}
                                />
                                <span>{user.fullName}</span>
                            </div>
                            {/* <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                {user.fullName}
                            </span> */}
                            {currentBoard && user.userId === filterByUserId && (
                                <span style={styles.checkmark}>✓</span>
                            )}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}