import React, { useState } from 'react';
import { PlusCircle, CheckCircle, XCircle, Clock, AlertCircle, MessageSquareMore, Trash2 } from 'lucide-react';
import { useQuery, useMutation } from '@tanstack/react-query';
import { ToastContainer, toast } from "react-toastify";

import PageLoadingAnimation from "../../../components/Loading/PageLoadingAnimation";
import * as Queries from '../../../utils/queries';

const Proposal = ({ userProfile }) => {
  const [page, setPage] = useState(1);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedProposalId, setSelectedProposalId] = useState(null);

  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: ['all-proposals', page],
    queryFn: () => Queries.getAllProposals({ freelancerId: userProfile.userId, page }),
  });

  const { mutate: deleteProposal } = useMutation({
    mutationFn: (proposalId) => Queries.deleteProposal(proposalId),
    onSuccess: async () => {
      await refetch();
      setIsDeleteModalOpen(false);
      toast.success("Proposal deleted successfully.");
    }
  });

  const handleDelete = async () => {
    deleteProposal(selectedProposalId);
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case 'pending':
        return <Clock className="tw-w-5 tw-h-5 tw-text-yellow-500" />;
      case 'accepted':
        return <CheckCircle className="tw-w-5 tw-h-5 tw-text-green-500" />;
      case 'rejected':
        return <XCircle className="tw-w-5 tw-h-5 tw-text-red-500" />;
      default:
        return null;
    }
  };

  const getTotalAmount = (services) => {
    return services.reduce((total, service) => {
      return total + (parseFloat(service.price) || 0);
    }, 0);
  };

  if (isLoading) {
    return <PageLoadingAnimation />
  }

  if (isError) {
    return (
      <div className="tw-min-h-screen tw-bg-gray-100 tw-flex tw-items-center tw-justify-center tw-p-4">
        <div className="tw-max-w-md tw-w-full tw-bg-white tw-rounded-lg tw-shadow-lg tw-p-6 tw-text-center">
          <div className="tw-flex tw-justify-center tw-mb-4">
            <AlertCircle className="tw-w-16 tw-h-16 tw-text-red-500" />
          </div>

          <h2 className="tw-text-2xl tw-font-bold tw-text-gray-800 tw-mb-2">
            Error Occurred
          </h2>

          <p className="tw-text-gray-600 tw-mb-6">
            An error occurred while fetching data. Please try again.
          </p>

          <div className="tw-space-y-3">
            <button
              onClick={() => window.location.reload()}
              className="tw-w-full tw-bg-red-500 tw-text-white tw-py-2 tw-px-4 tw-rounded-md tw-font-medium tw-hover:bg-red-600 tw-transition-colors btn"
            >
              Try Again
            </button>

            <button
              onClick={() => window.history.back()}
              className="tw-w-full tw-bg-gray-200 tw-text-gray-700 tw-py-2 tw-px-4 tw-rounded-md tw-font-medium tw-hover:bg-gray-300 tw-transition-colors btn"
            >
              Go Back
            </button>
          </div>

          <p className="tw-text-sm tw-text-gray-500 tw-mt-6">
            Sorun devam ederse lütfen destek ekibimizle iletişime geçin.
          </p>
        </div>
      </div>
    )
  }

  return (
    <>
      <ToastContainer />

      {
        isDeleteModalOpen ? (
          <div id="deleteModal" className="tw-fixed tw-inset-0 tw-z-50 tw-flex tw-items-center tw-justify-center tw-bg-black tw-bg-opacity-50 ">
            <div className="tw-bg-white tw-rounded-lg tw-overflow-hidden tw-shadow-xl tw-max-w-md tw-w-full">
              <div className="tw-px-6 tw-py-4 ">
                <div className="tw-flex tw-justify-center tw-mb-4">
                  <AlertCircle className="tw-w-16 tw-h-16 tw-text-red-500" />
                </div>
                <h2 className="tw-text-2xl tw-font-bold tw-text-gray-800 tw-mb-2 tw-text-center">
                  Are you sure?
                </h2>
                <p className="tw-text-gray-600 tw-mb-6 tw-text-center">
                  Do you really want to delete this proposal? This process cannot be undone.
                </p>
                <div className="tw-flex tw-justify-end tw-space-x-3">
                  <button
                    id="cancelButton"
                    className="tw-bg-gray-200 tw-text-gray-700 tw-py-2 tw-px-4 tw-rounded-md tw-font-medium tw-hover:bg-gray-300 tw-transition-colors tw-w-full btn"
                    onClick={() => setIsDeleteModalOpen(false)}
                  >
                    Cancel
                  </button>
                  <button
                    id="confirmButton"
                    className="tw-bg-red-500 tw-text-white tw-py-2 tw-px-4 tw-rounded-md tw-font-medium tw-hover:bg-red-600 tw-transition-colors tw-w-full btn"
                    onClick={handleDelete}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null
      }

      <div className=" tw-px-8 tw-py-8 tw-w-full">
        <div className="tw-flex tw-justify-between tw-items-center tw-mb-6">
          <h1 className="tw-text-2xl tw-font-bold tw-text-gray-800">Proposals</h1>
          <div className="top-buttons">
            <a
              href='create-proposal'
              className="custom-button tw-no-underline tw-flex tw-items-center tw-gap-2 "
            >
              <PlusCircle className="tw-w-5 tw-h-5" />
              Create New Proposal
            </a>
          </div>
        </div>


        {data?.list?.length === 0 ? (
          <div className="tw-text-center tw-py-8">
            <p className="tw-text-gray-500">No proposals found.</p>
          </div>
        ) : (
          <div className="tw-bg-white tw-shadow-md tw-rounded-lg tw-overflow-hidden">
            <div className="tw-overflow-x-auto">
              <table className="tw-w-full">
                <thead className="tw-bg-gray-50">
                  <tr>
                    <th className="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
                      Proposal
                    </th>
                    <th className="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
                      Client
                    </th>
                    <th className="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
                      Amount
                    </th>
                    <th className="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
                      Duration
                    </th>
                    <th className="tw-px-6 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
                      Status
                    </th>
                    <th className="tw-px-10 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="tw-bg-white tw-divide-y tw-divide-gray-200">
                  {data?.list?.map((proposal) => (
                    <tr key={proposal?._id} className="hover:tw-bg-gray-50">
                      <td className="tw-px-6 tw-py-4 tw-whitespace-nowrap">
                        <div className="tw-flex tw-flex-col tw-justify-center">
                          <div className="tw-text-sm tw-font-medium tw-text-gray-900">{proposal?.title.length > 30 ? proposal?.title.slice(0, 30) + '...' : proposal?.title}</div>
                        </div>
                      </td>
                      <td className="tw-px-6 tw-py-4 tw-whitespace-nowrap">
                        <div className="tw-text-sm tw-text-gray-900">{proposal?.clientEmail}</div>
                      </td>
                      <td className="tw-px-6 tw-py-4 tw-whitespace-nowrap">
                        <div className="tw-text-sm tw-text-gray-900">
                          ${getTotalAmount(proposal.services).toLocaleString()}
                        </div>
                      </td>
                      <td className="tw-px-6 tw-py-4 tw-whitespace-nowrap">
                        <div className="tw-text-sm tw-text-gray-900">
                          {proposal?.duration?.value} {proposal?.duration?.unit}
                        </div>
                      </td>
                      <td className="tw-px-6 tw-py-4 tw-whitespace-nowrap">
                        <div className="tw-flex tw-items-center">
                          {getStatusIcon(proposal?.status)}
                          <span className="tw-ml-2 tw-text-sm tw-text-gray-900 tw-capitalize">{proposal?.status}</span>
                        </div>
                      </td>
                      <td className="tw-px-6 tw-py-4 tw-whitespace-nowrap tw-text-sm tw-font-medium">
                        <a href={`/proposal-chat?chat=${proposal?._id}`} target="_blank" className="tw-text-[#40189D] hover:tw-text-[#331475] btn">
                          <MessageSquareMore className="tw-w-5 tw-h-5" />
                        </a>
                        <button
                          onClick={() => {
                            setSelectedProposalId(proposal?._id);
                            setIsDeleteModalOpen(true);
                          }}
                          className="tw-text-red-500 hover:tw-text-red-700 btn tw-ml-2"
                        >
                          <Trash2 className="tw-w-5 tw-h-5" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
        {
          data?.totalPages > 1 && (

            <div className="tw-flex tw-justify-end tw-mt-4">
              {Array.from({ length: data?.totalPages }, (_, index) => (
                <button
                  key={index + 1}
                  onClick={() => setPage(index + 1)}
                  className={`tw-mx-1 btn tw-px-3 tw-py-1 tw-rounded-lg ${page === index + 1 ? 'tw-bg-[#40189D] tw-text-white' : 'tw-bg-gray-100 tw-text-gray-700'}`}
                >
                  {index + 1}
                </button>
              ))}
            </div>
          )
        }
      </div>
    </>
  );
};

export default Proposal;