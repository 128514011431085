import React from "react";
import ReactLoading from "react-loading";
import "../createComponents2/Styles/Personal.scss";

function Personal({
  islinkNickLoading,
  linkNickRef,
  handleTextInputs,
  values,
  isLinkNickAvailable,
  linkError,
}) {
  return (
    <div className="personal-edit-container">
      <h2>Personal</h2>
      <div className="input-container">
        <div className="input-group">
          <div style={{ flex: 6 }}>
            <h3>Name</h3>
            <input
              type="text"
              value={values.fullName}
              onChange={(e) => handleTextInputs(e.target.value, "fullName")}
            />
          </div>

          <div style={{ flex: 6 }}>
            <h3>Your Link Nick</h3>
            <label style={{ color: "gray" }}>upperboard.co/</label>
            <input
              type="text"
              value={values.linkNick}
              style={{ width: "200px" }}
              onChange={(e) => {
                isLinkNickAvailable(e.target.value);
                handleTextInputs(e.target.value, "linkNick");
              }}
            />
            {islinkNickLoading ? (
              <ReactLoading
                type={"bars"}
                color={"black"}
                height={30}
                width={30}
              />
            ) : (
              <>
                {linkNickRef.current !== values.linkNick && (
                  <p style={{ color: linkError.color }}>
                    {linkError.errorMessage}
                  </p>
                )}
              </>
            )}
          </div>
        </div>

        <div className="input-group">
          <div style={{ flex: 6 }}>
            <h3>Role</h3>
            <input
              type="text"
              value={values.role}
              onChange={(e) => handleTextInputs(e.target.value, "role")}
            />
          </div>
          <div style={{ flex: 6 }}>
            {/* <h3>Slogan (Optional)</h3>
            <input type="text" placeholder="" /> */}
          </div>
        </div>

        <div className="input-group">
          <div style={{ flex: 6 }}>
            <h3>City</h3>
            <input
              type="text"
              maxLength={30}
              value={values.city}
              onChange={(e) => handleTextInputs(e.target.value, "city")}
              placeholder="Enter your city"
            />
          </div>
          <div style={{ flex: 6 }}>
            <h3>Country</h3>
            <input
              type="text"
              maxLength={30}
              value={values.country}
              onChange={(e) => handleTextInputs(e.target.value, "country")}
              placeholder="Enter your country"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Personal;
