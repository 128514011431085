import axios from "axios";
import { toast } from "react-toastify";
import { CREATE_BLOG, GET_BLOG_BY_ID, UPDATE_BLOG } from "../../../../../constants/links";

export const getBlogById = (id) => {
    return new Promise((resolve, reject) => {
        axios.get(GET_BLOG_BY_ID + id).then((res) => {
            resolve(res.data)
        })
            .catch(err => {
                reject(err)
            })
    })
}

export const createBlog = async (data, config) => {
    return new Promise((resolve, reject) => {
        axios.post(CREATE_BLOG, data, config).then((res) => {
            toast.success('Profile Created/Updated Succesfully', { theme: "colored", position: "bottom-center" })
            resolve(res.data)
        })
            .catch(err => {
                toast.error(err.response.data.message[0], { theme: "colored", position: "bottom-center" })
                reject(err)
            })
    })
}

export const updateBlog = async (data, config) => {
    return new Promise((resolve, reject) => {
        axios.post(UPDATE_BLOG, data, config).then((res) => {
            toast.success('Profile Created/Updated Succesfully', { theme: "colored", position: "bottom-center" })
            resolve(res.data)
        })
            .catch(err => {
                toast.error(err.response.data.message[0], { theme: "colored", position: "bottom-center" })
                reject(err)
            })
    })
}