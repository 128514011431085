import React from "react";
import "../styles/FloykaProfileCard.scss";
import { FaSquareXTwitter, FaSquareInstagram } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";
import { FaBehanceSquare, FaLinkedin, FaLink } from "react-icons/fa";
import { CiLocationOn } from "react-icons/ci";

const FloykaProfileCard = ({ portfolio }) => {
  return (
    <div className="profile-card-light">
      <div className="card-left">
        <div className="avatar">
          <img
            className="dashboard-partnership-img"
            src={portfolio.profileImg}
          />
        </div>
        <h2>{portfolio.fullName}</h2>
        <p className="location">
          <CiLocationOn style={{ margin: 5, marginBottom: 8 }} />
          {portfolio.city} / {portfolio.country}
        </p>
        <p className="description">{portfolio.about}</p>

        <div className="social-icons">
          {portfolio.socialMedias.map((link) => {
            switch (link.placeholder) {
              case "Instagram":
                return (
                  <a href={link.value}>
                    <FaSquareInstagram />
                  </a>
                );
              case "Behance":
                return (
                  <a href={link.value}>
                    <FaBehanceSquare />
                  </a>
                );
              case "Linkedin":
                return (
                  <a href={link.value}>
                    <FaLinkedin />
                  </a>
                );
              case "Web Link":
                return (
                  <a href={link.value}>
                    <FaLink />
                  </a>
                );

              default:
                break;
            }
            console.log(link);
          })}
        </div>
      </div>
      <div className="card-right">
        <div className="section-container">
          <div className="skills">
            <h1>My Skills</h1>
            <ul>
              {portfolio.skills.map((skill) => (
                <>
                  <h5>{skill.title} </h5>

                  <li>{skill.description}</li>
                </>
              ))}
            </ul>
          </div>
          <div className="experience">
            <h1>Experience</h1>
            <ul>
              {portfolio.experiences.map((experience) => (
                <>
                  <h5>{experience.title} </h5>

                  <li>{experience.description}</li>
                  <li>{experience.date ? experience.date + " Year" : null}</li>
                </>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FloykaProfileCard;
