import React from 'react'

function ProfileNotFound() {
    return (
        <div style={{ width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <h1>!!! Profile Not Found</h1>
        </div>
    )
}

export default ProfileNotFound