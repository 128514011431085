import React, { useState, useRef, useEffect } from 'react';

const styles = {
    boardSelector: {
        position: 'relative',
        fontFamily: 'Arial, sans-serif',
        display: 'inline-block',
    },
    selectedBoard: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px 15px',
        backgroundColor: '#ffffff',
        border: '1px solid #e0e0e0',
        borderRadius: '4px',
        cursor: 'pointer',
        userSelect: 'none',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    arrow: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '10px',
        flexShrink: 0,
    },
    dropdown: {
        position: 'absolute',
        top: '100%',
        left: '0',
        backgroundColor: 'white',
        border: '1px solid #e0e0e0',
        borderTop: 'none',
        borderRadius: '0 0 4px 4px',
        boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
        zIndex: 1000,
        overflowX: 'hidden',
    },
    dropdownItem: {
        padding: '10px 15px',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    dropdownItemHover: {
        backgroundColor: '#f5f5f5',
    },
    checkmark: {
        color: '#4CAF50',
        fontWeight: 'bold',
        marginLeft: '10px',
        flexShrink: 0,
    }
};

const ArrowIcon = ({ isOpen }) => (
    <svg
        width="10"
        height="6"
        viewBox="0 0 10 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}
    >
        <path
            d="M1 1L5 5L9 1"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default function BoardSelector({ boards, currentBoard, onSelectBoard }) {
    const [isOpen, setIsOpen] = useState(false);
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [width, setWidth] = useState(200); // Başlangıç genişliği
    const selectedBoardRef = useRef(null);

    useEffect(() => {
        updateWidth();
    }, [currentBoard]);

    const updateWidth = () => {
        if (selectedBoardRef.current) {
            const textWidth = selectedBoardRef.current.scrollWidth;
            setWidth(Math.max(textWidth + 40, 200)); // 40px ek alan için, minimum 200px
        }
    };

    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleSelectBoard = (board) => {
        onSelectBoard(board);
        setIsOpen(false);
    };

    return (
        <div style={{...styles.boardSelector, width: `${width}px`}}>
            <div style={styles.selectedBoard} onClick={toggleDropdown}>
                <span ref={selectedBoardRef} style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>
                    {currentBoard ? currentBoard.name : 'Select a board'}
                </span>
                <span style={styles.arrow}>
                    <ArrowIcon isOpen={isOpen} />
                </span>
            </div>
            {isOpen && (
                <div style={{...styles.dropdown, width: `${width}px`}}>
                    {boards && boards.map((board, index) => (
                        <div 
                            key={board._id} 
                            onClick={() => handleSelectBoard(board)}
                            onMouseEnter={() => setHoveredIndex(index)}
                            onMouseLeave={() => setHoveredIndex(null)}
                            style={{
                                ...styles.dropdownItem,
                                ...(hoveredIndex === index ? styles.dropdownItemHover : {})
                            }}
                        >
                            <span style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>
                                {board.name}
                            </span>
                            {currentBoard && board._id === currentBoard._id && (
                                <span style={styles.checkmark}>✓</span>
                            )}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}