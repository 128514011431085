import React, { useState, useRef } from 'react';
import { PlusCircle, Trash, Download, Send } from 'react-bootstrap-icons';
import { useMutation } from '@tanstack/react-query';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import { Avatar, Button, Input, Textarea } from './components/general';
import * as Queries from '../../../utils/queries';

const isValidEmailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

const ServiceInput = ({ service, index, updateService, removeService }) => (
    <div className="tw-flex tw-gap-2 tw-mb-2">
        <Input
            placeholder="Service name"
            value={service.name}
            onChange={(e) => updateService(index, 'name', e.target.value)}
        />
        <Input
            type="number"
            placeholder="Price"
            value={service.price}
            onChange={(e) => updateService(index, 'price', e.target.value)}
            min="0"
        />
        <select
            value={service.currency}
            onChange={(e) => updateService(index, 'currency', e.target.value)}
            className="tw-px-3 tw-py-2 tw-border tw-bg-white tw-border-gray-300 tw-border-solid tw-rounded-md tw-shadow-sm focus:tw-outline-none focus:tw-ring-blue-500 focus:tw-border-blue-500"
        >
            <option value="USD">USD</option>
            <option value="EUR">EUR</option>
            <option value="TRY">TRY</option>
        </select>
        <Button variant="outline" onClick={() => removeService(index)} aria-label="Remove service">
            <Trash className="tw-h-4 tw-w-4" />
        </Button>
    </div>
);

const ProposalPreview = ({ componentRef, title, clientEmail, description, services, duration, userProfile }) => (
    <div className="tw-w-full lg:tw-w-1/2 tw-bg-white tw-p-8 tw-rounded-lg tw-shadow-lg tw-border tw-border-gray-200 tw-border-solid">
        <div ref={componentRef} className='tw-w-full tw-h-full'>
            <div className="tw-flex tw-justify-between tw-items-start tw-mb-8">
                <div style={{ overflowWrap: 'break-word', wordBreak: 'break-word' }}>
                    <h2 className="tw-text-2xl tw-font-semibold tw-mb-2 ">{title || 'Proposal Title'}</h2>
                    <p className="tw-text-sm tw-text-gray-500">Prepared for: {clientEmail || 'client@example.com'}</p>
                </div>
                <img
                    src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/siyah%20svg%20board-riV9cS1QEaGJ9vRSamh6mcLb293Ob4.svg"
                    alt="Company Logo"
                    className="tw-w-32 tw-h-auto tw-m-5 tw-hidden lg:tw-block"
                />
            </div>
            <div className="tw-mb-8">
                <h3 className="tw-text-lg tw-font-semibold tw-mb-2">Executive Summary</h3>
                <p className="tw-text-sm tw-text-gray-700" style={{ overflowWrap: 'break-word', whiteSpace: 'break-spaces' }}>
                    {description || 'Your proposal message will appear here.'}
                </p>
            </div>
            <div className="tw-mb-8">
                <h3 className="tw-text-lg tw-font-semibold tw-mb-2">Proposed Services</h3>
                {services.map((service, index) => (
                    <div key={index} className="tw-flex tw-justify-between tw-mb-2 tw-text-sm">
                        <span>{service.name || 'Service Name'}</span>{" "}
                        <span>{service.price || '0'} {service.currency || 'USD'}</span>
                    </div>
                ))}
            </div>
            <div className="tw-mb-8">
                <h3 className="tw-text-lg tw-font-semibold tw-mb-2">Duration</h3>
                <span>{duration.value || '0'} {duration.unit || 'hours'}</span>
            </div>
            <div className="tw-flex tw-items-center tw-mb-8 tw-gap-2">
                <Avatar src={userProfile?.profileImg} />
                <div className="tw-gap-2 tw-flex tw-justify-center tw-flex-col">
                    <p className="tw-font-semibold tw-mb-0 ">{userProfile?.fullName}</p>
                    <p className="tw-text-sm tw-text-gray-500 tw-mb-0">{userProfile?.role}</p>
                </div>
            </div>
            <div className="tw-text-sm tw-text-gray-500">
                <p>Thank you for considering our proposal. We look forward to working with you.</p>
            </div>
        </div>
    </div>
);

export default function ProposalCreator({ userProfile }) {
    const componentRef = useRef();
    const navigate = useNavigate();
    const [clientEmail, setClientEmail] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [services, setServices] = useState([]);
    const [duration, setDuration] = useState({ value: '', unit: 'hours' });

    const { mutate, isPending } = useMutation({
        mutationKey: ['proposal'],
        mutationFn: async (data) => {
            await Queries.createProposal(data);
        },
        onSuccess: (res) => {
            toast.success('Proposal sent successfully!');
            navigate('/proposal');
        },
        onError: (error) => {
            toast.error(`Error: ${error.message}`);
        }
    });

    const addService = () => {
        setServices([...services, { name: '', price: '', currency: 'USD' }]);
    };

    const removeService = (index) => {
        const newServices = services.filter((_, i) => i !== index);
        setServices(newServices);
    };

    const updateService = (index, field, value) => {
        const newServices = services.map((service, i) => {
            if (i === index) {
                return { ...service, [field]: value };
            }
            return service;
        });
        setServices(newServices);
    };

    const handleDownloadPDF = async () => {
        const input = componentRef.current;
        const canvas = await html2canvas(input, {
            scale: 2,
            useCORS: true,
        });

        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const margin = 10;
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth() - 2 * margin;
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, 'PNG', margin, margin, pdfWidth, pdfHeight);
        pdf.save('proposal.pdf');
    };

    const sendOffer = () => {
        const isEmpty = !clientEmail || !title || !description || services.length === 0 || services.some(service => !service.name || !service.price) || duration.value === '';
        const isValidEmail = isValidEmailRegex.test(clientEmail);

        if (isEmpty) {
            toast.error('Please fill all fields');
            return;
        }

        if (!isValidEmail) {
            toast.error('Invalid email address');
            return;
        }

        mutate({
            clientEmail,
            title,
            description,
            services,
            duration,
            freelancer: {
                id: userProfile?.userId,
                fullName: userProfile?.fullName,
                role: userProfile?.role,
                profileImg: userProfile?.profileImg,
            }
        });
    };

    return (
        <div className="tw-container tw-mx-auto tw-py-4 tw-px-10">
            <h1 className="tw-text-3xl tw-font-bold tw-mb-6">Create Proposal</h1>
            <div className="tw-flex tw-flex-col lg:tw-flex-row tw-gap-8">
                <ProposalPreview
                    componentRef={componentRef}
                    title={title}
                    clientEmail={clientEmail}
                    description={description}
                    services={services}
                    duration={duration}
                    userProfile={userProfile}
                />
                <div className="tw-w-full lg:tw-w-1/2">
                    <div className="tw-mb-4">
                        <label htmlFor="clientEmail" className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
                            Client Email
                        </label>
                        <Input
                            id="clientEmail"
                            type="email"
                            placeholder="client@example.com"
                            value={clientEmail}
                            onChange={(e) => setClientEmail(e.target.value)}
                        />
                    </div>
                    <div className="tw-mb-4">
                        <label htmlFor="title" className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
                            Proposal Title
                        </label>
                        <Input
                            id="title"
                            placeholder="Enter proposal title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                    </div>
                    <div className="tw-mb-4">
                        <label htmlFor="message" className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">
                            Executive Summary
                        </label>
                        <Textarea
                            id="message"
                            placeholder="Enter your proposal summary"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            rows={6}
                        />
                    </div>
                    <div className="tw-mb-4">
                        <h3 className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">Duration</h3>
                        <div className="tw-flex tw-gap-2">
                            <Input
                                type="number"
                                placeholder="Duration"
                                value={duration.value}
                                onChange={(e) => setDuration({ ...duration, value: e.target.value })}
                                min="0"
                            />
                            <select
                                value={duration.unit}
                                onChange={(e) => setDuration({ ...duration, unit: e.target.value })}
                                className="tw-px-3 tw-py-2 tw-border tw-bg-white tw-border-gray-300 tw-border-solid tw-rounded-md tw-shadow-sm focus:tw-outline-none focus:tw-ring-blue-500 focus:tw-border-blue-500"
                            >
                                <option value="hours">Hours</option>
                                <option value="days">Days</option>
                                <option value="weeks">Weeks</option>
                                <option value="months">Months</option>
                                <option value="years">Years</option>
                            </select>
                        </div>
                    </div>
                    <div className="tw-mb-4">
                        <h3 className="tw-block tw-text-sm tw-font-medium tw-text-gray-700 tw-mb-1">Services</h3>
                        {services.map((service, index) => (
                            <ServiceInput
                                key={index}
                                service={service}
                                index={index}
                                updateService={updateService}
                                removeService={removeService}
                            />
                        ))}
                        <Button variant="outline" onClick={addService} className="tw-mt-2" aria-label="Add service">
                            <PlusCircle className="tw-h-4 tw-w-4 tw-mr-2" />
                            Add Service
                        </Button>
                    </div>
                </div>
            </div>
            <div className="tw-flex tw-justify-end tw-mt-6 tw-gap-4">
                <Button variant="outline" onClick={handleDownloadPDF} aria-label="Send as PDF">
                    <Download className="tw-h-4 tw-w-4 tw-mr-2" />
                    Send as PDF
                </Button>
                <Button onClick={sendOffer} aria-label="Send Offer" isLoading={isPending}>
                    <Send className="tw-h-4 tw-w-4 tw-mr-2" />
                    Send Offer
                </Button>
            </div>
            <ToastContainer />
        </div>
    );
}
