import React, { useState } from "react";
import Select from "react-select";

const styles = {
  container: {
    padding: "20px",
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0,0,0,0)",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
  },
  title: {
    fontSize: "24px",
    fontWeight: "bold",
    margin: 0,
  },
  label: {
    fontWeight: "bold",
    marginBottom: "5px",
    display: "block",
    fontSize: "14px",
    color: "#333",
  },
  input: {
    width: "100%",
    padding: "10px",
    marginBottom: "15px",
    border: "1px solid #ced4da",
    borderRadius: "4px",
    fontSize: "16px",
  },
  dateContainer: {
    display: "flex",
    justifyContent: "space-between",
    gap: "15px",
    marginBottom: "15px",
  },
  dateInput: {
    width: "calc(50% - 7.5px)",
  },
  text: {
    fontSize: "16px",
    marginBottom: "15px",
    padding: "10px",
    backgroundColor: "#e9ecef",
    borderRadius: "4px",
    border: "1px solid #ced4da",
  },
  userInfo: {
    display: "flex",
    alignItems: "center",
  },
  userImage: {
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    marginRight: "10px",
  },
};

export default function ViewTaskDetails({
  currentBoard,
  initialTask,
  onSubmit,
  onClose,
}) {
  const [task, setTask] = useState({
    ...initialTask,
    priority: initialTask.priority || null, // Başlangıçta boş
  });

  const [isEditing, setIsEditing] = useState(false);
  const stateOptions =
    currentBoard?.states?.map((state) => ({
      value: state,
      label: state,
    })) || [];

  const priorityOptions = [
    { value: "low", label: "Low" },
    { value: "medium", label: "Medium" },
    { value: "high", label: "High" },
  ];

  const userOptions =
    currentBoard?.users?.map((user) => ({
      value: user.userId,
      label: (
        <div style={styles.userInfo}>
          <img
            src={user.profileImg}
            alt={user.fullName}
            style={styles.userImage}
          />
          {user.fullName}
        </div>
      ),
    })) || [];

  const handleUserChange = (selectedOption) => {
    setTask((prevTask) => ({
      ...prevTask,
      appointedUserId: selectedOption.value,
    }));
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toISOString().split("T")[0];
  };

  const handleInputChange = (field, value) => {
    setTask((prevTask) => ({ ...prevTask, [field]: value }));
  };

  const handlePriorityChange = (selectedOption) => {
    setTask((prevTask) => ({ ...prevTask, priority: selectedOption.value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(task);
    setIsEditing(false);
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <h2 style={styles.title}>Task Details</h2>
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleEditToggle}
        >
          {isEditing ? "Cancel" : "Edit"}
        </button>
      </div>

      <form onSubmit={handleSubmit}>
        <div>
          <label style={styles.label}>Task Title</label>
          {isEditing ? (
            <input
              style={styles.input}
              type="text"
              value={task.title}
              onChange={(e) => handleInputChange("title", e.target.value)}
            />
          ) : (
            <div style={styles.text}>{task.title}</div>
          )}
        </div>

        <div>
          <label style={styles.label}>Task Description</label>
          {isEditing ? (
            <textarea
              style={{ ...styles.input, height: "100px" }}
              value={task.description}
              onChange={(e) => handleInputChange("description", e.target.value)}
            />
          ) : (
            <div style={styles.text}>{task.description}</div>
          )}
        </div>

        <div style={styles.dateContainer}>
          <div style={styles.dateInput}>
            <label style={styles.label}>Start Date</label>
            {isEditing ? (
              <input
                style={styles.input}
                type="date"
                value={formatDate(task.startDate)}
                onChange={(e) => handleInputChange("startDate", e.target.value)}
              />
            ) : (
              <div style={styles.text}>{formatDate(task.startDate)}</div>
            )}
          </div>
          <div style={styles.dateInput}>
            <label style={styles.label}>End Date</label>
            {isEditing ? (
              <input
                style={styles.input}
                type="date"
                value={formatDate(task.endDate)}
                onChange={(e) => handleInputChange("endDate", e.target.value)}
              />
            ) : (
              <div style={styles.text}>{formatDate(task.endDate)}</div>
            )}
          </div>
        </div>

        <div>
          <label style={styles.label}>Status</label>
          {isEditing ? (
            <Select
              styles={{
                control: (provided) => ({
                  ...provided,
                  marginBottom: "15px",
                }),
              }}
              value={stateOptions.find((option) => option.value === task.state)}
              onChange={(selectedOption) =>
                handleInputChange("state", selectedOption.value)
              }
              options={stateOptions}
            />
          ) : (
            <div style={styles.text}>{task.state}</div>
          )}
        </div>
        <div>
          <label style={styles.label}>Assigned User</label>
          {isEditing ? (
            <Select
              styles={{
                control: (provided) => ({
                  ...provided,
                  marginBottom: "15px",
                }),
              }}
              value={userOptions.find(
                (option) => option.value === task.appointedUserId
              )}
              onChange={handleUserChange}
              options={userOptions}
            />
          ) : (
            <div style={styles.text}>
              {userOptions.find(
                (option) => option.value === task.appointedUserId
              )?.label || "Not assigned"}
            </div>
          )}
        </div>
        <div>
          <label style={styles.label}>Priority</label>
          {isEditing ? (
            <Select
              styles={{
                control: (provided) => ({
                  ...provided,
                  marginBottom: "15px",
                }),
              }}
              value={priorityOptions.find(
                (option) => option.value === task.priority
              )}
              onChange={handlePriorityChange}
              options={priorityOptions}
            />
          ) : (
            <div style={styles.text}>
              {priorityOptions.find((option) => option.value === task.priority)
                ?.label || "Not set"}
            </div>
          )}
        </div>

        {isEditing && (
          <button
            type="submit"
            className="btn btn-success"
            style={{ marginTop: "20px" }}
          >
            Save Changes
          </button>
        )}
      </form>
    </div>
  );
}
