import { Dropdown } from "react-bootstrap";

const TaskboardMenu = ({ onCreateTaskBoard, onDeleteTaskBoard }) => (
  <Dropdown align="end">
    <Dropdown.Toggle
      variant="link"
      id="dropdown-basic"
      bsPrefix="custom-dropdown-toggle"
      style={{
        color: "black",
        fontSize: "26px",
        fontWeight: "normal",
        textDecoration: "none",
        paddingLeft: 15,
        marginBottom: "8px",
        "&::after": {
          display: "none",
        },
      }}
    >
      &#x22EE;
    </Dropdown.Toggle>

    <Dropdown.Menu>
      <Dropdown.Item onClick={onCreateTaskBoard}>
        Create New Task Board
      </Dropdown.Item>
      <Dropdown.Item onClick={onDeleteTaskBoard}>
        Delete Task Board
      </Dropdown.Item>
    </Dropdown.Menu>
  </Dropdown>
);

export default TaskboardMenu;
