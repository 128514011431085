export const styles = {
    mainSection: {
        paddingTop: 100,
        paddingBottom: 100,
        // backgroundColor: '#1d1d1d',
        paddingLeft: 50,
        paddingRight: 50,
    },
    gmailUl: {
        marginTop: 60,
        display: 'flex',
        paddingLeft: 0
    },
    linksDiv: {
        display: 'flex',
        flexDirection: 'row'
    },
    icon: {
        fontSize: 50,
        padding: 5
    },
    emailInput: {
        marginBottom: 30,
    }
}