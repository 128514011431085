import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PageLoadingAnimation from "../../../../components/Loading/PageLoadingAnimation";
import { getBlogById } from "./requests/requests";

function ShowBlog() {
  const [blog, setBlog] = useState([]);
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const path = location.pathname.split("/");
    getBlogById(path[path.length - 1]).then((res) => {
      setBlog(res);
      console.log(res);
      setLoading(false);
    });
  }, []);

  if (loading) return <PageLoadingAnimation />;

  return (
    <div className="show-blog-main">
      <div className="w-100">
        <h1 className="show-blog-title">{blog.title}</h1>
        <p className="show-blog-desc">{blog.description}</p>
      </div>
      {blog.length !== 0 ? (
        blog.contents.map((item, index) => {
          if (item.tag === "img") {
            // item.scale değerini kontrol ediyoruz, yoksa varsayılan olarak 1 atıyoruz
            const scale = item.scale || 1;
            return (
              <div
                key={index}
                className="item-margin"
                style={{
                  textAlign: item.align,
                  paddingBottom: "100px",
                  width: "100%",
                }}
              >
                <img
                  src={item.content}
                  className={item.showClassName + String(scale)}
                  alt=""

                />
              </div>
            );
          } else if (item.tag === "p") {
            return (
              <p
                key={index}
                className={"item-margin " + item.showClassName}
                style={{ textAlign: item.align }}
              >
                {item.content}
              </p>
            );
          } else {
            return (
              <h2
                key={index}
                className={"item-margin " + item.showClassName}
                style={{ textAlign: item.align }}
              >
                {item.content}
              </h2>
            );
          }
        })
      ) : (
        <div></div>
      )}
    </div>
  );
}

export default ShowBlog;
