import React from "react";

function Step2({ setVerificationCodeInput, sendEmailVerification, verifyEmail, minute, second, resendDisabled }) {
    return (
        <div className='appyl-inputs-div'>
            <h5 style={{ color: 'black', fontWeight: 'bold' }}>Freelance boldly with UpperBoard</h5>
            <h6 style={{ color: 'black' }}>Everything a freelancer needs in one place</h6>
            <input className='col-sm-8 col-md-8 col-xl-5 apply-input' placeholder='Verification Code' onChange={(e) => setVerificationCodeInput(e.target.value)} />
            <p style={{ color: 'black' }}>0{minute}:{second < 10 ? ('0') : (<></>)}{second}</p>

      <div className="row">
        <button
          className="apply-buttons"
          onClick={sendEmailVerification}
          disabled={resendDisabled}
        >
          Resend
        </button>
        <button className="apply-buttons" onClick={verifyEmail}>
          Verify
        </button>
      </div>
    </div>
  );
}

export default Step2;
