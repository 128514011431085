import React, { useEffect, useState } from 'react'
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Input,
    FormGroup,
    Label,
    Button,
    Dropdown,
    DropdownToggle,
} from "reactstrap"
import axios from "axios"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import { CREATE_TICKET, GET_ALL_FREELANCER_ROLES } from '../../../constants/links';
import { getUserIdFromCookie } from '../../../helpers/cookieHelper';
import { ToastContainer, toast } from 'react-toastify';
function CreateTicket() {
    const [startDate, setstartDate] = useState(new Date())
    const [endDate, setendDate] = useState(new Date())
    const [ticket, setTicket] = useState({})

    const [roles, setRoles] = useState([])
    const [rolesState, setRolesState] = useState([])

    const handleTextInputs = (value, name) => {
        let updatedValue = { [name]: value };
        setTicket(info => ({
            ...info,
            ...updatedValue
        }));
    }


    useEffect(() => {
        if (ticket.role !== undefined && ticket.role !== '') {
            const array = roles.filter(item => item.includes(ticket.role))
            setRolesState(array)
        }
        else {
            setRolesState(roles)
        }
    }, [ticket.role])

    useEffect(() => {
        axios.get(GET_ALL_FREELANCER_ROLES)
            .then((res) => {
                setRoles(res.data)
                setRolesState(res.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    const createTicket = () => {
        axios.post(CREATE_TICKET, { ...ticket }, {
            headers: {
                userid: getUserIdFromCookie()
            }
        })
            .then((res) => {
                if (res.data.message == 'Ticket limit reached!') {
                    toast.error(res.data.message, { theme: "colored", position: "bottom-right" })
                }
                else {
                    toast.success(res.data, { theme: "colored", position: "bottom-right" })
                }
                console.log(res.data)

                setTicket({
                    description: ''
                })
            })
            .catch((err) => {
                console.log(err)
                toast.error(err.data.response.message, { theme: "colored", position: "bottom-right" })
                setTicket({})

            })
    }


    return (
        <div className="page-content">
            <ToastContainer />
            <Container fluid>
                <Row>
                    <Col lg="12">
                        <Card style={{ border: 'none' }}>
                            <CardBody>
                                <CardTitle className="mb-4">Create New Ticket</CardTitle>
                                <form className="outer-repeater">
                                    <div data-repeater-list="outer-group" className="outer">
                                        <div data-repeater-item className="outer">
                                            <FormGroup className="mb-4" row>
                                                <Label
                                                    htmlFor="taskname"
                                                    className="col-form-label col-lg-2"
                                                >
                                                    Ticket Name
                                                </Label>
                                                <Col lg="10">
                                                    <Input
                                                        id="taskname"
                                                        name="taskname"
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Ticket Title..."
                                                        value={ticket.title}
                                                        onChange={(e) => handleTextInputs(e.target.value, 'title')}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup className="mb-4" row>
                                                <Label className="col-form-label col-lg-2">
                                                    Ticket Description
                                                </Label>
                                                <Col lg="10">
                                                    <Input
                                                        style={{ width: '100%', height: 100 }}
                                                        id="taskname"
                                                        name="taskname"
                                                        type="textarea"
                                                        className="form-control"
                                                        placeholder="Ticket Description..."
                                                        value={ticket.description}

                                                        onChange={(e) => handleTextInputs(e.target.value, 'description')}

                                                    />
                                                    {/* <CKEditor
                                                            editor={ClassicEditor}
                                                            data="<p>Hello from CKEditor 5!</p>"
                                                            onReady={editor => {
                                                                // You can store the "editor" and use when it is needed.
                                                                // console.log('Editor is ready to use!', editor);
                                                            }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                            }}
                                                        /> */}
                                                </Col>
                                            </FormGroup>

                                            <FormGroup className="mb-4" row>
                                                <Label className="col-form-label col-lg-2">
                                                    Ticket Date
                                                </Label>
                                                <Col lg="10">
                                                    <Row>
                                                        <Col md={6} className="pr-0">
                                                            <DatePicker
                                                                className="form-control"
                                                                placeholderText='Start Date'
                                                                selected={ticket.startDate}
                                                                onChange={(date) => handleTextInputs(date, 'startDate')}
                                                            />
                                                        </Col>
                                                        <Col md={6} className="pl-0">
                                                            <DatePicker
                                                                placeholderText='End Date'
                                                                className="form-control"
                                                                selected={ticket.endDate}
                                                                onChange={(date) => handleTextInputs(date, 'endDate')}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </FormGroup>

                                            <div className="inner-repeater mb-4">
                                                {/* <div className="inner form-group mb-0 row">
                                                        <Label className="col-form-label col-lg-2">
                                                            Add Team Member
                                                        </Label>
                                                        <div
                                                            className="inner col-lg-10 ml-md-auto"
                                                            id="repeater"
                                                        >
                                                            {inputFields.map((field, key) => (
                                                                <div
                                                                    key={key}
                                                                    id={"nested" + key}
                                                                    className="mb-3 row align-items-center"
                                                                >
                                                                    <Col md="6">
                                                                        <input
                                                                            type="text"
                                                                            className="inner form-control"
                                                                            defaultValue={field.name}
                                                                            placeholder="Enter Name..."
                                                                        />
                                                                    </Col>
                                                                    <Col md="4">
                                                                        <div className="mt-4 mt-md-0">
                                                                            <Input
                                                                                type="file"
                                                                                className="form-control"
                                                                                defaultValue={field.file}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col md="2">
                                                                        <div className="mt-2 mt-md-0 d-grid">
                                                                            <Button
                                                                                color="primary"
                                                                                className="inner"
                                                                                onClick={() => {
                                                                                    // handleRemoveFields(key)
                                                                                }}
                                                                                block
                                                                            >
                                                                                Delete
                                                                            </Button>
                                                                        </div>
                                                                    </Col>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    <Row className="justify-content-end">
                                                        <Col lg="10">
                                                            <Button
                                                                color="success"
                                                                className="inner"
                                                                onClick={() => {
                                                                    // handleAddFields()
                                                                }}
                                                            >
                                                                Add Member
                                                            </Button>
                                                        </Col>
                                                    </Row> */}
                                            </div>
                                            <FormGroup className="mb-4" row>
                                                <label
                                                    htmlFor="taskbudget"
                                                    className="col-form-label col-lg-2"
                                                >
                                                    Budget
                                                </label>
                                                <div className="col-lg-5">
                                                    <Input
                                                        id="taskbudget"
                                                        name="taskbudget"
                                                        type="text"
                                                        placeholder="Enter Ticket Budget..."
                                                        className="form-control"
                                                        value={ticket.budget}

                                                        onChange={(e) => handleTextInputs(e.target.value, 'budget')}
                                                    />
                                                </div>
                                                <div className="col-lg-5">
                                                    <Input
                                                        name="taskbudget"
                                                        type="text"
                                                        placeholder="What skills needed..."
                                                        className="form-control"
                                                        data-toggle="dropdown" id="combobox"
                                                        value={ticket.role}
                                                        onChange={(e) => handleTextInputs(e.target.value, 'role')}
                                                    />

                                                    <div class="dropdown-menu" aria-labelledby="combobox" style={{ backgroundColor: '#1a1a1a', width: 'auto', overflowY: 'auto', maxHeight: 200, display: rolesState.length == 0 ? 'none' : '' }}>
                                                        {
                                                            rolesState.map((item, index) => {
                                                                return (
                                                                    <a onClick={() => handleTextInputs(item, 'role')} class="dropdown-item" href="#" style={{ color: 'white' }}>{item}</a>
                                                                )
                                                            })
                                                        }

                                                    </div>
                                                </div>
                                            </FormGroup>
                                        </div>
                                    </div>
                                </form>
                                <Row className="justify-content-end">
                                    <Col lg="10">
                                        <Button type="submit" color="dark" onClick={createTicket}>
                                            Create Ticket
                                        </Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default CreateTicket