import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import CollectiveProfileCard from "../../components/CollectiveProfileCard";
import PageLoadingAnimation from "../../components/Loading/PageLoadingAnimation";
import { GET_ALL_FREELANCERS } from "../../constants/links";

const fetchFreelancers = async (currentPage) => {
  const response = await axios.get(GET_ALL_FREELANCERS + currentPage);
  return response.data; // Axios otomatik olarak JSON'u parse eder
};

function Collective({ inDashboard }) {
  const queryClient = useQueryClient();
  const [freelancers, setFreelancers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);

  const { isLoading, isError, data, error } = useQuery({
    queryKey: ["getAll", currentPage],
    queryFn: () => fetchFreelancers(currentPage),
  });

  // const { loading, error, value } = useFetch(GET_ALL_FREELANCERS + currentPage, { method: 'GET' }, [currentPage]);

  useEffect(() => {
    if (data) {
      setFreelancers((prev) => [...prev, ...data.freelancers]);
      setHasMore(data.freelancers.length > 0);
      setLoadingMore(false);
    }
  }, [data]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop >=
          document.documentElement.offsetHeight - 500 &&
        hasMore &&
        !loadingMore
      ) {
        setLoadingMore(true);
        setCurrentPage((prevPage) => prevPage + 1);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [hasMore, loadingMore]);

  if (isLoading && currentPage === 1) {
    return <PageLoadingAnimation />;
  }

  if (!isLoading && freelancers.length === 0) {
    return <> </>;
  }

  return (
    <div className="container-fluid">
      <div
        className="row"
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          marginTop: inDashboard ? 0 : 150,
        }}
      >
        <div
          className="text-center"
          style={{
            backgroundColor: "#212121",
            padding: 30,
            marginBottom: 50,
            width: "90%",
            minHeight: "300px",
            height: "auto",
            borderRadius: 20,
            marginTop: 20,
            display: "flex",
            flexDirection: "column",
            // alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h1 style={{ fontSize: 50, fontWeight: "bold", color: "white" }}>
            Everything a freelancer needs in one place
          </h1>
          <h4 style={{ color: "GrayText", fontSize: 20 }}>
            UpperBoard brings together all the essential tools and features
            freelancers require, streamlining their workflow and enhancing
            productivity.
          </h4>
        </div>
        {freelancers.map((item, index) => (
          <CollectiveProfileCard
            key={index}
            userName={item.fullName}
            userRole={item.role}
            userImage={item.profileImg}
            linkNick={item.linkNick}
            isDisable={false}
            cardImg={item.showcase}
            inDashboard={inDashboard}
          />
        ))}
      </div>
      {loadingMore && (
        <div className="d-flex justify-content-center">
          <ReactLoading
            type={"spinningBubbles"}
            color={"black"}
            height={100}
            width={100}
          />
        </div>
      )}
    </div>
  );
}

export default Collective;
