import React, { useState } from 'react';
const styles = {
    formGroup: {
        marginBottom: "15px",
    },
    inlineContainer: {
        display: "flex",
        justifyContent: "space-between",
        gap: "15px",
    },
    label: {
        display: "block",
        marginBottom: "3px",
        fontWeight: "bold",
        color: "#333",
        fontSize: "14px",
    },
    // Tüm input'lar için ortak stil
    input: {
        width: "100%",
        padding: "8px",
        border: "1px solid #ddd",
        borderRadius: "4px",
        fontSize: "13px",
        transition: "border-color 0.3s",
        height: "36px", // Title input ile aynı yükseklik
    },
    inputFocus: {
        outline: "none",
        borderColor: "#007bff",
        boxShadow: "0 0 0 2px rgba(0,123,255,.25)",
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: "20px",
    },
    button: {
        padding: "8px 15px",
        border: "none",
        borderRadius: "4px",
        cursor: "pointer",
        fontSize: "14px",
        transition: "background-color 0.3s",
        backgroundColor: "#007bff",
        color: "white",
    },
    buttonHover: {
        filter: "brightness(90%)",
    },
};

function AddProjectFile({ onSubmit }) {

    const [file, setFile] = useState({})
    const [focusedInput, setFocusedInput] = useState(null);
    const handleTextInputs = (value, name) => {
        let updatedValue = { [name]: value };
        setFile(info => ({
            ...info,
            ...updatedValue
        }));
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(file)
        onSubmit(file);
        setFile({
            fileName: '',
            fileLink: ''
        })
    };


    return (
        <form onSubmit={handleSubmit}>
            <div style={styles.formGroup}>
                <label htmlFor="editTaskTitle" style={styles.label}>
                    File Name
                </label>
                <input
                    type="text"
                    id="editTaskTitle"
                    name="title"
                    value={file.fileName}
                    onChange={(e) => handleTextInputs(e.target.value, 'fileName')}
                    required
                    style={{
                        ...styles.input,
                        ...(focusedInput === "title" ? styles.inputFocus : {}),
                    }}
                    onFocus={() => setFocusedInput("name")}
                    onBlur={() => setFocusedInput(null)}
                />
            </div>

            <div style={styles.formGroup}>
                <label htmlFor="editTaskTitle" style={styles.label}>
                    File Link
                </label>
                <input
                    type="text"
                    id="editTaskTitle"
                    name="title"
                    value={file.fileLink}
                    onChange={(e) => handleTextInputs(e.target.value, 'fileLink')}
                    required
                    style={{
                        ...styles.input,
                        ...(focusedInput === "title" ? styles.inputFocus : {}),
                    }}
                    onFocus={() => setFocusedInput("link")}
                    onBlur={() => setFocusedInput(null)}
                />
            </div>
            <div style={styles.buttonContainer}>
                <button
                    type="submit"
                    style={styles.button}
                    onMouseOver={(e) =>
                        (e.target.style.filter = styles.buttonHover.filter)
                    }
                    onMouseOut={(e) => (e.target.style.filter = "none")}
                >
                    Create Link
                </button>
            </div>
        </form>
    )
}

export default AddProjectFile