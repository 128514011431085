import React from 'react'

const AcceptedProposal = ({ projectId }) => {
    return (
        <div className="tw-fixed tw-inset-0 tw-bg-[#40189D]/20 tw-backdrop-blur-sm tw-flex tw-items-center tw-justify-center tw-p-4 tw-z-50">
            <div className="tw-relative tw-w-full tw-max-w-lg tw-animate-fadeIn">
                <div className="tw-bg-white/90 tw-backdrop-blur tw-border tw-border-[#40189D]/20 tw-shadow-xl tw-rounded-xl tw-p-6">
                    <div className="tw-space-y-6">
                        <div className="tw-text-center">
                            <h2 className="tw-text-2xl tw-font-bold tw-text-[#40189D] tw-mb-2">
                                You've Accepted the Proposal!
                            </h2>
                            <p className="tw-text-gray-600 tw-mb-6">
                                To start managing your project on UpperBoard, please follow these steps:
                            </p>
                        </div>

                        <div className="tw-space-y-4">
                            <div className="tw-flex tw-gap-2">
                                <span className="tw-font-semibold tw-text-[#40189D]">1.</span>
                                <p className="tw-text-gray-700">
                                    <span className="tw-font-semibold">Sign up</span> on UpperBoard. (
                                    <a
                                        href="http://www.upperboard.co"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="tw-text-[#40189D] hover:tw-text-[#40189D]/80 tw-underline"
                                    >
                                        www.upperboard.co
                                    </a>
                                    )
                                </p>
                            </div>

                            <div className="tw-flex tw-gap-2">
                                <span className="tw-font-semibold tw-text-[#40189D]">2.</span>
                                <p className="tw-text-gray-700">
                                    Go to the <span className="tw-font-semibold">Projects</span> page and select <span className="tw-font-semibold">Join with ID</span>.
                                </p>
                            </div>

                            <div className="tw-flex tw-gap-2">
                                <span className="tw-font-semibold tw-text-[#40189D]">3.</span>
                                <p className="tw-text-gray-700">
                                    Enter your Project Invite ID:
                                    <code className="tw-font-mono tw-bg-[#40189D]/5 tw-text-[#40189D] tw-px-2 tw-py-0.5 tw-rounded tw-mx-1">
                                        {projectId}
                                    </code>
                                    to access and collaborate on your project.
                                </p>
                            </div>
                        </div>

                        <div className="tw-text-center tw-text-gray-600 tw-mt-6">
                            <p className="tw-font-medium tw-text-[#40189D]">We're excited to work with you!</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AcceptedProposal