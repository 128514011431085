import React from 'react'
import { toast } from 'react-toastify'
import { themeEnum } from '../../../../../constants/themeEnums.js'
import { styles } from './styles/shareButtonStyles.js'
function ShareButton({ activeTheme, linkNick }) {
    const copyLink = () => {
        const portfolioUrl = "https://upperboard.co/" + linkNick

        if (window.isSecureContext && navigator.clipboard) {
            navigator.clipboard.writeText(portfolioUrl);
        } else {
            const textArea = document.createElement("textarea");
            textArea.value = portfolioUrl;
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            try { document.execCommand('copy') }
            catch (err) { console.error('Unable to copy to clipboard', err) }
            document.body.removeChild(textArea)
        }

        toast.success('Link Copied to Clipboard', { theme: "colored", position: "bottom-center" })
    }
    return (
        <div>
            <div style={styles.main} >
                <button
                    style={{ ...styles.button, color: activeTheme == themeEnum.DEFAULT_DARK ? 'white' : '#1d1d1d' }}
                    onClick={copyLink}
                >
                    <span style={styles.span} class="material-symbols-outlined">
                        share
                    </span>
                    Share
                </button>

            </div>
        </div>
    )
}

export default ShareButton