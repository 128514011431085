export const styles = {
    main: {
        display: 'flex',
        justifyContent: 'end',
        width: '100%',
        paddingBottom: 10,
        flexDirection: 'row',
    },
    button: {
        display: 'flex',
        width: 100,
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 20,
        backgroundColor: 'transparent',
        fontSize: 19,
        border: 'none',
        padding: 10,
        borderRadius: 5
    },
    span: {
        paddingRight: 9
    }

}