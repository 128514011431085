import { useState } from "react"
import { Button, Card, Dropdown, DropdownButton, ListGroup, Tab, Tabs } from "react-bootstrap"
import { useNavigate } from "react-router-dom"

export default function TaskListSection({ taskBoard }) {
    const [activeTab, setActiveTab] = useState("all")
    const [tasks, setTasks] = useState(taskBoard.tasks)

    const navigate = useNavigate()

    // Task durumunu güncelleyen fonksiyon
    const updateTaskStatus = (taskId, newStatus) => {
        setTasks(tasks.map(task =>
            task._id === taskId ? { ...task, state: newStatus } : task
        ))
    }

    // Aktif sekmeye göre filtrelenmiş görevler
    const filteredTasks = tasks && tasks.length !== 0 && tasks.filter(task =>
        activeTab === "all" ? true : task.state === activeTab
    )

    // Durum başına görev sayısını alır
    const getTaskCount = (status) => tasks.filter(task => task.state === status).length

    return (
        <Card className="w-100 mx-auto" style={{ backgroundColor: "white" }}>
            <Card.Header
                className="d-flex justify-content-between align-items-center"
                style={{ backgroundColor: "white", borderBottom: "none" }}
            >
                <h5>Task Board</h5>
                <div>
                    <Button
                        variant="outline-secondary"
                        className="mr-2"
                        style={{ borderRadius: "50px" }}
                        onClick={() => navigate("/dashboard/tasks/", {
                            state: { taskBoardId: taskBoard._id }
                        })}
                    >
                        Show All
                    </Button>
                </div>
            </Card.Header>
            <Card.Body>
                <Tabs
                    activeKey={activeTab}
                    onSelect={(k) => setActiveTab(k)}
                    className="mb-3"
                    style={{ backgroundColor: "white" }}
                >
                    <Tab
                        eventKey="all"
                        title={`All (${tasks.length})`}
                        tabClassName={activeTab === "all" ? "bg-black text-white" : "bg-light text-dark"}
                    >
                        {
                            filteredTasks && (

                                <TaskList tasks={filteredTasks} updateStatus={updateTaskStatus} taskBoard={taskBoard} />
                            )
                        }
                    </Tab>
                    {taskBoard.states.map((state, index) => (
                        <Tab
                            key={index}
                            eventKey={state}
                            title={`${state} (${getTaskCount(state)})`}
                            tabClassName={activeTab === state ? "bg-black text-white" : "bg-light text-dark"}
                        >
                            {
                                filteredTasks && (

                                    <TaskList tasks={filteredTasks} updateStatus={updateTaskStatus} taskBoard={taskBoard} />
                                )
                            }
                        </Tab>
                    ))}
                </Tabs>
            </Card.Body>
        </Card>
    )
}

function TaskList({ tasks, updateStatus, taskBoard }) {
    const limitedTasks = tasks.slice(0, 5);

    return (
        <ListGroup>
            {limitedTasks.map(task => (
                <ListGroup.Item key={task._id} className="d-flex justify-content-between align-items-center">
                    <div>
                        <h6>{task.title}</h6>
                        <small>{new Date(task.startDate).toLocaleDateString()} - {new Date(task.endDate).toLocaleDateString()}</small>
                    </div>
                    <DropdownButton
                        title="Status"
                        variant="secondary"
                        onSelect={(newStatus) => updateStatus(task._id, newStatus)}
                        style={{ borderRadius: "50px" }}
                    >
                        {taskBoard.states.map((state, index) => (
                            <Dropdown.Item key={index} eventKey={state}>{state}</Dropdown.Item>
                        ))}
                    </DropdownButton>
                </ListGroup.Item>
            ))}
        </ListGroup>
    )
}
