import axios from 'axios'
import { BASE_URL } from "../constants/links"

const instance = axios.create({
    baseURL: BASE_URL,
    timeout: 15 * 1000,
    timeoutErrorMessage: 'Zaman aşımı! Lütfen tekrar deneyin.',
    headers: {
        'Content-Type': 'application/json',
    }
})


// Add a response interceptor
instance.interceptors.response.use((response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data

    return response.data
}, (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response) {
        // Sunucudan gelen bir hata yanıtı varsa
        console.log('Sunucu Hatası', error.response.data);
    } else if (error.request) {
        // İstek gönderilirken bir hata oluştuysa (örneğin, internet bağlantısı yoksa)
        console.log('İstek Hatası', error.request);
    } else {
        // Diğer hata durumları
        console.log('Hata', error.message);
    }
    return Promise.reject(error)
})

export default instance