import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import { Button, Container, Input } from "reactstrap";
import { sendEmail } from "../../../../helpers/sendEmail";
import { getProjectInviteBody } from "../../../../helpers/getEmailBody";

function AddTeamMember({ inviteId, fullName }) {
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");

  const sendInviteMail = () => {
    sendEmail(
      email,
      "UpperBoard Project Invitation",
      getProjectInviteBody(inviteId, fullName, role)
    );
    setEmail("");
  };

  return (
    <div className="page-content projects-main" style={{ textAlign: "center" }}>
      <ToastContainer />
      <Container
        className="d-flex"
        style={{ flexDirection: "column", justifyContent: "center" }}
      >
        <p>Add Member</p>
        <h4>Project ID: {inviteId}</h4>
        <div style={{ marginTop: 20, marginBottom: 20 }}>
          <button
            className={`invite-role-selectors ${
              role === "Freelancer" ? "active" : ""
            }`}
            onClick={() => setRole("Freelancer")}
          >
            Freelancer
          </button>
          <button
            className={`invite-role-selectors ${
              role === "Company" ? "active" : ""
            }`}
            onClick={() => setRole("Company")}
          >
            Company
          </button>
        </div>
        <p>Member's Email</p>
        <Input value={email} onChange={(e) => setEmail(e.target.value)} />
        <Button
          type="submit"
          color="dark"
          className="mt-3"
          onClick={sendInviteMail}
        >
          Send Invite
        </Button>
      </Container>
    </div>
  );
}

export default AddTeamMember;
