export const Button = ({ children, variant, onClick, className, type = "button", isLoading = false }) => (
    <button
        type={type}
        className={`tw-px-4 tw-py-2 tw-rounded-md tw-font-medium tw-transition-colors ${variant === 'outline'
            ? 'tw-border tw-border-solid tw-border-gray-300 tw-text-gray-700 hover:tw-bg-gray-50 tw-bg-transparent'
            : 'tw-bg-[#18181b] tw-text-white hover:tw-opacity-90 tw-border tw-border-solid tw-border-[#18181b]'
            } ${className}`}
        onClick={onClick}
        disabled={isLoading}
    >
        {isLoading ? (
            <svg className="tw-animate-spin tw-h-5 tw-w-5 tw-text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="tw-opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="1.5"></circle>
                <path className="tw-opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
        ) : (
            children
        )}
    </button>
);

export const Input = ({ ...props }) => (
    <input
        {...props}
        className="tw-w-full tw-px-3 tw-py-2 tw-border tw-border-gray-300 tw-border-solid tw-rounded-md tw-shadow-sm focus:tw-outline-none focus:tw-ring-blue-500 focus:tw-border-blue-500"
    />
);

export const Textarea = ({ ...props }) => (
    <textarea
        {...props}
        className="tw-w-full tw-px-3 tw-py-2 tw-border tw-border-gray-300 tw-border-solid tw-rounded-md tw-shadow-sm focus:tw-outline-none focus:tw-ring-blue-500 focus:tw-border-blue-500"
    />
);

export const Avatar = ({ src, alt, fallback }) => (
    <div className="tw-w-12 tw-h-12 tw-rounded-full tw-bg-gray-200 tw-flex tw-items-center tw-justify-center tw-text-gray-600 tw-font-semibold">
        {src ? <img src={src} alt={alt} className="tw-w-full tw-h-full tw-rounded-full" /> : fallback}
    </div>
);