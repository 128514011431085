import axios from 'axios';
import { default as React, useState } from "react";
import { useNavigate } from 'react-router-dom';
import UpperModal from '../../../../../components/UpperModal';
import { DELETE_BLOG } from '../../../../../constants/links';
import { useGetBlogsByUserId } from '../../../../../customHooks/useGetBlogsByUserId';
import DeleteBlog from '../../blog/components/DeleteBlog';

function Works({ userId, deleteImage, handleSubmit }) {
  const navigate = useNavigate();

  const [refetchBlogs, setRefetchBlogs] = useState(false);

  const [blogs, loading] = useGetBlogsByUserId(userId, [refetchBlogs]);

  const deleteBlog = (blogId) => {
    axios.delete(DELETE_BLOG, {
      headers: {
        userid: userId,
        blogid: blogId
      }
    })
      .then((res) => {
        console.log(res.data);
        setRefetchBlogs(!refetchBlogs);
      })
      .catch((err) => {
        console.log(err);
      });
  };


  if (loading) return <>YÜKLENİYORR</>;

  return (
    <div className="works-edit-container">
      <h2>Blogs</h2>
      <div className="box-container row">
        {blogs.map((blog, index) => {
          return (
            <div className="col-lg-3 col-md-4 col-sm-6" style={{ marginBottom: 20 }}>
              <UpperModal modalId={'deleteBlog' + blog._id} contentHTML={<DeleteBlog deleteBlog={deleteBlog} blogId={blog._id} />} type={'lg'} />

              <div style={{ width: '100%', position: 'relative' }}>
                <img className='create-work-img' src={blog.cardImg} alt="image" />
                <div
                  className='edit-delete-div'
                >
                  <div
                    onClick={() => navigate('/dashboard/create-blog',
                      {
                        state: { itemId: typeof blog === 'string' ? null : blog._id }
                      }
                    )}
                    style={{
                      cursor: 'pointer',
                    }}>Edit</div>
                  <div
                    data-toggle="modal"
                    data-target={"#deleteBlog" + blog._id}
                    style={{
                      cursor: 'pointer',
                    }}
                  >Delete</div>

                </div>
              </div>
            </div>

          )
        })}


        <div class="col-lg-3 col-md-4 col-sm-6" style={{ marginBottom: 20 }}>

          <div
            onClick={async () => {
              await handleSubmit()
              navigate('/dashboard/create-blog')
            }}
            className='create-work-div '
          >
            + Add Work
          </div>
        </div>
      </div>
    </div>
  );
}

export default Works;

