import React from 'react'

function DismissModal() {
    return (
        <div className="notification-dismiss-modal">
            <div className="last-warning">
                <div className="overlap">
                    <div className="warning-box" />
                    <div className="im-sure-button">
                        <div className="overlap-group">
                            <div className="text-wrapper">I’m sure, send</div>
                        </div>
                    </div>
                    <div className="add-note">
                        <p className="div">
                            You can send a message with your rejection. Your message will be delivered to the customer.
                        </p>
                        <div className="auto-message">
                            <div className="div-wrapper">
                                <div className="text-wrapper-2">Message 1</div>
                            </div>
                        </div>
                        <div className="overlap-wrapper">
                            <div className="div-wrapper">
                                <div className="text-wrapper-2">Message 2</div>
                            </div>
                        </div>
                        <div className="message-box">
                            <div className="overlap-2">
                                <div className="text-wrapper-3">Add message (optional)</div>
                            </div>
                        </div>
                    </div>
                    <p className="p">
                        If you reject this offer, you will NOT be able to access the offer and the customer unless they contacts you
                        again.
                    </p>
                    <div className="text-wrapper-4">Are yo.u sure?</div>
                </div>
            </div>
        </div>
    )
}

export default DismissModal