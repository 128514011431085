import React from "react";
import { ToastContainer } from "react-toastify";
// import { GET_BY_LINK_NICK, GET_USER_PROFILE } from './constants/links'
import { themeEnum } from "../../../constants/themeEnums";
import ContactDark from "./components/showComponents/ContactDark";
import ContactWhite from "./components/showComponents/ContactWhite";
import Experiences from "./components/showComponents/Experiences";
import ProfileTop from "./components/showComponents/ProfileTop";
import ShareButton from "./components/showComponents/ShareButton";
import Skills from "./components/showComponents/Skills";
import Works from "./components/showComponents/Works";
function ShowUpperProfile({
  portfolio,
  activeTheme,
  inDashboard,
  emailFrom,
  setEmailFrom,
  handleNotificationInfo,
  notificationInfo,
  setNotificationInfo,
}) {
  return (
    <div
      className="show-profile-main"
      style={{
        paddingTop: inDashboard ? 0 : 150,
        width: "100%",
        color: activeTheme == themeEnum.DEFAULT ? "black" : "white",
        backgroundColor: activeTheme == themeEnum.DEFAULT ? "white" : "#1d1d1d",
      }}
    >
      <ToastContainer />

      <ShareButton linkNick={portfolio.linkNick} activeTheme={activeTheme} />

      <ProfileTop
        profileImg={portfolio.profileImg}
        fullName={portfolio.fullName}
        role={portfolio.role}
      />

      <Skills about={portfolio.about} skills={portfolio.skills} />

      <Works works={portfolio.works} userId={portfolio.userId} />

      <Experiences experiences={portfolio.experiences} />

      {activeTheme == themeEnum.DEFAULT ? (
        <ContactWhite
          emailFrom={emailFrom}
          setEmailFrom={setEmailFrom}
          socialMedias={portfolio.socialMedias}
          email={portfolio.email}
          handleNotificationInfo={handleNotificationInfo}
          notificationInfo={notificationInfo}
          userId={portfolio.userId}
          setNotificationInfo={setNotificationInfo}
        />
      ) : (
        <ContactDark
          emailFrom={emailFrom}
          setEmailFrom={setEmailFrom}
          socialMedias={portfolio.socialMedias}
          email={portfolio.email}
          handleNotificationInfo={handleNotificationInfo}
          notificationInfo={notificationInfo}
          userId={portfolio.userId}
          setNotificationInfo={setNotificationInfo}
        />
      )}
    </div>
  );
}

export default ShowUpperProfile;
