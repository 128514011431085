import React from 'react'


function FreelancerMenuButton({ profileImg, fullName }) {
    return (
        <button type="button" class="btn header-item " data-toggle="dropdown" id="page-header-user-dropdown" >
            <img class="rounded-circle" src={profileImg} alt="Header Avatar" style={{ width: 40, height: 40 }} />
            <span style={{ paddingLeft: 15, paddingRight: 7, color: 'white' }}>{fullName}</span>
            <i style={{ color: 'white' }} class="mdi mdi-chevron-down" ></i>
        </button>
    )
}

export default FreelancerMenuButton