import React from "react";

function ContactMessageDetail({ activeMessage }) {
  return (
    <div className="contact-message-detail">
      <h1>{activeMessage.subject}</h1>
      <p>
        <strong>From : </strong>
        {activeMessage.senderName}
      </p>
      <p>
        <strong>Message: </strong>
        <p dangerouslySetInnerHTML={{ __html: activeMessage.messageContent }} />

        {/* {activeMessage.messageContent} */}
      </p>
      <p>
        <strong>Received: </strong>
        {new Date(activeMessage.timestamp).toLocaleString()}
      </p>
    </div>
  );
}

export default ContactMessageDetail;
