import axios from "axios";
import { toast } from "react-toastify";
import { CHANGE_EMAIL } from "../../../../constants/links";
import { getVerificationCodeBody } from "../../../../helpers/getEmailBody";
import { sendEmail } from "../../../../helpers/sendEmail";

export const useChangeEmail = () => {
    const sendVerificationCode = (newEmail, setVerificationCode, setIsCodeSent) => {
        const code = Math.floor(100000 + Math.random() * 900000); // 6 haneli kod
        setVerificationCode(code);
        sendEmail(newEmail, "Change Email Verification Code", getVerificationCodeBody(code))

        setIsCodeSent(true);
    };

    const verifyCode = (enteredCode, verificationCode, setIsVerified) => {
        if (parseInt(enteredCode) === verificationCode) {
            setIsVerified(true);
            toast.success("Email verified successfully!");
        } else {
            toast.error("Invalid verification code.");
        }
    };

    const changeEmail = (newEmail, userId, onClose) => {
        axios
            .post(CHANGE_EMAIL, { newEmail, userId })
            .then(() => {
                toast.success("Email successfully updated!");
                onClose();
            })
            .catch((res) => {
                toast.error(res.response.data.message);
            });
    };

    return { sendVerificationCode, verifyCode, changeEmail };
};
